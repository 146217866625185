import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "home",
  id: "homePage_m"
};
const _hoisted_2 = {
  key: 0,
  class: "hometitle gloria-font"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 2,
  class: "googleMaps"
};
const _hoisted_5 = {
  key: 3,
  class: "accordion"
};
const _hoisted_6 = {
  key: 4,
  class: "lowCode"
};
const _hoisted_7 = {
  key: 5,
  class: "subscription"
};
const _hoisted_8 = {
  key: 6,
  class: "richText"
};
const _hoisted_9 = {
  key: 7,
  class: "fit-up-item"
};
const _hoisted_10 = {
  key: 8,
  class: "fit-up-item"
};
const _hoisted_11 = {
  key: 9,
  class: "fit-up-item"
};
const _hoisted_12 = {
  key: 10
};
const _hoisted_13 = {
  key: 11,
  class: "fit-up-item"
};
const _hoisted_14 = {
  key: 12,
  class: "goods-list fit-up-item"
};
const _hoisted_15 = {
  key: 13,
  class: "fit-up-item"
};
const _hoisted_16 = {
  key: 14,
  class: "fit-up-item"
};
const _hoisted_17 = {
  class: "showPictures"
};
const _hoisted_18 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "margin-bottom": "20px",
    "width": "100%"
  }
};
const _hoisted_19 = {
  class: "target-top",
  style: {
    "overflow": "hidden",
    "width": "100%"
  }
};
const _hoisted_20 = {
  class: "target-img"
};
const _hoisted_21 = ["src"];
const _hoisted_22 = {
  class: "target-img img-right"
};
const _hoisted_23 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_imageText = _resolveComponent("imageText");
  const _component_googleMaps = _resolveComponent("googleMaps");
  const _component_accordion = _resolveComponent("accordion");
  const _component_mimo_replace = _resolveComponent("mimo_replace");
  const _component_lowCode = _resolveComponent("lowCode");
  const _component_subscription = _resolveComponent("subscription");
  const _component_richText = _resolveComponent("richText");
  const _component_imgSwipers = _resolveComponent("imgSwipers");
  const _component_images = _resolveComponent("images");
  const _component_videos = _resolveComponent("videos");
  const _component_goodsScroll = _resolveComponent("goodsScroll");
  const _component_navigation = _resolveComponent("navigation");
  const _component_goodsGrid = _resolveComponent("goodsGrid");
  const _component_texts = _resolveComponent("texts");
  const _component_hotMap = _resolveComponent("hotMap");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.get_data, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "assembly"
    }, [item.base.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(item.base.title), 1)])) : _createCommentVNode("", true), item.name === 'imageText' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_imageText, {
      data: item,
      imageTextIndex: index
    }, null, 8, ["data", "imageTextIndex"])])) : _createCommentVNode("", true), item.name === 'googleMaps' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_googleMaps, {
      gMaps: item
    }, null, 8, ["gMaps"])])) : _createCommentVNode("", true), item.name === 'accordion' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_accordion, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'lowCode' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [item.data === '<mimo-replace>' ? (_openBlock(), _createBlock(_component_mimo_replace, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_lowCode, {
      key: 1,
      data: item
    }, null, 8, ["data"]))])) : _createCommentVNode("", true), item.name === 'subscription' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_subscription, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'richText' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_richText, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'slider' ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_imgSwipers, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'images' ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_images, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'video' ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_videos, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'goodsScroll' ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_goodsScroll, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'navigation' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createVNode(_component_navigation, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'goodsGrid' ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_goodsGrid, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'text' ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_texts, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true), item.name === 'hotmap' ? (_openBlock(), _createElementBlock("div", _hoisted_16, [_createVNode(_component_hotMap, {
      data: item
    }, null, 8, ["data"])])) : _createCommentVNode("", true)]);
  }), 128)), _createElementVNode("div", _hoisted_17, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.showPicturesList, item => {
    return _openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, ite => {
      return _openBlock(), _createElementBlock("div", {
        key: item
      }, [_createElementVNode("img", {
        src: ite
      }, null, 8, _hoisted_21)]);
    }), 128))]), _createElementVNode("div", _hoisted_22, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, ite => {
      return _openBlock(), _createElementBlock("div", {
        key: item
      }, [_createElementVNode("img", {
        src: ite
      }, null, 8, _hoisted_23)]);
    }), 128))])])]);
  }), 256))])]);
}