import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { dispatchEventStroage } from '@/utils/watchLocalStorage.js'
import VueLuckyCanvas from '@lucky-canvas/vue'

import '@/assets/scss/main.scss'
import '@/assets/scss/tailwind.css'
import '@/assets/scss/animated.css'
import '@/assets/icons/index' // 这个代表全局挂载项目当中,所有组件都可以使用icons

import 'element-plus/dist/index.css'
import './assets/font/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

export let localStore = {}

createApp(App).use(store).use(router).use(dispatchEventStroage).use(VueLuckyCanvas).mount('#app')
