import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import CommonHeader from '@/components/header/index.vue';
import CommonFooter from '@/components/footer/index.vue';
import service from '@/components/common/service.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'App',
  components: {
    CommonHeader,
    CommonFooter,
    service
  },
  setup() {
    if (localStorage.getItem('userData')) {
      sessionStorage.setItem('userData', localStorage.getItem('userData'));
    }
    if (!localStorage.getItem('key')) {
      localStorage.setItem('key', 'home');
    }
    const store = useStore(); // 该方法用于返回store 实例
    const Client = ref(0); // 初始化值为pc端  通过判断进行修改
    // console.log(store)  // store 实例对象  
    // 判断当前客户端的标识
    const _isMobile = () => {
      const flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    };
    if (_isMobile()) {
      Client.value = 1;
    }
    // 判断当前是否在会员页
    const getShowTask = computed(() => {
      console.log(store.state.is_member);
      return store.state.is_member;
    });
    // 判断当前是否在登录页
    const is_login = computed(() => {
      return store.state.is_login;
    });
    store.commit('modifyClient', Client.value);
    // console.log(window)
    onMounted(() => {
      // 判断当前是否处于会员端页面
      console.log('是否处于会员端');
    });
    // 获取当前页面
    const is_show = computed(() => {
      let isShow = ['member_index', 'member_science_m'];
      // console.log();
      return isShow.includes(store.state.routerKey);
    });
    const openKf = () => {
      window.open('http://www.mimodental.com:8088/im/text/104eac.html');
    };
    return {
      getShowTask,
      is_login,
      Client,
      is_show,
      openKf
    };
  }
});