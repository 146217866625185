import {
  ref,
  computed,
  watch,
  onMounted,
  defineComponent
} from 'vue'
import {
  useEventListener
} from '../composables/useEventListener'
import {
  getType,
  throttle
} from '../utils'

import { inBrowser } from '@/utils/tools'

const name = 'g-sticky'

const props = {
  top: {
    type: [Number, String],
    default: 0
  },
  zIndex: {
    type: [Number, String],
    default: 99
  },
  scrollHide: {
    type: Boolean,
    default: false
  },
  duration: {
    type: [Number, String],
    default: 200
  }
}

export default defineComponent({
  name,
  props,
  emits: ['scroll', 'toggle'],
  setup (props, { emit, slots }) {
    const flexible = inBrowser ? window.lib?.flexible?.rem : 0
    const sticky = ref(null)
    const isSupportSticky = ref(true)
    const noDuration = ref(false)
    // true 为下滚，false 为上滚
    const scrollStatus = ref(false)
    // 是否到达 fixed 位置
    const stickyStatus = ref(false)
    // let tempTop = document.documentElement.scrollTop || document.body.scrollTop
    // 元素距离顶部信息
    const stickyTop = computed(() => {
      const top = getType(props.top).isString ? props.top : `${props.top}px`
      return {
        top,
        formatTop: Number(top.includes('px') ? top.split('px')[0] : top.split('rem')[0] * flexible)
      }
    })
    // 元素基本宽高信息 - 占位用
    const basicStyle = computed(() => {
      return {
        width: `${sticky.value.offsetWidth}px`,
        height: `${sticky.value.offsetHeight}px`
      }
    })
    // sticky 元素信息
    const stickyStyle = computed(() => {
      const style = {
        position: isSupportSticky.value ? 'sticky' : stickyStatus.value ? 'fixed' : 'static',
        top: stickyTop.value.top,
        'z-index': props.zIndex
      }
      if (props.scrollHide) {
        style.transform = scrollStatus.value ? 'translateY(-100%)' : 'none'
        style.top = scrollStatus.value ? 0 : stickyTop.value.top
        if (!noDuration.value) {
          style.transition = `transform ${props.duration / 1000}s`
        }
      }
      return style
    })
    // fixed 用综合信息
    const commitStyle = computed(() => {
      return {
        ...basicStyle.value,
        ...stickyStyle.value
      }
    })
    const getTop = () => {
      return inBrowser ? document.documentElement.scrollTop || document.body.scrollTop : 0
    }
    let tempTop = getTop()
    // 滚动事件
    useEventListener('scroll', throttle(() => {
      // 如果要下滚隐藏
      const nowTop = document.documentElement.scrollTop || document.body.scrollTop
      const rectTop = sticky.value.getBoundingClientRect()?.top
      // 判断 sticky 位置
      stickyStatus.value = nowTop > stickyTop.value.formatTop + sticky.value.offsetHeight && rectTop <= stickyTop.value.formatTop
      // 判断是否滚动
      scrollStatus.value = nowTop > tempTop && stickyStatus.value
      tempTop = nowTop
      emit('scroll', scrollStatus.value)
    }, 200))

    watch(scrollStatus, (val) => {
      props.scrollHide && emit('toggle', val)
    })

    watch(stickyStatus, (val) => {
      if (val) {
        noDuration.value = true
        setTimeout(() => {
          noDuration.value = false
        }, props.duration)
      }
    })

    onMounted(() => {
      // 判断是否支持 sticky
      if (!sticky.value.style?.position?.includes('sticky')) {
        isSupportSticky.value = false
      }
    })

    return () => (
      <div ref={sticky} class='g-sticky' style={isSupportSticky.value ? stickyStyle.value : basicStyle.value}>
        <div class='g-sticky-container' style={!isSupportSticky.value && commitStyle.value}>
          { slots.default?.() }
        </div>
      </div>
    )
  }
})
