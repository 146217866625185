import { defineComponent } from 'vue';
import prodItem from '@/components/product/ProductItem';
export default defineComponent({
  name: 'ProductList',
  components: {
    prodItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    col: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    currentTitle: {
      type: String,
      default: ''
    },
    showQuickBuy: {
      type: Boolean,
      default: true
    },
    showQuickView: {
      type: Boolean,
      default: true
    },
    showWish: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showAttr: {
      type: Boolean,
      default: true
    },
    isCartHover: {
      type: Boolean,
      default: false
    },
    selectedColorName: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return {};
  }
});