import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { ElMessage } from 'element-plus';
import router from '@/router';
import { useStore } from "vuex";
export default {
  name: 'member_receive_m',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 优惠卷列表
    const cardRecordsList = ref([]);
    // 我的优惠卷列表
    const my_cardRecordsList = ref([]);
    // 优惠卷样式
    const designsObj = ref({});
    // 获取优惠卷
    const get_cardRecords = () => {
      let url = api.baseUrl + api.cards + '?userId=' + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        cardRecordsList.value = res.data.rows;
        // 获取优惠卷样式列表
        designs();
      });
    };
    // 获取我的优惠卷
    const get_my_yhj = () => {
      let url = api.baseUrl + api.cardRecords + '?userId=' + user_info.value.id + '&status=1';
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        my_cardRecordsList.value = res.data.rows;
        get_cardRecords();
      });
    };
    // 领取优惠卷
    const getCoupon = (cardId, item) => {
      if (item.unClaimable) {
        ElMessage({
          message: '该优惠卷不可领取',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      axios({
        url: api.baseUrl + api.getCoupon,
        method: 'post',
        data: {
          userId: user_info.value.id,
          cardId
        }
      }).then(res => {
        const {
          data
        } = res;
        console.log(data);
        if (data.code != 200) {
          ElMessage({
            message: data.msg,
            type: 'error',
            customClass: "message-m"
          });
        } else {
          ElMessage({
            message: '领取成功',
            type: 'success',
            customClass: "message-m"
          });
          get_my_yhj();
        }
      });
    };
    const go = () => {
      router.push({
        name: 'member_preferential_m'
      });
    };
    const fh = () => {
      router.go(-1);
    };
    const designs = () => {
      let arr = [...cardRecordsList.value, ...my_cardRecordsList.value];
      let data = {};
      arr.map(item => {
        data[item.cardId] = {
          kaquanId: item.cardId,
          client: 1,
          type: 0
        };
      });
      console.log(data);
      let newdata = [];
      for (const key in data) {
        newdata.push({
          kaquanId: key,
          client: 1,
          type: 0
        });
      }
      axios({
        url: api.baseUrl + api.designs,
        method: 'post',
        data: newdata
      }).then(res => {
        // console.log(res);
        designsObj.value = res.data.data;
      });
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    get_my_yhj();
    return {
      cardRecordsList,
      getCoupon,
      my_cardRecordsList,
      go,
      fh,
      designsObj,
      call
    };
  }
};