import { defineComponent, computed, ref, watch } from 'vue'
import { useExpose } from '../utils'

export default defineComponent({
  name: 'g-dropdown',

  props: {
    show: Boolean,
    initHeight: [Number, String],
    maxHeight: [Number, String],
    duration: Number
  },

  emits: ['update:show'],

  setup (props, { slots, emit }) {
    const initHeight = +props.initHeight || 0
    const maxHeight = +props.maxHeight || 0
    // set display height to init height
    const displayHeight = ref(initHeight)

    const style = computed(() => {
      return {
        maxHeight: `${displayHeight.value}px`,
        transitionDuration: `${(props.duration || 300) / 1000}s`
      }
    })

    const $dom = ref()

    const getTotalHeight = () => {
      if ($dom.value) {
        const children = $dom.value.children
        if (children) {
          if (children.length > 1) {
            console.warn('dropdown component should only has one root element')
          }
          return children[0].clientHeight
        }

        return 0
      }

      return 0
    }

    const getShowHeight = () => {
      displayHeight.value = maxHeight || getTotalHeight()
    }

    const expand = () => {
      getShowHeight()
      emit('update:show', true)
    }

    const hide = () => {
      displayHeight.value = initHeight
      emit('update:show', false)
    }

    useExpose({
      expand,
      hide
    })

    watch(() => props.show, (val) => {
      if (val) {
        expand()
      } else {
        hide()
      }
    })

    return () => (
      <div
        ref={$dom}
        class="g-dropdown"
        style={style.value}
      >
        {slots.default?.()}
      </div>
    )
  }
})
