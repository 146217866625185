import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-31273e83"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "home_m"
};
const _hoisted_2 = {
  class: "Expert_Popularization_m"
};
const _hoisted_3 = {
  class: "Popularization_list_m"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  style: {
    "color": "#cbcbcb"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.answer, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      onClick: $event => $setup.details(item.id)
    }, [_createElementVNode("span", null, _toDisplayString(item.title), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(item.createTime), 1)], 8, _hoisted_4);
  }), 128))])])]);
}