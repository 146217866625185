import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from "vuex";
import router from '@/router';
export default {
  name: 'member_userCard_m',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    // 优惠券tab选中
    const preferential_tab_key = ref(1);
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 优惠券列表
    const cardRecordsList = ref([]);
    // 优惠券弹窗
    const show = ref(false);
    // 弹窗内容
    const dialog_data = ref({});
    // 获取卡片
    const get_cardRecords = () => {
      let url = api.baseUrl + api.userCard + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res.data;
        cardRecordsList.value = data;
      });
    };
    // 使用优惠券
    const show_dialog = (id, index) => {
      axios({
        url: api.baseUrl + api.getCard + id,
        method: 'get'
      }).then(res => {
        console.log(res);
        const {
          data
        } = res.data;
        preferential_tab_key.value = index;
        dialog_data.value = data;
        show.value = true;
      });
    };
    // 关闭弹窗
    const handleClose = () => {
      show.value = false;
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    const fh = () => {
      router.go(-1);
    };
    const push = item => {
      router.push({
        path: '/member_userCard_xq_m',
        query: {
          ...item
        }
      });
    };
    get_cardRecords();
    return {
      preferential_tab_key,
      show,
      dialog_data,
      handleClose,
      show_dialog,
      cardRecordsList,
      call,
      fh,
      push
    };
  }
};