import {
  reactive,
  provide,
  getCurrentInstance,
  isVNode
} from 'vue'
import { extend } from '../utils'

export function flattenVNodes (children) {
  const result = []

  const traverse = children => {
    if (Array.isArray(children)) {
      children.forEach(child => {
        if (!child) return
        if (isVNode(child)) {
          result.push(child)
        }

        if (child.component?.subTree) {
          traverse(child.component.subTree.children)
        }

        if (child.children) {
          traverse(child.children)
        }
      })
    }
  }

  traverse(children)

  return result
}

export function sortChildren (parent, publicChildren, internalChildren) {
  const vnodes = flattenVNodes(parent.subTree.children)

  internalChildren.sort(
    (a, b) => vnodes.indexOf(a.vnode) - vnodes.indexOf(b.vnode)
  )

  const orderedPublicChildren = internalChildren.map(item => item.proxy)

  publicChildren.sort(
    (a, b) => orderedPublicChildren.indexOf(a) - orderedPublicChildren.indexOf(b)
  )
}

export function useChildren (key) {
  const publicChildren = reactive([])
  const internalChildren = reactive([])
  const parent = getCurrentInstance([])

  const linkChildren = value => {
    const link = child => {
      if (child.proxy) {
        internalChildren.push(child)
        publicChildren.push(child.proxy)
        sortChildren(parent, publicChildren, internalChildren)
      }
    }

    const unlink = child => {
      const index = internalChildren.indexOf(child)
      publicChildren.splice(index, 1)
      internalChildren.splice(index, 1)
    }

    provide(
      key,
      extend(
        {
          link,
          unlink,
          children: publicChildren,
          internalChildren
        },
        value
      )
    )
  }

  return {
    children: publicChildren,
    linkChildren
  }
}
