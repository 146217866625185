import {
  defineComponent,
  inject
} from 'vue'
export default defineComponent({
  name: 'g-radio',
  props: {
    value: [Number, String, Boolean],
    label: {
      type: [String, Number],
      default: 'radio'
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit, attrs, slots }) {
    const modelRadio = inject('modelRadio')
    const model = modelRadio.value
    const updataReadio = (value) => {
      if (model) {
        modelRadio.updateModel(value)
        // model.value = value
      } else {
        emit('update:value', value)
      }
    }
    const radioType = () => {
      if (model) {
        return (
          <span className={`iconfont-g ${props.label === model.value ? 'icon-radio-active' : 'icon-radio'}`} />
        )
      } else {
        return (
          <span className={`iconfont-g ${props.label === props.value ? 'icon-radio-active' : 'icon-radio'}`} />
        )
      }
    }
    const renderRadio = () => {
      if (props.disabled) {
        return (
          <span class="g-radio i-g-disabled">
            {radioType()}
            {props.name ? props.name : slots.default?.()}
          </span>
        )
      } else {
        return (
          <span class="g-radio" onClick={updataReadio.bind(this, props.label)}>
            {radioType()}
            {props.name ? props.name : slots.default?.()}
          </span>
        )
      }
    }
    return () => {
      return (
        <>
          {renderRadio()}
        </>
      )
    }
  }
})
