import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "home",
  id: "homePage_m"
};
const _hoisted_2 = {
  class: "djs"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "title_dome"
};
const _hoisted_5 = {
  class: "title_dome_list"
};
const _hoisted_6 = {
  style: {
    "width": "200px",
    "text-align": "center"
  }
};
const _hoisted_7 = {
  style: {
    "flex": "1"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.event, (item, index) => {
    return _openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("h3", null, _toDisplayString(item.time) + "(" + _toDisplayString(item.arr.length) + ")", 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.arr, (ite, ind) => {
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(ite.time), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(ite.title), 1)])]);
    }), 256))])]);
  }), 256))])])]);
}