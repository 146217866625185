import {
  defineComponent,
  provide,
  watch,
  computed
} from 'vue'

export const modelValue = Symbol('model')
export default defineComponent({
  name: 'g-group-radio',
  props: {
    value: [Number, String, Boolean],
    label: {
      type: String,
      default: 'radio'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value'],
  setup (props, { emit, attrs, slots }) {
    const value = computed(() => props.value)
    const updateModel = (val) => {
      emit('update:value', val)
      emit('change', val)
    }
    provide('modelRadio', {
      value: value,
      updateModel: updateModel
    })

    watch(value, () => {
      emit('update:value', props.value)
      emit('change', props.value)
    }, { immediate: true, deep: true })
    return () => {
      return (
        <>
          {slots.default(1)}
        </>
      )
    }
  }
})
