import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus';
export default {
  name: 'member_receive',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 优惠券列表
    const cardRecordsList = ref([]);
    // 优惠卷样式
    const designsObj = ref({});
    // 获取优惠券
    const get_cardRecords = () => {
      let url = api.baseUrl + api.cards + '?userId=' + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        cardRecordsList.value = res.data.rows;
        // 获取优惠卷样式列表
        designs();
        console.log('1L === 800ML', true);
      });
    };
    // 领取优惠券
    const getCoupon = (cardId, item) => {
      if (item.unClaimable) {
        ElMessage.error('该优惠卷不可领取');
        return;
      }
      axios({
        url: api.baseUrl + api.getCoupon,
        method: 'post',
        data: {
          userId: user_info.value.id,
          cardId
        }
      }).then(res => {
        const {
          data
        } = res;
        console.log(data);
        if (data.code != 200) {
          ElMessage.error(data.msg);
        } else {
          ElMessage({
            message: "领取成功",
            type: 'success'
          });
        }
        get_cardRecords();
      });
    };
    const designs = () => {
      let data = cardRecordsList.value.map(item => {
        return {
          kaquanId: item.cardId,
          client: 0,
          type: 0
        };
      });
      axios({
        url: api.baseUrl + api.designs,
        method: 'post',
        data
      }).then(res => {
        // console.log(res);
        designsObj.value = res.data.data;
        console.log(designsObj.value['2307121123632']);
      });
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    get_cardRecords();
    return {
      cardRecordsList,
      getCoupon,
      designsObj,
      call
    };
  }
};