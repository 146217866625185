"use strict";
// import { Console } from '@sentry/node/dist/integrations'
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLangs = exports.formatLangsDatas = exports.getCurrentTimeZone = exports.getCurrency = exports.addToCartBatch = exports.popupCheckout = exports.addToCartOrBuy = exports.customBuriedPoint = exports.viewItemList = exports.share = exports.addToWishlist = exports.selectContent = void 0;
/*
 * @Description: 第三方插件函数集合
 */
var utils_1 = require("@/utils");
/**
 * @description: ga电子商务事件
 * @param {ProductInfo} productInfo 产品信息
 * @return {*}
 */
function selectContent(productInfo) {
    window.dataLayer.push({
        event: 'select_content',
        sendTypes: 'ga',
        gaParams: JSON.stringify({
            content_type: 'product',
            items: [
                {
                    id: productInfo.productsId,
                    name: productInfo.productsName,
                    list_name: productInfo.listCode || '',
                    category: '',
                    variant: '',
                    list_position: productInfo.index || '',
                    price: productInfo.finalPrice
                }
            ]
        })
    });
}
exports.selectContent = selectContent;
/**
 * @description: ga、fb添加收藏事件
 * @param {ProductInfo} productInfo
 * @return {*}
 */
function addToWishlist(productInfo) {
    window.dataLayer.push({
        event: 'add_to_wishlist',
        sendTypes: 'ga,fb',
        fbCategory: 'AddToWishlist',
        fbOptions: {
            eventID: productInfo.fbEventId
        },
        gaParams: JSON.stringify({
            currency: getCurrency(),
            value: '',
            items: [{
                id: productInfo.productsId,
                name: productInfo.productsName,
                category: productInfo.category,
                coupon: '',
                list_name: productInfo.listName || '',
                list_position: productInfo.index || '',
                price: productInfo.finalPrice,
                quantity: 1,
                variant: ''
            }]
        }),
        fbParams: {
            content_name: productInfo.productsName,
            content_category: productInfo.category,
            content_ids: [String(productInfo.productsId)],
            contents: [
                {
                    id: String(productInfo.productsId),
                    quantity: 1
                }
            ],
            currency: getCurrency(),
            value: ''
        }
    });
}
exports.addToWishlist = addToWishlist;
/**
 * @description: 详情页（弹窗）加购，buy now, paypal快速支付 埋点
 * @param {ProductInfo} productInfo
 * * @param object productInfo
 * @return {*}
 */
function addToCartOrBuy(productInfo, sendTypes) {
    var fbParams = {
        content_category: productInfo.categoriesName,
        content_ids: [String(productInfo.productsId)],
        content_name: productInfo.productsName,
        content_type: 'product',
        contents: [
            {
                id: String(productInfo.productsId),
                quantity: 1
            }
        ],
        currency: getCurrency(),
        value: productInfo.finalPrice
    };
    window.dataLayer.push({
        event: sendTypes.gaType || 'add_to_cart',
        sendTypes: 'ga',
        gaParams: JSON.stringify({
            items: [
                {
                    id: productInfo.productsId,
                    name: productInfo.productsName,
                    category: productInfo.categoriesName,
                    quantity: 1,
                    variant: (productInfo.attributes &&
                        productInfo.attributes[0] &&
                        productInfo.attributes[0].optionsType) ||
                        '',
                    price: productInfo.finalPrice
                }
            ]
        })
    });
    if (['PaypalCheckout', 'BuynowCheckout'].includes(sendTypes.fbType)) {
        // fb自定义事件
        window.dataLayer.push({
            event: 'fb-custom-trigger',
            fbCategory: sendTypes.fbType,
            fbOptions: {
                eventID: productInfo.fbEventId
            },
            fbParams: fbParams
        });
    }
    else {
        // fb标准事件
        window.dataLayer.push({
            event: 'common-event-trigger',
            sendTypes: 'fb',
            fbCategory: sendTypes.fbType || 'AddToCart',
            fbOptions: {
                eventID: productInfo.fbEventId
            },
            fbParams: fbParams
        });
    }
}
exports.addToCartOrBuy = addToCartOrBuy;
/**
 * @description: 订单详情或订单列表页批量加购
 * @param {ProductInfo} productInfo
 * * @param object productInfo
 * @return {*}
 */
function addToCartBatch(orderList) {
    var fbItems = [];
    var faItems = [];
    var gaItems = [];
    var fbParams = {
        content_ids: [],
        content_name: orderList.route,
        content_type: 'product',
        currency: getCurrency(),
        value: orderList.otTotal || orderList.formatOtTotal
    };
    var faParams = {
        eventLabel: orderList.route,
        currency: getCurrency(),
        totalPrices: orderList.otTotal || orderList.formatOtTotal // 订单总价
    };
    orderList.item && orderList.item.forEach(function (_) {
        faItems.push({
            pid: _.productsId,
            sku: _.sku,
            name: _.productsName,
            quantity: _.productsQuantity || 1,
            price: _.formatFinalPriceTotal
        });
        fbParams.content_ids.push(String(_.productsId));
        fbItems.push({
            id: String(_.productsId),
            quantity: _.productsQuantity || 1
        });
        gaItems.push({
            id: _.productsId,
            name: _.productsName,
            category: '',
            quantity: _.productsQuantity || 1,
            variant: (_.attribute &&
                _.attribute[0] &&
                _.attribute[0].optionValue) ||
                '',
            price: _.formatFinalPriceTotal || ''
        });
    });
    window.dataLayer.push({
        event: 'add_to_cart',
        sendTypes: 'ga',
        gaParams: JSON.stringify({
            items: gaItems
        })
    }, {
        event: 'common-event-trigger',
        sendTypes: 'fb',
        fbCategory: 'AddToCart',
        fbOptions: {
            eventID: orderList.fbEventId
        },
        fbParams: __assign(__assign({}, fbParams), { contents: fbItems })
    });
}
exports.addToCartBatch = addToCartBatch;
/**
 * @description: ga、fa分享事件
 * @param {string} platformName 分享平台名称
 * @param {string} contentType 内容类型
 * @param {string} id 内容id
 * @param {string} url 分享URL
 * @param {any} products 商品信息数组
 * @return {*}
 */
function share(platformName, contentType, id, url, products) {
    window.dataLayer.push({
        event: 'share',
        sendTypes: 'ga',
        gaParams: JSON.stringify({
            content_type: contentType,
            item_id: id,
            method: platformName
        })
    });
}
exports.share = share;
/**
 * @description: ga电子商务事件
 * @param {*} 发送 ga 参数信息
 * @return {*}
 */
function viewItemList(ga) {
    if (ga === void 0) { ga = null; }
    window.dataLayer.push({
        event: 'view_item_list',
        sendTypes: 'ga',
        gaParams: JSON.stringify(ga)
    });
}
exports.viewItemList = viewItemList;
/**
 * @description: 购物车侧边栏checkout ga、fa自定义事件
 * @param list array 产品列表
 * @return {*}
 */
function popupCheckout(list, total, eventName) {
    var items = [];
    list.forEach(function (item) {
        items.push({
            id: item.productsId,
            name: item.productInfo.productsName,
            category: '',
            quantity: item.qty,
            variant: (item.attributes &&
                item.attributes[0] &&
                item.attributes[0].valuesName) ||
                '',
            price: item.productInfo.finalPrice
        });
    });
    window.dataLayer.push({
        event: 'popup_checkout',
        sendTypes: 'ga',
        gaParams: JSON.stringify({
            items: items,
            currency: getCurrency(),
            value: total
        })
    });
    // fa埋点
    // window.fa && window.fa.en(eventName)
}
exports.popupCheckout = popupCheckout;
/**
 * @description: 自定义埋点集合
 * @param {*}
 * @return {*}
 */
var customBuriedPoint = {
    eventCategory: 'custom',
    /**
     * @description: 返回顶部按钮埋点
     * @param {*} routeName 路由名称
     * @return {*}
     */
    goToTop: function (routeName) {
        // ga埋点
        window.dataLayer.push({
            event: 'go_to_top',
            sendTypes: 'ga',
            gaParams: JSON.stringify({
                event_category: this.eventCategory,
                event_label: routeName,
                value: ''
            })
        });
        // fa埋点
        // window.fa && window.fa.send('event', this.eventCategory, 'goToTop', routeName, '')
    },
    /**
     * @description: 订单列表点击 detail 埋点
     * @param {*} routeName 路由名称
     * @return {*}
     */
    orderListToDetail: function (routeName) {
        window.dataLayer.push({
            event: 'order_list_to_detail',
            sendTypes: 'ga',
            gaParams: JSON.stringify({
                event_category: this.eventCategory,
                event_label: routeName,
                value: ''
            })
        });
        // window.fa && window.fa.send('event', this.eventCategory, 'orderListToDetail', routeName, '')
    }
};
exports.customBuriedPoint = customBuriedPoint;
// 时间设置
var timeConfig = {
    au: 'en-AU',
    jp: 'ja-JP-u-ca-japanese',
    default: 'en-US'
};
function getCurrentTimeZone() {
    // const side = process.env.SIDE_ENV || 'default'
    var side = 'default';
    return timeConfig[side];
}
exports.getCurrentTimeZone = getCurrentTimeZone;
// 币种设置
var currencyConfig = {
    au: 'AUD',
    jp: 'JPY',
    default: 'AUD'
};
function getCurrency() {
    // const side = process.env.SIDE_ENV || 'default'
    var side = 'default';
    return currencyConfig[side];
}
exports.getCurrency = getCurrency;
function formatLangsDatas(obj) {
    var oldObj = Object.assign({}, obj);
    var newObj = {};
    for (var key in oldObj) {
        var i = key.indexOf('_');
        var newKey = key.substring(i + 1);
        newObj[newKey] = oldObj[key];
    }
    return newObj;
}
exports.formatLangsDatas = formatLangsDatas;
function getLangs(key) {
    var keyPath = key.split('.');
    var langDatas = utils_1.storage.load('langs');
    // const langDatas:any = localStore.state.langs.language
    var val = '';
    if (!langDatas) {
        return '';
    }
    if (keyPath.length > 1) {
        var getVals_1 = function (data, len) {
            if (data) {
                if (len === keyPath.length - 1) {
                    val = data[keyPath[len]];
                    return;
                }
                var key_1 = keyPath[len];
                getVals_1(data[key_1], ++len);
            }
        };
        getVals_1(langDatas, 0);
    }
    else {
        val = langDatas[key];
    }
    return val;
}
exports.getLangs = getLangs;
