// 会员端字典
const routers = [
    'member_index',
    'member_science',
    'member_details',
    'member_association',
    'member_upload',
    'member_clock',
    'member_raffle',
    'member_preferential',
    'member_upload_push',
    'member_welfare',
    'member_receive',
    'member_fxdetails',
    'member_shou',
    'member_expert',
    'member_expert_m',
    'member_department',
    'member_department_xq',
    'member_science_m',
    'member_details_m',
    'member_my',
    'member_association_m',
    'member_fxdetails_m',
    'member_upload_m',
    'member_welfare_m',
    'member_upload_push_m',
	'member_preferential_m',
    'member_preferential_xq_m',
    'member_receive_m',
	'member_details_brief',
	'member_shou_m',
	'member_gydetails_m',
	'member_questionnaire_m',
	'member_collect',
	'member_new',
	'member_new_pc',
	'member_history_m',
	'member_history',
	'member_record',
	'member_record_m',
	'member_userCard',
	'member_userCard_m',
	'member_userCard_xq_m',
	'member_userCard_xq',
	'member_science_more',
]
export default routers