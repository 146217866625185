import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import router from '@/router';
import { useStore } from "vuex";
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
export default {
  name: 'HeaderPcMember',
  setup() {
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    const store = useStore(); // 该方法用于返回store 实例
    const text = ref('');
    let route = useRoute();
    console.log(user_info.value);
    const go = name => {
      router.push({
        name
      });
    };
    const go_details_brief = (name, id) => {
      router.push({
        name: name,
        params: {
          id: id
        }
      });
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    const shou = () => {
      if (text.value != '') {
        router.push({
          name: 'member_shou',
          params: {
            text: text.value
          }
        });
        text.value = '';
      } else {
        ElMessage.error('搜索内容不能为空');
      }
    };
    // 判断是否显示返回按钮
    const fh_btn = ref(localStorage.getItem('key'));
    // 监听
    watch(() => route.path, newValue => {
      fh_btn.value = localStorage.getItem('key');
    }, {
      immediate: true
    });
    return {
      user_info,
      go,
      go_details_brief,
      call,
      shou,
      text,
      fh_btn
    };
  }
};