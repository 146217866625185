"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storage = void 0;
// localStorage的读取、写入和删除操作模块
var tools_1 = require("@/utils/tools");
exports.storage = {
    save: function (key, value) {
        var v = typeof value === 'object' ? JSON.stringify(value) : value;
        tools_1.inBrowser && localStorage.setItem(key, v);
        return value;
    },
    load: function (key) {
        var v = tools_1.inBrowser && localStorage.getItem(key);
        try {
            v = JSON.parse(v);
        }
        catch (err) {
            return v;
        }
        return v;
    },
    remove: function (key) {
        tools_1.inBrowser && localStorage.removeItem(key);
    }
};
