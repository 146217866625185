import { ref } from 'vue'
import { inBrowser } from '@/utils'
import { useEventListener } from '.'

export function usePageVisibility () {
  const visibility = ref('visible')

  const setVisibility = () => {
    if (inBrowser) {
      visibility.value = document.hidden ? 'hidden' : 'visible'
    }
  }

  setVisibility()
  useEventListener('visibilitychange', setVisibility)

  return visibility
}
