import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { request } from '@/utils';
import { Toast, Dialog } from '@/components/ui';
export default defineComponent({
  name: '',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const router = useRouter();
    const store = useStore(); // 该方法用于返回store 实例
    const state = reactive({
      item: data,
      screenWidthFlag: '',
      x: '',
      y: ''
    });
    onMounted(() => {
      state.screenWidthFlag = window.screen.width >= 1024;
    });
    const fetchSet = {
      getCoupon: params => request.get('/coupon/getCouponById', params)
    };
    // 获取点击坐标
    const getMouseXY = (e, item) => {
      state.x = e.x; // 获取鼠标的X坐标（鼠标与屏幕左侧的距离，单位为px）
      state.y = e.y; // 获取鼠标的Y坐标（鼠标与屏幕顶部的距离，单位为px）

      const hbl = e.target.height / item.base.height;
      const h = e.pageY - e.target.offsetTop;
      const eh = parseInt(h / hbl);
      const wbl = e.target.width / item.base.width;
      const w = e.pageX - e.target.offsetLeft;
      const ew = parseInt(w / wbl);
      const data = item.data;
      for (let i = 0; i < data.length; i++) {
        const rwend = data[i].width + data[i].startX;
        const rhend = data[i].height + data[i].startY;
        if (data[i].startY < eh && eh < rhend && data[i].startX < ew && ew < rwend) {
          if (data[i].linkPage === 'activityPage') {
            // router.push({
            //   path: '/activePage',
            //   query: { id: data[i].id.id }
            // })
            console.log(store);
            store.commit('serviceShow', true);
          } else if (data[i].linkPage === 'coupon') {
            fetchSet.getCoupon({
              couponId: data[i].id
            }).then(res => {
              const {
                code,
                message
              } = res;
              if (code === 200) {
                GetCouponsPop('Successfully received');
              } else {
                GetCouponsPop(message);
              }
            }).catch(err => {
              Toast(err);
            });
          } else {
            document.location.href = data[i].url;
          }
          // document.location.href = data[i].url
        }
      }
    }; // 变成指针
    // const pointerChange = ref("default");
    const pointerChange = ref(false);
    // 鼠标移动
    const mouseSite = (e, item) => {
      state.x = e.x; // 获取鼠标的X坐标（鼠标与屏幕左侧的距离，单位为px）
      state.y = e.y; // 获取鼠标的Y坐标（鼠标与屏幕顶部的距离，单位为px）

      const hbl = e.target.height / item.base.height;
      const h = e.pageY - e.target.offsetTop;
      const eh = parseInt(h / hbl);
      const wbl = e.target.width / item.base.width;
      const w = e.pageX - e.target.offsetLeft;
      const ew = parseInt(w / wbl);
      const data = item.data;
      for (let i = 0; i < data.length; i++) {
        const rwend = data[i].width + data[i].startX;
        const rhend = data[i].height + data[i].startY;
        if (data[i].startY < eh && eh < rhend && data[i].startX < ew && ew < rwend) {
          pointerChange.value = true;
          return false;
        } else {
          pointerChange.value = false;
        }
      }
    };
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };
    return {
      ...toRefs(state),
      getMouseXY,
      mouseSite,
      pointerChange
    };
  }
});