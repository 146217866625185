import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-393d2e4f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "components-unit-goodsScroll"
};
const _hoisted_2 = ["src", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (i, t) => {
    return _openBlock(), _createElementBlock("div", {
      key: t,
      class: "components-unit-goodsScroll-ones"
    }, [_createElementVNode("img", {
      src: i.imgUrl,
      alt: "",
      onClick: $event => _ctx.toLinkUrl(i)
    }, null, 8, _hoisted_2)]);
  }), 128))]);
}