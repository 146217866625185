import {
  ref,
  computed,
  watch,
  defineComponent,
  onBeforeUnmount,
  onActivated,
  onDeactivated
} from 'vue'
import {
  truthProp,
  useExpose,
  raf,
  cancelRaf,
  parseFormat
} from '../utils'
import { inBrowser } from '@/utils'

const props = {
  autoStart: truthProp,
  millisecond: Boolean,
  time: {
    type: [Number, String],
    default: 0
  },
  format: {
    type: String,
    default: 'HH:mm:ss'
  }
}
const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

const parseTime = (time) => {
  const days = Math.floor(time / DAY)
  const hours = Math.floor((time % DAY) / HOUR)
  const minutes = Math.floor((time % HOUR) / MINUTE)
  const seconds = Math.floor((time % MINUTE) / SECOND)
  const milliseconds = Math.floor(time % SECOND)

  return {
    total: time,
    days,
    hours,
    minutes,
    seconds,
    milliseconds
  }
}

const isSameSecond = (time1, time2) => {
  return Math.floor(time1 / 1000) === Math.floor(time2 / 1000)
}

export default defineComponent({
  name: 'g-countdown',

  props,

  emits: ['change', 'finish'],

  setup (props, { emit, slots }) {
    let rafId, endTime, counting, deactivated

    const remain = ref(+props.time)
    const current = computed(() => parseTime(remain.value))

    const pause = () => {
      counting = false
      cancelRaf(rafId)
    }

    const getCurrentRemain = () => Math.max(endTime - Date.now(), 0)

    const setRemain = (value) => {
      remain.value = value
      emit('change', current.value)
      if (value === 0) {
        pause()
        emit('finish')
      }
    }

    const microTick = () => {
      rafId = raf(() => {
        if (counting) {
          setRemain(getCurrentRemain())

          if (remain.value > 0) {
            microTick()
          }
        }
      })
    }

    const macroTick = () => {
      rafId = raf(() => {
        if (counting) {
          const remainRemain = getCurrentRemain()

          if (!isSameSecond(remainRemain, remain.value) || remainRemain === 0) {
            setRemain(remainRemain)
          }

          if (remain.value > 0) {
            macroTick()
          }
        }
      })
    }

    const tick = () => {
      if (!inBrowser) {
        return
      }
      if (props.millisecond) {
        microTick()
      } else {
        macroTick()
      }
    }

    const start = () => {
      if (!counting) {
        endTime = Date.now() + remain.value
        counting = true
        tick()
      }
    }

    const reset = (totalTime = +props.time) => {
      pause()
      remain.value = totalTime
    }

    const timeText = computed(() => parseFormat(props.format, current.value))

    const resetTime = () => {
      reset(+props.time)

      if (props.autoStart) {
        start()
      }
    }

    watch(() => props.time, resetTime, { immediate: true })

    useExpose({
      start,
      pause,
      reset: resetTime
    })

    onBeforeUnmount(pause)

    onActivated(() => {
      if (deactivated) {
        counting = true
        deactivated = false
        tick()
      }
    })

    onDeactivated(() => {
      if (counting) {
        pause()
        deactivated = true
      }
    })

    return () => (
      <div class='g-count-down'>
        { slots.default ? slots.default(current.value) : timeText.value }
      </div>
    )
  }
})
