import {
  defineComponent
} from 'vue'

const name = 'g-empty'

const props = {
  image: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
}

export default defineComponent({
  name,
  props,
  setup (props, { slots }) {
    return () => (
      <div class='g-empty'>
        <img class='g-empty-img' v-show={props.image} src={props.image} />
        <p class='g-empty-desc' v-show={props.description}>{props.description}</p>
        <div class='g-empty-bottom'>
          { slots.default?.() }
        </div>
      </div>
    )
  }
})
