import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'g-tags',
  props: {
    // tags数据
    list: {
      type: Array,
      default: () => (
        [{
          id: '001',
          label: 'xxx'
        },
        {
          id: '002',
          label: 'yyy'
        },
        {
          id: '003',
          label: 'zzz'
        }]
      )
    },
    // list数据项的key
    listKey: {
      type: Object,
      default: () => ({ id: 'id', label: 'label' })
    },
    // 当前选中项
    active: {
      type: Array,
      default: () => []
    },
    // 是否多选（默认单选）
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:active', 'change'],
  setup (props, context) {
    const activeArr = ref([])
    watch(() => props.active, (val) => {
      activeArr.value = val
    })

    const tagClick = id => {
      if (props.multiple) {
        const index = activeArr.value.indexOf(id)
        if (index > -1) {
          activeArr.value.splice(index, 1)
        } else {
          activeArr.value.push(id)
        }
      } else {
        if (activeArr.value.includes(id)) {
          activeArr.value.splice(0, 1)
        } else {
          activeArr.value.length = 0
          activeArr.value.push(id)
        }
      }
      context.emit('update:active', activeArr.value)
      context.emit('change', activeArr.value)
    }

    const renderList = () => {
      const listKey = props.listKey
      return props.list.map((item, index) => {
        return (
          <span key={item[listKey.id] || index}
            class={['tags-item', { active: props.active.includes(item[listKey.id]) }]}
            onClick={tagClick.bind(this, item[listKey.id])}
          >
            { item[listKey.label] }
          </span>
        )
      })
    }

    const renderTags = () => {
      return (
        <div class="tags-wrap">
          <div class="tags-inner">
            { renderList() }
          </div>
        </div>
      )
    }
    return () => {
      return (
        <>
          {renderTags()}
        </>
      )
    }
  }
})
