import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
const __default__ = {
  name: 'seek_m',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace
  },
  setup() {
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const tabList = ref([{
      name: '牙齿不适',
      img: 'discomfort',
      key: '83'
    }, {
      name: '牙齿缺损',
      img: 'defect',
      key: '84'
    }, {
      name: '牙齿不美观',
      img: 'Unattractive',
      key: '85'
    }, {
      name: '牙齿变色',
      img: 'discoloration',
      key: '89'
    }, {
      name: '儿牙专科',
      img: 'children',
      key: '82'
    }]);
    const TabData = ref([]);
    const SelectedTab = ref(0);
    const tabDatastate = ref(false);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=96';
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
    });
    const tabClick = (index, id) => {
      router.push({
        name: 'seek_details_m',
        params: {
          id: id
        }
      });
      // SelectedTab.value = index
      // getTabData(id)
    };

    return {
      get_data,
      Client,
      tabList,
      SelectedTab,
      TabData,
      tabDatastate,
      tabClick
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "40b19f8d": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;