import {
  ref,
  watch,
  nextTick,
  computed,
  toRaw,
  onMounted,
  onBeforeUnmount,
  defineComponent
} from 'vue'

import { extend, truthProp } from '../utils'

// @popperjs/core   https://github.com/popperjs/popper-core
import { createPopper } from '@popperjs/core'

// Composables
import { useClickAway } from '../composables/useClickAway'

import Popup from '../popup'

const popoverProps = {
  show: Boolean,
  theme: {
    type: String,
    default: 'light' // gradient:渐变 dark: 暗黑
  },
  overlay: Boolean,
  actions: [],
  trigger: {
    type: String,
    default: 'click'
  },
  duration: [Number, String],
  showArrow: truthProp,
  placement: {
    type: String,
    default: 'bottom'
  },
  fallbackPlacements: Array, // 展示位置列表: 定义后备展示位置,如果需要锁定,传固定的位置即可, 参考popperjs
  iconPrefix: String,
  overlayClass: null,
  overlayStyle: Object,
  closeOnClickAction: truthProp,
  closeOnClickOverlay: truthProp,
  closeOnClickOutside: truthProp,
  offset: {
    type: Array,
    default: () => [0, 8]
  },
  teleport: {
    type: [String, Object],
    default: 'body'
  },
  // hover显示的内容
  content: {
    type: String,
    default: ''
  },
  // hover场景是否通过icon触发
  showTiggerIcon: {
    type: Boolean,
    default: false
  },
  // 触发hover的icon
  triggerIconClass: {
    type: String,
    default: ''
  },
  warpClass: {
    type: String,
    default: ''
  }
}

export default defineComponent({
  name: 'g-popover',
  props: popoverProps,
  emits: ['select', 'touchstart', 'update:show'],
  setup (props, { emit, slots, attrs }) {
    let popper = null

    const wrapperRef = ref()
    const popoverRef = ref()
    const contentRef = ref()
    const arrowRef = ref()
    const newProps = ref(toRaw(props)) // 主要是处理hover show字段
    const isHover = computed(() => props.trigger === 'hover')
    const isClick = computed(() => props.trigger === 'click')

    watch(() => props, val => {
      newProps.value = val
    }, {
      immediate: true,
      deep: true
    })

    const createPopperInstance = () => {
      if (wrapperRef.value && popoverRef.value) {
        return createPopper(wrapperRef.value, popoverRef.value.popupRef.value, {
          placement: props.placement,
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                adaptive: false,
                gpuAcceleration: false
              }
            },
            {
              name: 'arrow',
              options: {
                element: arrowRef.value
              }
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: props.fallbackPlacements
              }
            },
            extend(
              {
                name: 'offset',
                options: {
                  offset: [0, 8]
                }
              },
              {
                options: {
                  offset: props.offset
                }
              }
            )
          ]
        })
      }
      return null
    }

    const updateLocation = () => {
      nextTick(() => {
        if (!props.show) {
          return
        }

        if (!popper) {
          popper = createPopperInstance()
        } else {
          popper.setOptions({
            placement: props.placement
          })
        }
      })
    }

    const updateShow = value => emit('update:show', value)

    const onClickWrapper = () => { isClick.value && updateShow(!props.show) }

    // 下拉菜单点击事件处理
    const onClickAction = (action, index) => {
      if (action.disabled) return
      emit('select', action, index)
      props.closeOnClickAction && updateShow(false)
    }

    const onClickAway = (event) => {
      if (
        props.closeOnClickOutside &&
        (!props.overlay || props.closeOnClickOverlay) &&
        !contentRef.value?.contains(event.target)
      ) {
        updateShow(false)
      }
    }
    const hoverShowPoper = () => { newProps.value.show = true }
    const hoverhidePoper = () => { newProps.value.show = false } // 交互待优化

    // 下拉菜单渲染
    const renderAction = (action, index) => {
      const { icon, text, color, className } = action
      return (
        <div
          role="menuitem"
          class={['action', className]}
          style={{ color }}
          onClick={() => onClickAction(action, index)}
        >
          {icon && <i class={['iconfont', icon]} />}
          <div class={['action-text']}>{text}</div>
        </div>
      )
    }
    // hover or click 触发范围
    const tiggerArea = () => {
      return (
        <>
          {
            isHover.value
              ? <span
                ref={wrapperRef}
                class="g-popover__wrapper"
                onmouseenter={hoverShowPoper}
                onmouseleave={hoverhidePoper}
              >
                {
                  props.showTiggerIcon ? <i class={[`iconfont-g ${props.triggerIconClass} g-popover__hover-icon`]} /> : (slots.reference?.() || slots?.default())
                }
              </span>
              : <span
                ref={wrapperRef}
                class="g-popover__wrapper"
                onClick={onClickWrapper}
              >
                {slots.reference?.()}
              </span>
          }
        </>
      )
    }

    // html渲染
    const renderHtml = () => {
      return (
        <>
          {tiggerArea()}
          <Popup
            ref={popoverRef}
            class={[`g-popover ${props.warpClass} g-popover--${props.theme}`]}
            position={''}
            transition="g-popover-zoom"
            lockScroll={false}
            onUpdate={updateShow}
            {...attrs}
            {...newProps.value}
            onmouseenter={isHover.value ? hoverShowPoper : () => {}}
            onmouseleave={isHover.value ? hoverhidePoper : () => {}}
          >
            {props.showArrow && <div class="g-popover__arrow" ref={arrowRef} />}
            <div ref={contentRef} role="menu" class={['g-popover__content', isHover.value && 'g-popover__content-hover']}>
              {isHover.value && (props.content || (slots.content && slots.content()) || (slots.default && slots.default()))}
              {!isHover.value && (slots.content ? slots.content() : slots.default ? slots.default() : props.actions?.map(renderAction))}
            </div>
          </Popup>
        </>
      )
    }

    onMounted(updateLocation)
    onBeforeUnmount(() => {
      if (popper) {
        popper.destroy()
        popper = null
      }
    })

    watch(() => [props.show, props.placement], updateLocation)

    useClickAway(wrapperRef, onClickAway, { eventName: 'click' })

    return () => (
      <>
        {renderHtml()}
      </>
    )
  }
})
