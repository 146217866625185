import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { request } from '@/utils';
import { Toast, Dialog } from '@/components/ui';
export default defineComponent({
  name: '',
  props: ['data'],
  setup(props) {
    const {
      data,
      pageData
    } = {
      ...props
    };
    const item = data;
    const router = useRouter();
    const state = reactive({});
    // 跳转链接
    const fetchSet = {
      getCoupon: params => request.get('/coupon/getCouponById', params)
    };
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };
    const toLinkUrl = i => {
      if (i.linkPage === 'activityPage') {
        router.push({
          path: '/activePage',
          query: {
            id: i.id.id
          }
        });
      } else if (i.linkPage === 'coupon') {
        fetchSet.getCoupon({
          couponId: i.id
        }).then(res => {
          const {
            code,
            message
          } = res;
          if (code === 200) {
            GetCouponsPop('Successfully received');
          } else {
            GetCouponsPop(message);
          }
        }).catch(err => {
          Toast(err);
        });
      } else {
        document.location.href = i.url;
      }
    };
    return {
      item,
      ...toRefs(state),
      toLinkUrl
    };
  }
});