import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: 'member_record_m',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    const hd_data = ref({
      ...route.query
    });
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 获取记录
    const getUserRecord = () => {
      axios({
        url: api.baseUrl + api.clinic + '?userId=' + user_info.value.id,
        method: 'get'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
      });
    };
    const look = url => {
      window.open(url);
    };
    const fh = () => {
      router.go(-1);
    };
    getUserRecord();
    return {
      upList,
      look,
      fh
    };
  }
};