import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
export default {
  name: 'member_science_more',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    // tab栏目
    const tabList = ref([{
      name: '正畸',
      key: 'zhengji'
    }, {
      name: '种植',
      key: 'zhongzhi'
    }, {
      name: '修复',
      key: 'xiufu'
    }, {
      name: '综合',
      key: 'zonghe'
    }, {
      name: '肌功能',
      key: 'jigongneng'
    }, {
      name: '儿牙',
      key: 'erya'
    }]);

    // 当前选中的tab
    const tabIndex = ref(0);
    // 展示信息
    const get_data = ref({});
    // 获取上传资料列表
    const get_list = () => {
      let url = api.baseUrl + api.text + '?category=science&category2=' + tabList.value[tabIndex.value].key + '&isNotes=0';
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
      });
    };
    const go = e => {
      router.push({
        name: 'member_details',
        params: {
          id: e
        }
      });
    };
    // tab点击事件
    const tabClick = e => {
      if (tabIndex.value != e) {
        tabIndex.value = e;
        get_list();
        // get_attention()
      }
    };

    get_list();
    return {
      go,
      upList,
      tabList,
      tabIndex,
      get_data,
      tabClick
    };
  }
};