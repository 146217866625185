import {
  defineComponent,
  inject
} from 'vue'
export default defineComponent({
  name: 'g-checkbox',
  props: {
    value: [Array],
    label: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit, attrs, slots }) {
    const modelCheckbox = inject('modelCheckbox')
    const updata = (value) => {
      if (modelCheckbox && modelCheckbox.value) {
        const model = modelCheckbox.value
        const index = model.value.indexOf(value)
        if (model.value.find(x => x === value)) {
          model.value.splice(index, 1)
        } else {
          model.value.push(value)
        }
        modelCheckbox.update(value)
      } else {
        emit('update:value', !props.value)
      }
    }
    const checkboxType = () => {
      if (modelCheckbox && modelCheckbox.value) {
        const model = modelCheckbox.value
        return (
          <span className={`iconfont-g ${props.label === model.value.find(x => x === props.label) ? 'i-g-checkout' : 'i-g-no-checkout'} ${props.disabled ? 'i-g-disabled' : ''}` }>
            <span class="inner-after"></span>
          </span>
        )
      } else {
        return (
          <span className={`iconfont-g ${props.value ? 'i-g-checkout' : 'i-g-no-checkout'} ${props.disabled ? 'i-g-disabled' : ''} `}>
            <span class="inner-after"></span>
          </span>
        )
      }
    }
    const renderCheckBox = () => {
      // if (props.disabled) {
      //   return (
      //     <span class="g-checkbox">
      //       <span class={`iconfont i-g-disabled ${props.value ? 'i-g-checkout' : 'i-g-no-checkout'}`}>
      //         <span class="inner-after"></span>
      //       </span>
      //       {props.name ? props.name : slots.default?.()}
      //     </span>
      //   )
      // } else {
      return (
        <span class="g-checkbox" onClick={props.disabled ? '' : updata.bind(this, props.label)}>
          {checkboxType()}
          {props.name ? props.name : slots.default?.()}
        </span>
      )
      // }
    }
    return () => {
      return (
        <>
          {renderCheckBox()}
        </>
      )
    }
  }
})
