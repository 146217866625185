import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'agreement',
  setup() {
    const route = useRoute();
    const get_data = ref({});
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
  }
};