import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
export default {
  name: 'member_association_m',
  setup() {
    const upList = ref([]);
    // 获取上传资料列表
    const get_list = () => {
      let url = api.baseUrl + api.case;
      axios({
        url,
        method: 'post'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
      });
    };
    const go = e => {
      router.push({
        name: 'member_fxdetails_m',
        params: {
          id: e
        }
      });
    };
    const fh = () => {
      router.go(-1);
    };
    get_list();
    return {
      go,
      upList,
      fh
    };
  }
};