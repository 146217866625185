import { Transition, defineComponent, watch, ref } from 'vue'
import { isDef } from '../utils'

// const isNumeric = (val) => typeof val === 'number'
const getZIndexStyle = (zIndex) => {
  const style = {}
  if (isDef(zIndex)) {
    style.zIndex = +zIndex
  }
  return style
}

export default defineComponent({
  name: 'g-overlay',

  props: {
    show: Boolean,
    zIndex: [Number, String],
    duration: [Number, String],
    className: String,
    lockScroll: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const inited = ref(false)

    watch(() => props.show, (value) => {
      if (value) {
        inited.value = value
      }
    }, {
      immediate: true
    })

    const renderOverlay = () => {
      const style = getZIndexStyle(props.zIndex)
      if (isDef(props.duration)) {
        style.animationDuration = `${props.duration}s`
      }

      return (
        <div
          v-show={props.show}
          style={style}
          class={['g-overlay', props.className]}
        >
        </div>
      )
    }

    return () => <Transition name='g-fade'>{renderOverlay()}</Transition>
  }
})
