import { defineComponent, reactive, toRefs, onBeforeMount, onMounted } from 'vue';
export default defineComponent({
  name: 'lowCode',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      iframeHeight: ''
    });
    onBeforeMount(() => {});
    onMounted(() => {
      if (document.getElementById('iframe')) {
        handleShowMessage(data.data);
      }
    });
    const handleShowMessage = content => {
      // console.log(content)
      const iframe = document.getElementById('iframe');
      // console.log(iframe)
      if (iframe !== null && content.length > 0) {
        const ifrdoc = iframe.contentWindow.document;
        // 开启文档的编辑模式
        ifrdoc.designMode = 'on';
        // 打开数据流
        ifrdoc.open();
        // 写入内容
        ifrdoc.write(content);
        // 关闭数据流
        ifrdoc.close();
        // 关闭文档编辑模式
        ifrdoc.designMode = 'off';
      } else {
        iframe.style.height = '0';
      }
    };
    const iframeload = () => {
      const cIframe = document.getElementById('iframe');
      const aWindow = cIframe.contentWindow;
      const aWindowHeight = aWindow.document.documentElement.scrollHeight || aWindow.document.body.scrollHeight;
      const doc = cIframe.contentDocument || cIframe.document;
      const cHeight = Math.max(doc.body.clientHeight, doc.documentElement.clientHeight);
      const sHeight = Math.max(doc.body.scrollHeight, doc.documentElement.scrollHeight);
      const lheight = Math.max(cHeight, sHeight);
      const finalHeight = Math.max(lheight, aWindowHeight);
      // console.log(finalHeight)
      state.iframeHeight = finalHeight;
    };
    return {
      ...toRefs(state),
      iframeload
    };
  }
});