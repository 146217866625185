import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
export default {
  name: 'FooterMobile',
  setup() {
    const active = ref(0);
    const store = useStore(); // 该方法用于返回store 实例
    // 获取当前页面
    const is_show = computed(() => {
      let isShow = ['member_index', 'member_science_m', 'member_my'];
      // console.log();
      return isShow.includes(store.state.routerKey);
    });
    return {
      active,
      is_show
    };
  }
};