import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
export default {
  name: 'member_fxdetails',
  setup() {
    const route = useRoute();
    const get_data = ref({});
    // console.log(route.params);
    let url = api.baseUrl + api.selectById + route.params.id;
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      get_data.value = data.data;
    });
    return {
      get_data
    };
  }
};