import {
  ref,
  watch,
  computed,
  defineComponent,
  onMounted,
  Transition,
  Teleport,
  onDeactivated
} from 'vue'
import { extend, isDef, truthProp, useExpose, callInterceptor } from '../utils'
import { useLazyRender } from '../composables/useLazyRender'
import Overlay from '../overlay'

let globalIndex = 2000

const popupProps = {
  show: Boolean,
  zIndex: [Number, String],
  overlay: truthProp,
  duration: [Number, String],
  teleport: [String, Object],
  lockScroll: truthProp,
  lazyRender: truthProp,
  beforeClose: Function,
  overlayClass: String,
  transitionAppear: Boolean,
  closeOnClickOverlay: truthProp
}
export default defineComponent({
  name: 'g-popup',

  inheritAttrs: false,

  props: extend({}, popupProps, {
    closeable: Boolean,
    transition: String,
    closeOnPopState: Boolean,
    position: {
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        if (value === '') return true
        return (
          ['top', 'left', 'bottom', 'right', 'center'].indexOf(value) !== -1
        )
      },
      default: 'center'
    }
  }),

  emits: [
    'open',
    'close',
    'click',
    'opened',
    'closed',
    'update:show',
    'click-overlay',
    'click-close-icon'
  ],

  setup (props, { emit, attrs, slots }) {
    let opened

    const zIndex = ref()
    const popupRef = ref()

    const lazyRender = useLazyRender(() => props.show || !props.lazyRender)

    const style = computed(() => {
      const style = {
        zIndex: zIndex.value
      }

      if (isDef(props.duration)) {
        const key =
          props.position === 'center'
            ? 'animationDuration'
            : 'transitionDuration'
        style[key] = `${props.duration}s`
      }

      return style
    })

    const open = () => {
      if (!opened) {
        if (props.zIndex !== undefined) {
          globalIndex = +globalIndex
        }
        opened = true
        zIndex.value = ++globalIndex

        emit('open')
      }
    }

    const close = () => {
      if (opened) {
        callInterceptor({
          interceptor: props.beforeClose,
          done () {
            opened = false
            emit('close')
            emit('update:show', false)
          }
        })
      }
    }

    const onClickOverlay = event => {
      emit('click-overlay', event)

      if (props.closeOnClickOverlay) {
        close()
      }
    }

    const renderOverlay = () => {
      if (props.overlay) {
        return (
          <Overlay
            v-slots={{ default: slots['overlay-content'] }}
            show={props.show}
            class={props.overlayClass}
            zIndex={zIndex.value}
            duration={props.duration}
            onClick={onClickOverlay}
          />
        )
      }
    }

    const onClickCloseIcon = event => {
      emit('click-close-icon', event)
      close()
    }

    const renderCloseIcon = () => {
      if (props.closeable) {
        return (
          <i
            class="iconfont-g icon-close close-btn"
            onClick={onClickCloseIcon}
          />
        )
      }
    }

    const onClick = event => emit('click', event)
    const onOpened = () => emit('opened')
    const onClosed = () => emit('closed')

    const renderPopup = lazyRender(() => {
      const { position } = props
      return (
        <div
          v-show={props.show}
          ref={popupRef}
          style={style.value}
          class={`g-popup g-popup-${position}`}
          onClick={onClick}
          {...attrs}
        >
          {slots.default?.()}
          {renderCloseIcon()}
        </div>
      )
    })

    const renderTransition = () => {
      const { position, transition, transitionAppear } = props
      const name =
        position === 'center' ? 'g-fade' : `g-popup-slide-${position}`

      return (
        <Transition
          name={transition || name}
          appear={transitionAppear}
          onAfterEnter={onOpened}
          onAfterLeave={onClosed}
        >
          {renderPopup()}
        </Transition>
      )
    }

    watch(
      () => props.show,
      value => {
        if (value) {
          open()
        } else {
          opened = false
          emit('close')
        }
      }
    )

    useExpose({ popupRef })

    /* watch(() => props.show, (value) => {
      if (props.lockScroll) {

      }
    }) */

    onMounted(() => {
      if (props.show) {
        open()
      }
    })

    onDeactivated(() => {
      if (props.show) {
        close()
      }
    })

    return () => {
      if (props.teleport) {
        return (
          <Teleport to={props.teleport}>
            {renderOverlay()}
            {renderTransition()}
          </Teleport>
        )
      }

      return (
        <>
          {renderOverlay()}
          {renderTransition()}
        </>
      )
    }
  }
})
