import { defineComponent, Teleport, ref, computed, watch } from 'vue'
import { truthProp } from '../utils'
import { useEventListener } from '../composables'

export default defineComponent({
  name: 'g-select',

  inheritAttrs: false,

  props: {
    show: Boolean,
    active: [String, Number],
    list: {
      type: Array,
      default: () => []
    },
    duration: {
      type: [Number, String],
      default: 300
    },
    teleport: [String, Object],
    className: String,
    // click: Function,
    stop: Boolean,
    IndexItem: [String, Number],
    maxHeight: [String, Number],
    position: {
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['bottom-right', 'bottom-left', 'top-right', 'top-left'].indexOf(value) !== -1
      },
      default: 'bottom-right'
    },
    closeOnClickBody: truthProp
  },

  emits: ['select', 'change', 'update:show'],

  setup (props, { emit, slots }) {
    const active = computed(() => props.active)
    const list = computed(() => props.list)
    const maxHeight = ref(parseFloat(props.maxHeight))
    const position = ref(props.position)
    const $dom = ref()
    const horizontal = position.value.indexOf('left') > -1 ? 'left' : 'right'
    const vertical = position.value.indexOf('bottom') > -1 ? 'bottom' : 'top'

    // 控制根节点销毁
    const domShow = ref(Boolean(props.show))
    // 控制动画
    const listShow = ref(Boolean(props.show))

    const updateShow = value => emit('update:show', value)

    watch(() => props.show, (value) => {
      if (value) {
        domShow.value = value
        setTimeout(() => {
          listShow.value = value
        }, 50)
      } else {
        listShow.value = value
        setTimeout(() => {
          domShow.value = value
        }, props.duration)
      }
    })
    const onClick = (item, index, event) => {
      if (props.closeOnClickBody || props.stop) {
        event.stopPropagation()
      }

      emit('select', item, index, event, props.IndexItem)

      if (item.value !== active.value) {
        emit('change', item.value, active.value)
      }

      updateShow(false)
    }

    if (props.closeOnClickBody) {
      useEventListener('click', () => {
        updateShow(false)
      })
    }

    const renderIcon = () => {
      return (
        <i class="iconfont i-g-gouxuan icon-tick" />
      )
    }

    const renderItem = (item, index) => {
      const isActive = active.value === item.value
      return slots.default
        ? slots.default(item, index)
        : <li class={[
          'g-select-item',
          {
            active: isActive
          }
        ]}
        onClick={onClick.bind(this, item, index)}
        >
          <span class="g-select-text">{ item.key }</span>
          { isActive ? renderIcon() : null }
        </li>
    }

    const calStyle = () => {
      const isBottom = vertical === 'bottom'
      const targetPosition = isBottom ? '-120%' : 0
      const initPosition = isBottom ? 0 : '120%'

      const res = [
        `transform: translateY(${listShow.value ? initPosition : targetPosition});`,
        `transitionDuration: ${(props.duration) / 1000}s`
      ]
      if (maxHeight.value) {
        res.push(`max-height: ${maxHeight.value}px;`)
      }

      return res
    }

    const renderList = () => {
      return <div
        ref={$dom}
        class={[
          'g-selector-wrap',
          `g-selector-${vertical}-${horizontal}`
        ]}
        v-show={ domShow.value }
      >
        <ul
          class={[
            'g-selector',
            props.className
          ]}
          style={calStyle()}
        >
          { list.value.map(renderItem) }
        </ul>
      </div>
    }

    return () => {
      if (props.teleport) {
        return (
          <Teleport to={props.teleport}>
            { renderList() }
          </Teleport>
        )
      }

      return renderList()
    }
  }
})
