
import { defineComponent, Transition, ref, inject } from 'vue'
import { TABS_KEY } from '../tabs/Tabs'
import { useParent } from '../composables'
export default defineComponent({
  name: 'g-tab',
  props: {
    name: {
      type: String,
      default: '0'
    },
    label: {
      type: String
    }
  },
  emits: ['change'],
  setup (props, { slots }) {
    let activeKey = ref()
    activeKey = inject('activeKey')
    const { index } = useParent(TABS_KEY)
    const renderTab = () => {
      return (
        <Transition name={index < 1 ? 'g-slide-left' : 'g-slide-left-reverse'}>
          {
            props.name === activeKey.value &&
              <div
                class="tab-content-ui">
                { slots.default() }
              </div>
          }
        </Transition>
      )
    }
    return () => {
      return (
        <>
          {renderTab()}
        </>
      )
    }
  }
})
