import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'accordion',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const store = useStore();
    const state = reactive({
      item: JSON.parse(JSON.stringify(data))
    });
    const openItem = (k, j) => {
      state.item.data[j].active = !state.item.data[j].active;
    };
    return {
      ...toRefs(state),
      openItem
    };
  }
});