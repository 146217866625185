import { ref } from "vue";
import router from '@/router';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
export default {
  name: 'member_department_xq',
  setup() {
    const route = useRoute();
    const query = ref({
      ...route.query
    });
    const get_data = ref([]);
    const fh = () => {
      router.go(-1);
    };
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=' + query.value.key;
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      // console.log(data);
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      console.log(get_data.value);
    });
    return {
      query,
      fh,
      get_data
    };
  }
};