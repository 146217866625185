import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import router from '@/router';
import { useStore } from 'vuex';
// import { el } from 'element-plus/es/locale';
export default {
  name: 'member_gydetails_m',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace
  },
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const route = useRoute();
    const get_data = ref({});
    // console.log(route.params);
    let url = api.baseUrl + (route.params.id != 4 ? api.activity : api.webDecor) + (route.params.id == 4 ? '?id=81' : route.params.id);
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      if (route.params.id != 4) {
        get_data.value = data.data;
      } else {
        // get_data.value = JSON.parse(data.data.editConfig)
        get_data.value = data.data;
        get_data.value.editConfig = JSON.parse(data.data.editConfig);
      }
    });
    const fh = () => {
      router.go(-1);
    };
    return {
      get_data,
      fh,
      Client,
      route
    };
  }
};