import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
export default {
  name: 'member_science_m',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    // tab栏目
    const tabList = ref([{
      name: '正畸专区',
      key: 'zhengji'
    }, {
      name: '种植专区',
      key: 'zhongzhi'
    }, {
      name: '修复专区',
      key: 'xiufu'
    }, {
      name: '综合专区',
      key: 'zonghe'
    }, {
      name: '肌功能专区',
      key: 'jigongneng'
    }, {
      name: '儿牙专区',
      key: 'erya'
    }]);
    // 当前选中的tab
    const tabIndex = ref(0);
    // 展示信息
    const get_data = ref({});
    // 当前选中的展示信息
    const select_index = ref(0);
    // 获取信息
    const get_text = () => {
      let url = api.baseUrl + api.text + '?category=science&category2=' + tabList.value[tabIndex.value].key + '&isNotes=0';
      axios({
        url,
        method: 'get'
      }).then(res => {
        // console.log(res);
        get_data.value.top = res.data.rows;
      });
    };
    // 顶部新闻切换事件
    const info_option = e => {
      select_index.value = e;
    };
    // tab点击事件
    const tabClick = e => {
      if (tabIndex.value != e) {
        tabIndex.value = e;
        get_text();
        // get_attention()
      }
    };
    // 查看图文详情
    const details = (e, index) => {
      if (index <= 2) {
        router.push({
          name: 'member_details_m',
          params: {
            id: e
          }
        });
      } else {
        // 判断有没有登录
        if (!localStorage.getItem('userData')) {
          show();
        } else {
          const user_info = ref(JSON.parse(localStorage.getItem('userData')));
          if (user_info.value.spent != '1') {
            show();
          } else {
            router.push({
              name: 'member_details_m',
              params: {
                id: e
              }
            });
          }
        }
      }
    };
    // 联系我们
    const call = () => {
      store.commit('serviceShow', true);
    };
    get_text();
    return {
      tabList,
      tabIndex,
      get_text,
      tabClick,
      get_data,
      select_index,
      info_option,
      details,
      call
    };
  }
};