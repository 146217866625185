import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import router from '@/router';
import axios from "axios";
import { api } from "@/api/api";
import { showConfirmDialog } from 'vant';
import { useStore } from "vuex";
export default {
  name: 'member_my',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    const userCard_list = ref(0);
    const getShowTask = ref(false);
    // list_bot
    const list = ref([
    // {
    //     name: '领劵中心',
    //     img: 'lj',
    //     go: 'member_receive_m'
    // },
    // {
    //     name: '资料补全',
    //     img: 'tx',
    //     go: 'member_questionnaire_m'
    // },
    // {
    //     name: '退出登录',
    //     img: 'tc',
    //     go: 'tc'
    // },
    [{
      name: '领劵中心',
      img: 'lj',
      go: 'member_receive_m'
    }, {
      name: '口腔肌功能训练公益报名',
      img: 'kq',
      go: 'member_welfare_m'
    }], [{
      name: '编辑资料',
      img: 'zl',
      go: 'member_questionnaire_m'
    }, {
      name: '关注公众号',
      img: 'gzh',
      pop: true,
      sTetx: '新用户可领取爱牙礼包'
    }], [{
      name: '退出登录',
      img: 'tc',
      go: 'tc'
    }]]);
    const go = item => {
      if (item.pop) {
        console.log('1');
        getShowTask.value = true;
        return;
      }
      if (item.go != 'tc') {
        router.push({
          name: item.go
        });
      } else {
        showConfirmDialog({
          title: '退出',
          message: '确定退出登录吗？'
        }).then(() => {
          // on confirm
          localStorage.clear();
          sessionStorage.clear();
          router.push({
            name: 'home'
          });
        }).catch(() => {
          // on cancel
        });
      }
    };
    // 获取用户信息
    const get_user_info = () => {
      let url = api.baseUrl + api.get_user_info + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        // 存临时缓存
        localStorage.setItem('userData', JSON.stringify(res.data.data));
        user_info.value = JSON.parse(localStorage.getItem('userData'));
      });
    };
    const get_userCard = () => {
      let url = api.baseUrl + api.userCard + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res.data;
        let num = 0;
        data.map(item => {
          if (item.status == '已激活') {
            num += 1;
          }
        });
        userCard_list.value = num;
      });
    };
    onMounted(() => {
      get_user_info();
      get_userCard();
    });
    const call = () => {
      store.commit('serviceShow', true);
    };
    const handleClose = () => {
      getShowTask.value = false;
    };
    return {
      user_info,
      list,
      go,
      userCard_list,
      call,
      getShowTask,
      handleClose
    };
  }
};