import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d1ce8850"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 2,
  class: "iframeDiv"
};
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["videoC", _ctx.item.base.isFullScreen ? '' : 'noFullScreen'])
  }, [_ctx.item.base.bgvideo && _ctx.Client == 0 ? (_openBlock(), _createElementBlock("video", {
    key: 0,
    style: {
      "width": "100%",
      "height": "100%"
    },
    autoplay: "",
    muted: "",
    loop: "",
    preoad: "",
    src: _ctx.item.base.bgvideo
  }, null, 8, _hoisted_1)) : _createCommentVNode("", true), _ctx.Client != 0 ? (_openBlock(), _createElementBlock("video", {
    key: 1,
    style: {
      "width": "100%",
      "height": "100%"
    },
    "x-webkit-airplay": "true",
    playsinline: "true",
    "x5-video-player-type": "h5",
    "x5-video-orientation": "h5",
    "x5-video-player-fullscreen": "true",
    preload: "auto",
    controls: "",
    muted: "",
    loop: "",
    src: _ctx.item.base.bgvideo
  }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _ctx.item.base.inputVideoUrl ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("iframe", {
    src: _ctx.item.base.inputVideoUrl + '?enablejsapi=1&modestbranding=1&autohide=1&showinfo=0&controls=0&mute=1&autoplay=1&loop=1&',
    frameborder: "0",
    allow: "autoplay; loop; encrypted-media",
    allowfullscreen: "",
    muted: "",
    class: "js-youtube lazyloaded",
    id: "iframeVideo"
  }, null, 8, _hoisted_4)])) : _createCommentVNode("", true)], 2);
}