import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted, reactive } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { ElMessage } from 'element-plus';
import router from '@/router';
export default defineComponent({
  name: 'popup',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showState: {
      type: Number,
      default: 0 //0为注册，1为登录，2为重置密码
    }
  },

  emits: ['modifyShowState'],
  setup(props, {
    emit
  }) {
    // 标题
    const title = ref(['欢迎注册', '欢迎来到米檬口腔', '重置密码']);
    // 按钮
    const btnText = ref(['注册', '登录', '重置']);
    // 提交表单数据
    const formData = ref({
      captcha: '',
      password: '',
      phone: ''
    });
    const popupState = ref(props.showState);
    const popupShow = ref(props.show);
    const loginState = ref(0);
    // 秒针
    const clock = ref(60);
    // 按钮文字
    const get_code_text = ref('获取验证码');
    // 定时器
    const alarm = ref(false);
    // 校验规则
    // const ruleFormRef = ref<FormInstance>()
    // const rules = reactive < FormRules < RuleForm >> ({
    //     phone: [
    //         { min: 11, max: 11, message: '请正确输入手机号' }
    //     ]
    // })
    // 更新弹窗状态
    watch(() => props.showState, newVal => {
      popupState.value = newVal;
    });
    // 更新弹窗是否显示
    watch(() => props.show, newVal => {
      popupShow.value = newVal;
    });
    // 修改当前状态
    const modifyShowState = e => {
      popupState.value = e;
      formData.value = {
        captcha: '',
        password: '',
        phone: ''
      };
    };
    // 登录状态修改
    const modifyLoginState = e => {
      loginState.value = e;
      formData.value = {
        captcha: '',
        password: '',
        phone: ''
      };
    };
    // 获取验证码
    const get_code = () => {
      if (!formData.value.phone) {
        ElMessage.error('请正确填写手机号码');
        return;
      }
      if (clock.value != 60) {
        ElMessage({
          message: '请' + clock.value + '秒后再试',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      let url = api.baseUrl + api.sms;
      axios({
        url,
        data: {
          mobile: formData.value.phone,
          type: popupState.value
        },
        method: 'post'
      }).then(res => {
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
          clock.value -= 1;
          get_code_text.value = clock.value + 's';
          alarm.value = true;
          runClock();
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };
    const runClock = () => {
      setTimeout(() => {
        if (clock.value > 0) {
          clock.value -= 1;
          get_code_text.value = clock.value + 's';
          runClock();
        } else {
          get_code_text.value = '获取验证码';
          alarm.value = false;
          clock.value = 60;
        }
      }, 1000);
    };
    // 提交表单
    const pushForm = () => {
      let urlList = [api.register, api.login, api.resetPassword];
      let {
        captcha,
        password,
        phone
      } = formData.value;
      let data = {};
      if (popupState.value == 0 || popupState.value == 2) {
        data = {
          captcha,
          password,
          phone
        };
        if (localStorage.getItem('source')) {
          data.source = localStorage.getItem('source');
        }
      } else if (popupState.value == 1) {
        if (loginState.value == 0) {
          data = {
            password,
            phone
          };
        } else {
          data = {
            captcha,
            phone
          };
        }
      }
      axios({
        url: api.baseUrl + urlList[popupState.value],
        method: "post",
        data
      }).then(res => {
        effect(res.data);
      });
    };
    // 效果处理
    const effect = data => {
      console.log(data);
      // 提示效果
      if (data.code == 200) {
        ElMessage({
          message: data.msg,
          type: 'success'
        });
      } else {
        console.log(1);
        ElMessage.error(data.msg);
      }
      // 成功结果处理
      if (data.code == 200) {
        if (popupState.value == 0 || popupState.value == 2) {
          // 注册成功
          // modifyShowState(1)
          popupState.value = 1;
          pushForm();
        } else if (popupState.value == 1) {
          // 登录成功
          // 存临时缓存
          localStorage.setItem('userData', JSON.stringify(data.data));
          // console.log(data.data);
          // 存本地缓存
          // 关闭弹窗
          handleClose();
          // 调用父组件获取用户信息
          emit('getUserData', {
            State: '0'
          });
          router.push({
            name: 'member_index'
          });
        }
      }
    };
    const handleClose = () => {
      emit('modifyShowState', {
        State: '0'
      });
    };
    // 服务条款
    const open = () => {
      let routeData = router.resolve({
        name: 'details',
        params: {
          id: 78
        }
      });
      window.open(routeData.href, '_blank');
    };
    const next = event => {
      if (event.keyCode == 13) {
        pushForm();
      }
    };
    return {
      title,
      formData,
      modifyShowState,
      popupState,
      popupShow,
      loginState,
      handleClose,
      modifyLoginState,
      btnText,
      pushForm,
      effect,
      get_code,
      get_code_text,
      clock,
      open,
      runClock,
      next
    };
  }
});