import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2ce7e884"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["img-nav", _ctx.item.base.isFullScreen ? '' : 'noFullScreen'])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (nav, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "img-nav-item",
      onClick: $event => _ctx.toLinkUrl(nav, _ctx.item.name),
      key: index
    }, [_createElementVNode("img", {
      class: "img",
      src: nav.imgUrl,
      alt: ""
    }, null, 8, _hoisted_2), _createElementVNode("span", null, _toDisplayString(nav.content), 1)], 8, _hoisted_1);
  }), 128))], 2);
}