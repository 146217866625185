import {
  defineComponent
} from 'vue'
export default defineComponent({
  name: 'g-circle',
  props: {
    className: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: ''
    }
  },
  setup (props, { emit, attrs, slots }) {
    const className = () => {
      return props.className ? props.className : attrs.class ? attrs.class : ''
    }
    const renderButton = () => {
      return (
        <span style={`width:${props.width}px`} className={
          `g-circle ${className()}`}>
          <span class="text" > { props.text ? props.text : slots.default?.()}</span>
        </span>
      )
    }
    return () => {
      return (
        <>
          { renderButton()}
        </>
      )
    }
  }
})
