import {
  watch,
  defineComponent,
  onMounted,
  onUnmounted
} from 'vue'
import Popup from '../popup'
import { isDef, lockClick } from '../utils'

export default defineComponent({
  name: 'FmsToast',

  props: {
    show: Boolean,
    overlay: Boolean,
    message: [Number, String],
    className: String,
    closeOnClick: Boolean,
    closeOnClickOverlay: Boolean,
    forbidClick: Boolean,
    duration: {
      type: Number,
      default: 2000
    },
    type: {
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['html', 'text', 'success', 'fail'].indexOf(value) !== -1
      },
      default: 'text'
    },
    position: {
      validator: function (value) {
        // 这个值必须匹配下列字符串中的一个
        return ['top', 'middle', 'bottom'].indexOf(value) !== -1
      },
      default: 'middle'
    }
  },

  emits: ['update:show'],

  setup (props, { emit }) {
    let timer
    let clickable = false

    const toggleClickable = () => {
      const newValue = props.show && props.forbidClick
      if (clickable !== newValue) {
        clickable = newValue
        lockClick(clickable)
      }
    }

    const updateShow = show => emit('update:show', show)

    const onClick = () => {
      if (props.closeOnClick) {
        updateShow(false)
      }
    }

    const clearTimer = () => clearTimeout(timer)

    const renderIcon = () => {
      const { type, iconPrefix } = props
      const hasIcon = type === 'success' || type === 'fail' || iconPrefix
      if (hasIcon) {
        const className = type === 'success' ? 'iconfont i-g-wancheng' : type === 'fail' ? 'iconfont i-g-jingshi' : ''
        return (
          <i class={[className, iconPrefix]}></i>
        )
      }
    }
    // iconPrefix: 'iconfont i-g-wancheng',
    // iconPrefix: 'iconfont i-g-jingshi',
    const renderMessage = () => {
      const { type, message } = props
      if (isDef(message) && message !== '') {
        return type === 'html'
          ? (
            <div class='toast-text' innerHTML={String(message)} />
            )
          : (
            <div class='toast-text'>{message}</div>
            )
      }
    }

    watch(() => [props.show, props.forbidClick], toggleClickable)

    watch(
      () => [props.show, props.type, props.message, props.duration],
      () => {
        clearTimer()
        if (props.show && props.duration > 0) {
          timer = setTimeout(() => {
            updateShow(false)
          }, props.duration)
        }
      }
    )

    onMounted(toggleClickable)
    onUnmounted(toggleClickable)

    return () => (
      <Popup
        show={props.show}
        class={['g-toast', `g-toast-${props.position}`, props.className]}
        overlay={props.overlay}
        closeOnClickOverlay={props.closeOnClickOverlay}
        lockScroll={false}
        onClick={onClick}
        onClosed={clearTimer}
        {...{ 'onUpdate:show': updateShow }}
      >
        {renderIcon()}
        {renderMessage()}
      </Popup>
    )
  }
})
