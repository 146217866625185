import {
  watch,
  unref,
  isRef,
  onUnmounted,
  onDeactivated
} from 'vue'
import { inBrowser } from '@/utils'
import { onMountedOrActivated } from '.'

export function useEventListener (type, listener, options = {}) {
  if (!inBrowser) {
    return
  }

  const { target = window, passive = false, capture = false } = options

  let attached

  const add = target => {
    const element = unref(target)

    if (element && !attached) {
      element.addEventListener(type, listener, { capture, passive })
      attached = true
    }
  }

  const remove = target => {
    const element = unref(target)

    if (element && attached) {
      element.removeEventListener(type, listener, capture)
      attached = false
    }
  }

  onUnmounted(() => remove(target))
  onDeactivated(() => remove(target))
  onMountedOrActivated(() => add(target))

  if (isRef(target)) {
    watch(target, (val, oldVal) => {
      remove(oldVal)
      add(val)
    })
  }
}
