import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Loading',
  props: {
    fill: {
      type: String,
      default: '#000'
    },
    showBg: {
      type: Boolean,
      default: false
    }
  }
});