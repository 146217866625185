import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "product-list-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_prodItem = _resolveComponent("prodItem");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, iKey) => {
    return _openBlock(), _createBlock(_component_prodItem, {
      class: _normalizeClass(["product-list-item px-2", `product-list-item__col-${_ctx.col}`]),
      key: item.productsId || iKey,
      data: item,
      "current-page": _ctx.currentPage,
      "current-index": iKey,
      "current-title": _ctx.currentTitle,
      "show-quick-buy": _ctx.showQuickBuy,
      showQuickView: _ctx.showQuickView,
      "add-wish": _ctx.showWish,
      "show-title": _ctx.showTitle,
      "show-Attr": _ctx.showAttr,
      "is-cart-hover": _ctx.isCartHover,
      "one-line": _ctx.col === 1,
      selectedColorName: _ctx.selectedColorName
    }, {
      option: _withCtx(({
        option
      }) => [_renderSlot(_ctx.$slots, "option", {
        option: option
      })]),
      _: 2
    }, 1032, ["class", "data", "current-page", "current-index", "current-title", "show-quick-buy", "showQuickView", "add-wish", "show-title", "show-Attr", "is-cart-hover", "one-line", "selectedColorName"]);
  }), 128))]);
}