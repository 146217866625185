import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import router from '@/router';
import { ElMessage } from 'element-plus';
import { showConfirmDialog } from 'vant';
import 'vant/es/dialog/style';
export default {
  name: 'member_history_m',
  setup() {
    const route = useRoute();
    const hd_data = ref({
      ...route.query
    });
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    console.log(hd_data.value);
    const get_data = ref([]);
    // 获取记录
    const getUserRecord = () => {
      axios({
        url: api.baseUrl + api.getUserRecord,
        method: 'post',
        data: {
          userId: user_info.value.id,
          benefitId: hd_data.value.benefitId,
          materialId: hd_data.value.id
        }
      }).then(res => {
        get_data.value = res.data.data;
      });
    };
    const fh = () => {
      router.go(-1);
    };
    // 删除记录
    const deleteBenefitActivityRecord = id => {
      showConfirmDialog({
        title: '删除',
        message: '确定删除吗？'
      }).then(() => {
        // on confirm
        axios({
          url: api.baseUrl + api.deleteBenefitActivityRecord + id,
          method: 'delete'
        }).then(res => {
          console.log(res);
          ElMessage({
            message: res.data.code == 200 ? '删除成功' : '删除失败',
            type: res.data.code == 200 ? 'success' : 'error',
            customClass: "message-m"
          });
          getUserRecord();
        });
      }).catch(() => {
        // on cancel
      });
    };
    const look = url => {
      window.open(url);
    };
    getUserRecord();
    return {
      fh,
      get_data,
      deleteBenefitActivityRecord,
      look
    };
  }
};