import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import footerPCM from './FooterPCM.vue';
import router from '@/router';
import axios from "axios";
export default {
  name: 'FooterPc',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const codeShowState = ref(false);
    const codeShowIndex = ref(0);
    const loginShow = ref(false);
    const showState = ref(1); //0为注册，1为登录
    const userData = ref(false);
    const route = useRoute();
    const show = () => {
      store.commit('serviceShow', true);
    };
    const go = name => {
      router.push({
        name
      });
    };
    const showCode = e => {
      codeShowIndex.value = e;
      codeShowState.value = true;
    };
    const hideCode = () => {
      codeShowState.value = false;
    };
    //了解更多
    const login = () => {
      loginShow.value = true;
    };
    const modifyShowState = e => {
      loginShow.value = false;
    };
    const getUserData = () => {
      console.log(localStorage.getItem('userData'));
      if (localStorage.getItem('userData')) {
        userData.value = true;
      } else {
        userData.value = false;
      }
    };
    getUserData();
    // 获取底部文字
    const textObj = ref({});
    const getTextObj = () => {
      axios({
        url: 'https://www.mimodental.com/assets/configuration.txt',
        method: 'get'
      }).then(res => {
        console.log(res);
        textObj.value = res.data;
        console.log('222222222222222222222222222222');
      });
    };
    getTextObj();
    return {
      Client,
      show,
      go,
      showCode,
      hideCode,
      codeShowState,
      login,
      modifyShowState,
      getUserData,
      userData,
      loginShow,
      showState,
      codeShowIndex,
      route,
      textObj
    };
  }
};