import router from '@/router';
import { useRoute } from 'vue-router';
import { useStore } from "vuex";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
export default {
  name: 'member_userCard_xq',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const route = useRoute();
    const hd_data = ref({
      ...route.query
    });
    console.log(hd_data);
    const call = () => {
      store.commit('serviceShow', true);
    };
    return {
      hd_data,
      call
    };
  }
};