import "core-js/modules/es.array.push.js";
import { ref, onMounted } from 'vue';
import router from '@/router';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import popup from '../../common/popup.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: 'HeaderPcM',
  components: {
    popup
  },
  setup() {
    const route = useRoute();
    const routeName = route.name;
    const key = ref('home');
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const showNavList = ref(false);
    const showLogin = ref(false);
    const showZonNavList = ref(false);
    const userData = ref(sessionStorage.getItem('userData') ? true : false);
    const nav_to = e => {
      // console.log(e);
      showNavList.value = false;
      router.push({
        name: e
      });
    };
    const nav_toParam = (e, id) => {
      showZonNavList.value = false;
      showNavList.value = false;
      router.push({
        name: e,
        params: {
          id: id
        }
      });
    };
    const showNavListAction = () => {
      showNavList.value = !showNavList.value;
      if (showLogin.value) {
        showLogin.value = false;
      }
    };
    const showLoginAction = () => {
      showLogin.value = !showLogin.value;
      if (showNavList.value) {
        showNavList.value = false;
      }
    };
    const quitLogin = () => {
      userData.value = false;
      localStorage.clear();
      sessionStorage.clear();
    };
    const showZonNavListAction = () => {
      showZonNavList.value = !showZonNavList.value;
      showNavList.value = !showNavList.value;
    };
    const go = () => {
      router.push({
        name: 'member_index'
      });
    };
    onMounted(() => {
      if (localStorage.getItem('userData')) {
        sessionStorage.setItem('userData', localStorage.getItem('userData'));
      }
      console.log(sessionStorage.getItem('userData') ? true : false);
      userData.value = sessionStorage.getItem('userData') ? true : false;
    });
    return {
      key,
      routeName,
      Client,
      nav_to,
      showNavList,
      showLogin,
      showNavListAction,
      showLoginAction,
      nav_toParam,
      userData,
      quitLogin,
      showZonNavListAction,
      showZonNavList,
      go
    };
  }
};