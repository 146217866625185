import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.item.base.isFullScreen ? '' : 'noFullScreen'),
    key: _ctx.item.base.isFullScreen
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, img => {
    return _openBlock(), _createElementBlock("img", {
      key: img,
      src: img.imgUrl,
      alt: "",
      style: {
        "width": "100%",
        "height": "auto"
      }
    }, null, 8, _hoisted_1);
  }), 128))], 2);
}