import "core-js/modules/es.array.push.js";
import { ref } from "vue";
import router from '@/router';
export default {
  name: 'member_department',
  setup() {
    const list = ref([{
      name: '口腔正畸',
      text: '儿童早期矫治、青少年及成人正畸，教授、硕博亲诊',
      img: 'correct',
      key: '72'
    }, {
      name: '口腔种植',
      text: '为各类牙齿缺失、牙齿缺损牙友提供数字化种植服务',
      img: 'plant',
      key: '73'
    }, {
      name: '牙周诊疗',
      text: '牙周综合诊疗、控制牙周问题，延长牙周寿命',
      img: 'treatment',
      key: '74'
    }, {
      name: '牙体牙髓',
      text: '减轻牙齿疼痛，完善的根管治疗，恢复牙齿形态',
      img: 'pulp',
      key: '75'
    }, {
      name: '儿童齿科',
      text: '童趣空间，情绪呵护，快乐看牙，健康成长',
      img: 'children',
      key: '76'
    }, {
      name: '牙齿美容',
      text: '科学焕白，黄牙逆袭，牙齿美白的正确打开方式',
      img: 'cosmetology',
      key: '77'
    }, {
      name: '微创拔牙',
      text: '拔牙安全性高、舒适度好、用户易接受，避免不良反应',
      img: 'extract',
      key: '78'
    }, {
      name: '牙齿修复',
      text: '针对牙齿形态不佳、牙齿缺损、过小牙等数字化美学修复',
      img: 'repair',
      key: '79'
    }, {
      name: 'PMTC口腔护理',
      text: '软垢去除、菌斑控制、牙齿亮白、口气清新、防蛀健齿',
      img: 'nurse',
      key: '80'
    }]);
    const go = item => {
      router.push({
        path: '/member_department_xq',
        query: {
          ...item
        }
      });
    };
    const fh = () => {
      router.go(-1);
    };
    return {
      list,
      go,
      fh
    };
  }
};