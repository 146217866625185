import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import router from '@/router';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: 'member_upload_push',
  setup() {
    const route = useRoute();
    console.log(route.query);
    const hd_data = ref({
      ...route.query
    });
    const push_data = ref({
      textarea: ''
    });
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 上传图片
    const afterReadImg = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file.file.size);
      const formData = new FormData();
      formData.append('file', file.file);
      const url = api.baseUrl + api.uploadIcon;
      axios.post(url, formData).then(res => {
        console.log(res);
        push_data.value.img = res.data.data.url;
      });
    };
    // const beforeReadVido = (file)=>{
    //     var url = window.URL.createObjectURL(file);
    //         var audioElement = new Audio(url);
    //         var self = this;
    //         var result;
    //         audioElement.addEventListener("loadedmetadata", function() {
    //             // 视频时长值的获取要等到这个匿名函数执行完毕才产生
    //             result = audioElement.duration; //得到时长为秒，小数，182.36
    //             console.log(parseInt(result)) ; //转为int值

    //         });
    // }
    const beforeReadVido = file => {
      let url = window.URL.createObjectURL(file);
      let audioElement = new Audio(url);
      return new Promise((resolve, reject) => {
        audioElement.addEventListener('loadedmetadata', function () {
          // 视频时长值的获取要等到这个匿名函数执行完毕才产生
          let serce = parseInt(audioElement.duration);
          console.log(serce);
          //限制30秒
          if (serce > 30) {
            console.log(audioElement.muted);
            audioElement.muted = false;
            ElMessage.error('视频不能超过30秒');
            reject();
          } else {
            audioElement.muted = false;
            resolve();
          }
        });
      });
    };
    // 上传视频
    const afterReadVido = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      const formData = new FormData();
      formData.append('file', file.file);
      const url = api.baseUrl + api.uploadVideo;
      axios.post(url, formData).then(res => {
        console.log(res);
        push_data.value.vido = res.data.data.url;
      });
    };
    // 提交
    const tj = () => {
      console.log(push_data.img);
      //未上传图片和视频则提示
      if (!push_data.value.img && !push_data.value.vido) {
        ElMessage.error("图片或视频未上传");
        return;
      }
      axios({
        url: api.baseUrl + api.insertRecord,
        method: 'post',
        data: {
          userId: user_info.value.id,
          benefitId: 2,
          materialId: hd_data.value.id,
          imageUrl: push_data.value.img,
          videoUrl: push_data.value.vido,
          remark: push_data.value.textarea
        }
      }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          if (hd_data.value.uploadFlow - hd_data.value.doneFlow != 1) {
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            router.push({
              path: '/member_welfare'
            });
          } else {
            ElMessageBox.confirm(hd_data.value.finishedMessage, '恭喜打卡完成', {
              confirmButtonText: '完成训练',
              showCancelButton: false,
              center: true,
              callback: action => {
                router.push({
                  path: '/member_welfare'
                });
              }
            });
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 查看历史记录
    const history = () => {
      router.push({
        name: 'member_history',
        query: {
          ...hd_data.value
        }
      });
    };
    return {
      push_data,
      afterReadVido,
      afterReadImg,
      hd_data,
      tj,
      beforeReadVido,
      history
    };
  }
};