import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
const __default__ = {
  name: 'Zone',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace
  },
  setup() {
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const tabList = ref([{
      name: '口腔正畸',
      img: 'correct',
      key: '53',
      category: '60'
    }, {
      name: '口腔种植',
      img: 'plant',
      key: '54',
      category: '61'
    }, {
      name: '牙周诊疗',
      img: 'treatment',
      key: '55',
      category: '62'
    }, {
      name: '牙体牙髓',
      img: 'pulp',
      key: '56',
      category: '63'
    }, {
      name: '儿童齿科',
      img: 'children',
      key: '57',
      category: '64'
    }, {
      name: '牙齿美容',
      img: 'cosmetology',
      key: '58',
      category: '65'
    }, {
      name: '微创拔牙',
      img: 'extract',
      key: '59',
      category: '66'
    }, {
      name: '牙齿修复',
      img: 'repair',
      key: '60',
      category: '67'
    }, {
      name: 'PMTC口腔护理',
      img: 'nurse',
      key: '61',
      category: '68'
    }]);
    const TabData = ref([]);
    const SelectedTab = ref(0);
    const tabDatastate = ref(false);
    const img_list = ref({
      correct: [{
        img: 'Group284',
        is_top: true,
        width: '18%;'
      }, {
        img: 'Group285',
        is_top: false,
        width: '18%;'
      }, {
        img: 'Group286',
        is_top: true,
        width: '18%;'
      }, {
        img: 'Group287',
        is_top: false,
        width: '18%;'
      }, {
        img: 'Group288',
        is_top: true,
        width: '18%;'
      }],
      plant: [{
        img: 'Group289',
        is_top: true,
        width: '23%;'
      }, {
        img: 'Group290',
        is_top: false,
        width: '23%;'
      }, {
        img: 'Group291',
        is_top: true,
        width: '23%;'
      }, {
        img: 'Group292',
        is_top: false,
        width: '23%;'
      }],
      treatment: [{
        img: 'Group293',
        is_top: true,
        width: '23%;'
      }, {
        img: 'Group294',
        is_top: false,
        width: '23%;'
      }, {
        img: 'Group295',
        is_top: true,
        width: '23%;'
      }, {
        img: 'Group296',
        is_top: false,
        width: '23%;'
      }],
      children: [{
        img: 'Group279',
        is_top: true,
        width: '18%;'
      }, {
        img: 'Group280',
        is_top: false,
        width: '18%;'
      }, {
        img: 'Group281',
        is_top: true,
        width: '18%;'
      }, {
        img: 'Group282',
        is_top: false,
        width: '18%;'
      }, {
        img: 'Group283',
        is_top: true,
        width: '18%;'
      }]
    });
    // 修复类型
    const xiufuList = ref(['嵌体修复', '全瓷美牙冠', '数字化全瓷贴面', '活动义齿']);
    // 专家解答
    const answer = ref([{
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }, {
      name: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答',
      nr: '这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答这是一条专家解答'
    }]);
    // 修复类型选中下标
    const xiufuList_index = ref(0);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=50';
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      getTabData();
      get_answer(0);
    });
    const tabClick = (index, id) => {
      SelectedTab.value = index;
      getTabData(id);
      get_answer(index);
    };
    const getTabData = id => {
      tabDatastate.value = false;
      let Taburl = api.baseUrl + api.webDecor + '?id=' + (id || tabList.value[SelectedTab.value].key);
      axios({
        url: Taburl,
        method: "get"
      }).then(res => {
        let {
          data
        } = res;
        TabData.value = JSON.parse(data.data.editConfig);
        tabDatastate.value = true;
      });
    };
    const show = () => {
      store.commit('serviceShow', true);
    };
    const touch = ref(999);
    const onMouseOver = e => {
      touch.value = e;
    };
    const onMoouseOut = () => {
      touch.value = 9999;
    };
    // 获取专家解读
    const get_answer = index => {
      let url = api.baseUrl + api.text + '?category=problem&category2=clinic&category3=' + tabList.value[index].category;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        answer.value = res.data.rows;
      });
    };
    // 查看图文详情
    const details = e => {
      router.push({
        name: 'details',
        params: {
          id: e
        }
      });
    };
    return {
      get_data,
      Client,
      tabList,
      SelectedTab,
      TabData,
      tabDatastate,
      tabClick,
      getTabData,
      img_list,
      show,
      touch,
      onMouseOver,
      onMoouseOut,
      xiufuList,
      xiufuList_index,
      answer,
      details
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "603e28d8": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;