
import { defineComponent, computed, watch } from 'vue'

const pageSizeComponent = defineComponent({
  name: 'g-pageSize',
  props: {
    pageinfo: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['change'],
  setup (props, { emit, attrs }) {
    const pageCount = computed(() => Number(props.pageinfo.pageCount))
    const pageInfo = computed(() => props.pageinfo)
    const currentPageFn = (currentPage) => {
      return (
        <>
          <span className="current-num fz" >
            {currentPage}
          </span>
        </>
      )
    }
    const pageCountFn = (pageCount) => {
      return (
        <>
          <span className="page-count fz">
            {pageCount}
          </span>
        </>
      )
    }
    const changePage = (type, page) => {
      if (type === 'prev') {
        // 置灰不可点
        if (+page === 1) {
          return
        }
        emit('change', page > 1 ? page - 1 : 1)
      } else {
        // 置灰不可点
        if (+page >= pageCount.value) {
          return
        }
        emit('change', page + 1)
        // if (page >= pageCount.value) {
        //   emit('change', pageCount.value)
        // } else {
        //   emit('change', page + 1)
        // }
      }
    }
    watch(pageInfo, () => {
      if (props.pageinfo.currentPage > props.pageinfo.pageCount) {
        emit('change', props.pageinfo.pageCount)
      }
    })
    return () => {
      return (
        <>
          <div className={`g-page-size-wrap ${attrs.class}`}>
            <span className="page-control prev-page" onClick={changePage.bind(this, 'prev', props.pageinfo.currentPage)}>
              <i className={`iconfont-g icon-back  ${Number(props.pageinfo.currentPage) === 1 ? 'not-allowed' : ''} `}></i>
            </span>
            <span class="page-num fz">
              {currentPageFn(Number(props.pageinfo.currentPage) > pageCount.value ? pageCount.value : Number(props.pageinfo.currentPage))}
              /
              {pageCountFn(Number(props.pageinfo.pageCount))}
            </span>
            <span className="page-control next-page" onClick={changePage.bind(this, 'next', props.pageinfo.currentPage)}>
              <i className={`iconfont-g icon-back rotate ${props.pageinfo.currentPage === pageCount.value ? 'not-allowed' : ''}`}></i>
            </span>
          </div>
        </>
      )
    }
  }
})
export default pageSizeComponent
