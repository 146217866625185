import { createStore } from 'vuex'

export default createStore({
  state: {
    // 客户端详情 0：pc 1：h5
    Client: 0,
    // 预约客服弹窗
    show_service: false,
    // 是否处于会员端
    is_member: false,
    // 是否登录页
    is_login: false,
    // 记录当前路由
    routerKey: '',
    // 口腔科普下标
    tabIndex:0
  },
  getters: {
  },
  mutations: {
    // 修改客户端标识
    modifyClient(state, val) {
      state.Client = val
    },
    // 显隐弹窗
    serviceShow(state, val) {
      state.show_service = val
    },
    // 修改是否为会员端
    serviceMember(state, val) {
      state.is_member = val
    },
    // 修改是否登录页
    serviceLogin(state, val) {
      state.is_login = val
    },
    // 修改当前路由
    servicerouterKey(state, val) {
      state.routerKey = val
    },
    // 修改下标
    serviceTabIndex(state,val) {
      state.tabIndex = val
    }
  },
  actions: {
  },
  modules: {
  }
})
