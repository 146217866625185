import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.item.base.isFullScreen ? '' : 'noFullScreen'),
    style: _normalizeStyle([{
      cursor: _ctx.pointerChange ? 'pointer' : 'default'
    }]),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.getMouseXY($event, _ctx.item)),
    onMousemove: _cache[1] || (_cache[1] = $event => _ctx.mouseSite($event, _ctx.item))
  }, [_createElementVNode("img", {
    src: _ctx.item.base.bgimg,
    alt: "",
    style: {
      "width": "100%"
    }
  }, null, 8, _hoisted_1)], 38);
}