export let BASEURL = 'https://www.mimodental.com/prod-api' //线上
// export let BASEURL = 'http://192.168.2.124:9090'
//后台接口
export const api = {
    // 自动化生成模块使用
    baseUrl: BASEURL,
    // 获取网站装修数据
    webDecor: '/decor/webDecor/get',
    // 荣誉资质/合作品牌
    showPictures: '/protal/home/showPictures',
    // 米檬大记事
    notepad: '/protal/about/listNotepad',
    // 口腔科普/新闻资讯/口腔知识 列表
    text: '/decor/text/list',
    // 文章详情
    details: '/decor/text/',
    // 会员登录
    login: '/protal/user/login',
    // 会员注册
    register: '/protal/user/register',
    // 重置密码
    resetPassword: '/protal/user/resetPassword',
    // 公益活动列表
    benefit: '/activity/benefit/webList',
    // 公益活动详情
    activity: '/activity/benefit/',
    // 公益活动详情
    activity_hy: '/activity/benefit/getInfo',
    // 获取验证码
    sms: '/send/sms',
    // 上传视频
    uploadVideo: '/common/uploadVideo',
    // 上传图片
    uploadIcon: '/common/uploadIcon',
    // 获取打卡活动信息
    contactData: '/operation/roomClockIn/contactData',
    // 打卡
    contactClockIn: '/operation/roomClockIn/contactClockIn',
    // 获取优惠卷
    cardRecords: '/activity/cardRecords/list',
    // 优惠卷详情
    cards_xq: '/activity/cardRecords/',
    // 领劵中心列表
    cards: '/activity/cards/list',
    // 领取优惠卷
    getCoupon: '/activity/coupon/getCoupon',
    // 分享
    case: '/sharing/case/list',
    // 分享详情
    selectById: '/sharing/case/selectById/',
    // 上传训练成果
    insertRecord: '/activity/record/insertRecord',
    // 搜索
    search: '/protal/home/search',
    // 口腔专家
    specialist: '/member/specialist/list',
    // 科室列表
    section: '/protal/about/sectionDict',
    // 会员信息
    get_user_info: '/protal/user/',
    // 文章收藏/取消收藏
    collect: '/decor/text/collect',
    // 用户收藏文章列表
    userCollects: '/decor/text/userCollects',
    // 获取用户公益活动上传记录
    getUserRecord: '/activity/record/getUserRecord',
    // 用户删除上传记录
    deleteBenefitActivityRecord: '/activity/record/deleteBenefitActivityRecord/',
    // 调查问卷
    questionnaire: '/protal/user/questionnaire',
    // 诊疗记录
    clinic: '/protal/user/clinicList',
    // 我的卡卷
    userCard: '/member/user/userCard/',
    // 卡卷详情
    getCard: '/member/card/getCard/',
    // 优惠券样式设计
    designs: '/activity/cards/designs',
    // 会员端首页轮播图
    osMemberHome: '/decor/osMemberHome/list',
    // code换取id
    wxcode : '/protal/user/wxCode',
    // 绑定微信
    bindWxid: '/protal/user/bindWxid',
    // 字典
    getDict:'/protal/about/getDict'
}
