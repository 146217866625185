import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
const __default__ = {
  name: 'about_m',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace
  },
  setup() {
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const showPicturesList = ref([]);
    const event = ref([]);
    const loginShow = ref(false);
    const showState = ref(0); //0为注册，1为登录
    const userData = ref(false);
    // 当前选择的时间-年份
    const select_year = ref(0);
    // 当前选择的事件
    const select_event = ref(0);
    // 滚动辅助 判断是向上还是向下
    const auxiliary = ref(0);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=69';
    // 荣誉资质
    let showPictures = api.baseUrl + api.showPictures + '?type=honor';
    // 装修数据
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      // console.log(data);
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      console.log(get_data.value);
    });
    // 荣誉资质
    axios({
      url: showPictures,
      method: "get"
    }).then(res => {
      let {
        data
      } = res.data;
      console.log(data);
      showPicturesList.value = data;
    });
    // 米檬大记事
    axios({
      url: api.baseUrl + api.notepad,
      method: "get"
    }).then(res => {
      let {
        data
      } = res.data;
      // 排序
      for (let i = 0; i < data.length - 1; i++) {
        for (let h = 0; h < data.length - 1; h++) {
          if (data[h].time > data[h + 1].time) {
            [data[h], data[h + 1]] = [data[h + 1], data[h]];
          }
        }
      }
      console.log(data);
      event.value = data;
    });
    const go = name => {
      router.push({
        name
      });
    };
    // 事件点击切换
    const event_click = (index, ind) => {
      console.log(index);
      console.log(ind);
      select_year.value = index;
      select_event.value = ind;
    };
    const show = () => {
      store.commit('serviceShow', true);
    };
    //了解更多
    const learnMore = () => {
      loginShow.value = true;
    };
    const modifyShowState = e => {
      loginShow.value = false;
    };
    const getUserData = () => {
      console.log(localStorage.getItem('userData'));
      if (localStorage.getItem('userData')) {
        userData.value = JSON.parse(localStorage.getItem('userData'));
      }
    };
    const gotoWelfareD = () => {
      router.push({
        name: 'welfare_details',
        params: {
          id: 2
        }
      });
    };
    const gotoWelfareDB = () => {
      router.push({
        name: 'welfare_details_brief',
        params: {
          id: 3
        }
      });
    };
    const openFile = type => {};
    return {
      get_data,
      Client,
      showPicturesList,
      event,
      select_year,
      select_event,
      // scrollHandler
      event_click,
      show,
      modifyShowState,
      getUserData,
      userData,
      learnMore,
      loginShow,
      showState,
      gotoWelfareD,
      gotoWelfareDB,
      openFile,
      go
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "82299504": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;