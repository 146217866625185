import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
export default {
  name: 'member_welfare',
  setup() {
    const get_data = ref({});
    const pic = ref("");
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const show = () => {
      store.commit('serviceShow', true);
    };
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // console.log(route.params);
    let url = api.baseUrl + api.activity_hy + '?id=2&userId=' + user_info.value.id;
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      let tmpList = [];
      pic.value = data.data.materials[0].url;
      for (let index in data.data.materials) {
        // console.log(data.data.materials[index].type.indexOf("video")==0);
        if (data.data.materials[index].type.indexOf("video") == 0) {
          tmpList.push(data.data.materials[index]);
        }
      }
      console.log(tmpList);
      // data.data.materials = tmpList;
      get_data.value = data.data;
      // get_data.value.materials = tmpList;
      let length = get_data.value.materials.length;
      get_data.value.materials.map(item => {
        if (item.hide) {
          length -= 1;
        }
      });
      document.getElementsByClassName('course_list')[0].style.height = Math.round((length - 1) / 2) * 65 + 'px';
      let count = tmpList.length;
      let completeCount = 0;
      for (let index in tmpList) {
        if (tmpList[index].statusStr == "已完成") {
          completeCount += 1;
        }
      }
      if (completeCount != 0) {
        // console.log(/completeCount);
        get_data.value.status = parseFloat(completeCount / count * 100).toFixed(0);
      }
    });
    const push = item => {
      if (item.lock) {
        show();
        return;
      }
      if (item.statusStr == '已完成') {
        router.push({
          path: '/member_upload_push',
          query: {
            ...item
          }
        });
      }
      router.push({
        path: '/member_upload_push',
        query: {
          ...item
        }
      });
    };
    return {
      get_data,
      Client,
      push,
      pic,
      show
    };
  }
};