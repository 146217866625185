
import {
  defineComponent,
  Transition,
  ref
} from 'vue'
import Overlay from '../overlay'
import { extend, truthProp, callInterceptor } from '../utils'
import Button from '../button'
const popupProps = {
  show: Boolean,
  zIndex: {
    type: [Number, String],
    default: 100
  },
  Options: {
    type: Object,
    default: () => {
      return {
        className: '',
        showTitle: false,
        showCancelButton: false,
        showConfirmButton: false,
        showTitleText: '',
        confirmButtonText: '',
        cancelButtonText: '',
        message: ['messageBox'],
        confirmCallback: function () {},
        cancelCallback: function () {}
      }
    }
  },
  overlay: truthProp,
  beforeClose: Function,
  overlayClass: String,
  transitionAppear: Boolean,
  closeOnClickOverlay: truthProp
}

export default defineComponent({
  props: extend({}, popupProps, {
    closeable: Boolean,
    transition: String,
    closeOnPopState: Boolean
  }),
  emits: [
    'close',
    'callback',
    'update:show',
    'click-overlay',
    'click-close-icon'
  ],
  name: 'g-messsageBox',
  components: {
    [Button.name]: Button
  },
  setup (props, { emit, attrs, slots }) {
    const messagebox = ref(null)
    const renderMessageBox = () => {
      return (
        <div ref={messagebox} class={'g-message-box ' + props.Options.className}
          v-show={props.show}>
          {titleHeader()}
          <div class="g-message-tips">
            {
              typeof props.Options.message === 'string'
                ? props.Options.message
                : props.Options.message.map((str) => {
                  return <p>{str}</p>
                })
            }
          </div>
          <div class="g-message-footer">
            {cancelBtn()}
            {confirmBtn()}
          </div>
        </div>
      )
    }
    const titleHeader = () => {
      if (props.Options.showTitle) {
        return (
          <div class="g-message-header">
            <h3 class="title">{props.Options.showTitleText}</h3>
          </div>
        )
      }
    }
    const cancelBtn = () => {
      if (props.Options.showCancelButton) {
        return (
          <g-button
            className="g-com-btn"
            type={'info'}
            size={'small'}
            onClick={closeConfirm.bind(this, 'cancel')}>
            {props.Options.cancelButtonText}
          </g-button>
        )
      }
    }
    const confirmBtn = () => {
      if (props.Options.showConfirmButton) {
        return (
          <g-button
            className="g-com-btn"
            type={'primary'}
            size={'small'}
            onClick={closeConfirm.bind(this, 'confirm')}>
            {props.Options.confirmButtonText}
          </g-button>
        )
      }
    }
    const close = () => {
      callInterceptor({
        interceptor: props.beforeClose,
        done () {
          emit('close')
          emit('update:show', false)
        }
      })
    }
    const closeConfirm = (type) => {
      if (type === 'confirm') {
        props.Options.confirmCallback && props.Options.confirmCallback()
      } else {
        props.Options.cancelCallback && props.Options.cancelCallback()
      }

      emit('callback', type)
      emit('close')
      emit('update:show', false)
    }
    const onClickOverlay = event => {
      props.Options.cancelCallback && props.Options.cancelCallback()
      emit('click-overlay', event)
      if (props.closeOnClickOverlay) {
        close()
      }
    }
    const renderOverlay = () => {
      if (props.overlay) {
        return (
          <Overlay
            v-slots={{ default: slots['overlay-content'] }}
            show={props.show}
            class={props.overlayClass}
            zIndex={props.zIndex}
            duration={props.duration}
            onClick={onClickOverlay}
          />
        )
      }
    }
    const renderTransition = () => {
      return (
        <Transition>
          {renderMessageBox()}
        </Transition>
      )
    }
    return () => {
      return (
        <div >
          {renderOverlay()}
          { renderTransition() }
        </div>
      )
    }
  }
})
