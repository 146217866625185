import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  viewBox: "0,0,16,16"
};
const _hoisted_2 = ["fill"];
const _hoisted_3 = {
  viewBox: "0,0,16,16"
};
const _hoisted_4 = ["fill"];
const _hoisted_5 = {
  viewBox: "0,0,16,16"
};
const _hoisted_6 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading-point", {
      bg: _ctx.showBg
    }])
  }, [(_openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("circle", {
    cx: "8",
    cy: "8",
    r: "8",
    fill: _ctx.fill
  }, null, 8, _hoisted_2)])), (_openBlock(), _createElementBlock("svg", _hoisted_3, [_createElementVNode("circle", {
    cx: "8",
    cy: "8",
    r: "8",
    fill: _ctx.fill
  }, null, 8, _hoisted_4)])), (_openBlock(), _createElementBlock("svg", _hoisted_5, [_createElementVNode("circle", {
    cx: "8",
    cy: "8",
    r: "8",
    fill: _ctx.fill
  }, null, 8, _hoisted_6)]))], 2);
}