import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
export default {
  name: 'member_new',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    const currentPage = ref(1); //当前页数
    const pageSize = ref(10); //分页大小
    const total = ref(0); //数据总量

    const scrollRef = ref(); //名字需要跟上面ref绑定的一样
    // 新闻列表
    let newLIst = api.baseUrl + api.text;
    onMounted(() => {
      axios({
        url: newLIst,
        method: "get",
        params: {
          category: 'information',
          pageNum: currentPage.value,
          pageSize: pageSize.value
        }
      }).then(res => {
        let {
          data
        } = res;
        console.log(data);
        upList.value = data.rows;
        total.value = data.total;
      });
    });
    const go = e => {
      router.push({
        name: 'member_details_m',
        params: {
          id: e
        }
      });
    };
    const fh = () => {
      router.go(-1);
    };
    const asd = () => {
      console.log('到底了');
      if (upList.value.length < total.value) {
        currentPage.value++;
        axios({
          url: newLIst,
          method: "get",
          params: {
            category: 'information',
            pageNum: currentPage.value,
            pageSize: pageSize.value
          }
        }).then(res => {
          let {
            data
          } = res;
          console.log(data);
          data.rows.map(item => {
            upList.value.push(item);
          });
        });
      } else {
        console.log('到底了');
      }
    };
    return {
      go,
      upList,
      fh,
      asd
    };
  }
};