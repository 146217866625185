import { defineComponent, reactive, toRefs, ref } from 'vue';
import { Toast, Dialog } from '@/components/ui';
import { request } from '@/utils';
export default defineComponent({
  name: 'subscription',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      item: data
    });
    const subscribesContent = ref(null);
    const subscribesBtn = async () => {
      const params = {
        email: subscribesContent.value
      };
      const res = await request.get('/subscribe/subscribe', params);
      if (res.code === 200) {
        Toast(res.message);
      }
      // request.get('/subscribe/subscribe',params)
    };

    return {
      ...toRefs(state),
      subscribesBtn,
      subscribesContent
    };
  }
});