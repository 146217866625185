"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openLoading = exports.closeLoading = exports.renderComponent = void 0;
var vue_1 = require("vue");
var PageLoading_vue_1 = require("@/components/common/PageLoading.vue");
var renderComponent = function (commpent, props, instance) {
    if (props === void 0) { props = {}; }
    var vm = (0, vue_1.createVNode)(commpent, props);
    var container = document.createElement('div');
    var el = document.body;
    if (instance) {
        var ctx = instance.appContext;
        el = instance.vnode.el;
        vm.appContext = ctx;
    }
    (0, vue_1.render)(vm, container);
    if (container.firstElementChild) {
        el.appendChild(container.firstElementChild);
    }
    else {
        el.appendChild(container);
    }
    return vm;
};
exports.renderComponent = renderComponent;
var pageLoadVm = null; // 记录全局loading虚拟节点
// 关闭全局loading
var closeLoading = function () {
    if (!pageLoadVm)
        return false;
    pageLoadVm.num--;
    if (pageLoadVm.num > 0)
        return false;
    pageLoadVm.component.props.show = false;
};
exports.closeLoading = closeLoading;
// 全局开启loading
var openLoading = function () {
    if (pageLoadVm) {
        pageLoadVm.num++;
        pageLoadVm.component.props.show = true;
        return false; // 只创建一次
    }
    pageLoadVm = (0, exports.renderComponent)(PageLoading_vue_1.default, {}, null);
    pageLoadVm.num = 1;
    pageLoadVm.component.props.show = true;
};
exports.openLoading = openLoading;
/* let renderedLoginPopup: any

export const triggerLogin = () => {
  return new Promise((resolve, reject) => {
    if (!renderedLoginPopup) {
      renderedLoginPopup = renderComponent(LoginPopup, {}, null)

      renderedLoginPopup.component.emitsOptions['update:show'] = (val: boolean): boolean => {
        renderedLoginPopup.component.props.show = val
        if (!val) {
          resolve(val)
        }
        // 需要返回一个true值避免emit的validator验证
        // [Vue warn]: Invalid event arguments: event validation failed for event "update:show".
        return true
      }
    }
    renderedLoginPopup.component.props.show = true
  })
} */
