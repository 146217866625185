import { defineComponent, ref } from 'vue'
import { throttle } from '../utils'
import { useEventListener } from '../composables/useEventListener'
import { customBuriedPoint } from '@/utils/third'

const name = 'g-back-to-top'

export default defineComponent({
  name,
  setup () {
    const show = ref(false)
    useEventListener('scroll', throttle(() => {
      show.value = (document.body.scrollTop || document.documentElement.scrollTop) > (window.innerHeight * 1.5)
    }, 200))
    const backToTop = () => {
      customBuriedPoint.goToTop()
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
    return () => (
      <div class={ show.value ? 'show g-back-to-top' : 'g-back-to-top' } onClick={ backToTop }>
        <i class="iconfont-g icon-drop-down back-to-top flex-center-center" />
      </div>
    )
  }
})
