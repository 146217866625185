import { ref, onMounted } from 'vue'
import { inBrowser } from '@/utils/tools'

const overflowScrollReg = /scroll|auto/i
const defaultRoot = inBrowser ? window : undefined

function isElement (node) {
  const ELEMENT_NODE_TYPE = 1

  return (
    node.tagName !== 'HTML' &&
    node.tagName !== 'BODY' &&
    node.nodeType === ELEMENT_NODE_TYPE
  )
}

export function getScrollParent (el, root = defaultRoot) {
  let node = el

  while (node && node !== root && isElement(node)) {
    const { overflowY } = window.getComputedStyle(node)

    if (overflowScrollReg.test(overflowY)) {
      return node
    }
    node = node.parentNode
  }
  return root
}

export function useScrollParent (el, root = defaultRoot) {
  const scrollParent = ref()

  onMounted(() => {
    if (el.vlaue) {
      scrollParent.value = getScrollParent(el.value, root)
    }
  })

  return scrollParent
}
