import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-555c456c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "upload_body"
};
const _hoisted_2 = {
  class: "upload_body_nr"
};
const _hoisted_3 = {
  class: "expert-box"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "upload_list"
};
const _hoisted_6 = {
  class: "photo-box"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "zj_top"
};
const _hoisted_9 = {
  style: {
    "text-align": "center"
  }
};
const _hoisted_10 = {
  class: "zj_bot"
};
const _hoisted_11 = {
  style: {
    "white-space": "pre-wrap"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tjList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "expert",
      onClick: $event => $setup.tjListClick(index)
    }, [_createElementVNode("div", {
      class: _normalizeClass(["item", $setup.tjList_index == index ? 'active' : ''])
    }, _toDisplayString(item.dictLabel), 3)], 8, _hoisted_4);
  }), 256))]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.upList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
      src: item.imgUrl,
      alt: ""
    }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("h2", null, _toDisplayString(item.name), 1), _createElementVNode("span", _hoisted_9, _toDisplayString(item.label), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(item.intro), 1)])]);
  }), 128))])])]);
}