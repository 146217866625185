import {
  ref,
  reactive,
  watch,
  computed,
  onMounted,
  onActivated,
  onDeactivated,
  onBeforeUnmount,
  defineComponent,
  nextTick
} from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import {
  truthProp,
  isHidden,
  clamp,
  doubleRaf,
  useExpose
} from '../utils'
import {
  useWindowSize,
  useChildren,
  usePageVisibility,
  onPopupReopen
} from '../composables'

const name = 'g-swipe'

const props = {
  loop: Boolean,
  width: [Number, String],
  height: [Number, String],
  spacing: {
    type: [Number, String],
    default: 0
  },
  hideArrow: Boolean,
  step: [Number, String],
  moveStep: [Number, String],
  lazyRender: Boolean,
  initialSwipe: {
    type: [Number, String],
    default: 0
  },
  stopPropagation: truthProp,
  autoplay: {
    type: [Number, String],
    default: 0
  },
  duration: {
    type: [Number, String],
    default: 500
  },
  direction: {
    type: String,
    default: 'x'
  },
  button: {
    type: String,
    default: 'round'
  }
}

export const SWIPE_KEY = Symbol(name)

export default defineComponent({
  name,

  props,

  emits: ['change', 'click-prev', 'click-next'],

  setup (props, { emit, slots }) {
    const root = ref()
    const spacing = ref(+props.spacing)
    const step = ref(+props.step || 1)
    const moveStep = ref(+props.moveStep || 1)
    const swipeItemWidth = ref(0)
    const state = reactive({
      rect: null,
      width: 0,
      height: 0,
      offset: 0,
      active: 0,
      swiping: false
    })

    const windowSize = useWindowSize()

    const { children, linkChildren } = useChildren(SWIPE_KEY)

    const count = computed(() => children.length)

    const isVertical = props.direction !== 'x'

    const size = computed(() => state.width)

    const minOffset = computed(() => {
      if (state.rect) {
        if (isVertical) {
          return state.rect.height - (props.height * count.value + (count.value - 1) * spacing.value)
        }
        return state.rect.width - (size.value * count.value + (count.value - 1) * spacing.value)
      }
      return 0
    })

    const maxCount = computed(() => {
      if (isVertical) {
        return Math.ceil(Math.abs(minOffset.value) / (props.height + spacing.value))
      }

      return Math.ceil(Math.abs(minOffset.value) / (size.value + spacing.value))
    })

    const trackSize = computed(() => {
      if (isVertical) {
        return count.value * props.height + (count.value - 1) * spacing.value
      } else {
        return count.value * size.value + (count.value - 1) * spacing.value
      }
    })

    const getTargetActive = (pace) => {
      const { active } = state
      if (pace) {
        if (props.loop) {
          return clamp(active + pace, -1, count.value)
        }
        return clamp(active + pace, 0, maxCount.value)
      }
      return active % count.value
    }

    const getTargetOffset = (targetActive, offset) => {
      let currentPosition = isVertical
        ? targetActive * props.height + targetActive * spacing.value
        : targetActive * size.value + targetActive * spacing.value
      if (!props.loop) {
        currentPosition = Math.min(currentPosition, -minOffset.value)
      }

      let targetOffset = offset - currentPosition
      if (!props.loop) {
        targetOffset = clamp(targetOffset, minOffset.value, 0)
      }

      return targetOffset
    }

    const activeIndicator = computed(
      () => (state.active + count.value) % count.value
    )

    const trackStyle = computed(() => {
      const style = {
        transitionDuration: `${state.swiping ? 0 : props.duration}ms`,
        transform: `translate${props.direction === 'x' ? 'X' : 'Y'}(${state.offset}px)`,
        width: `${isVertical ? size.value : trackSize.value}px`,
        height: isVertical ? `${trackSize.value}px` : props.height ? `${props.height}px` : ''
      }

      return style
    })

    const move = ({ pace = 0, offset = 0, emitChange }) => {
      if (count.value <= 1) {
        return
      }

      const { active } = state
      const targetActive = getTargetActive(pace)
      const targetOffset = getTargetOffset(targetActive, offset)

      if (props.loop) {
        if (children[0] && targetOffset !== minOffset.value) {
          const outRightBound = targetOffset < minOffset.value
          children[0].setOffset(outRightBound ? trackSize.value : 0)
        }

        if (children[count.value - 1] && targetOffset !== 0) {
          const outLeftBound = targetOffset > 0
          children[count.value - 1].setOffset(outLeftBound ? -trackSize.value : 0)
        }
      }
      state.active = targetActive
      state.offset = targetOffset

      if (targetActive > count.value) {
        correctPosition()
      }

      if (emitChange && targetActive !== active) {
        emit('change', activeIndicator.value)
      }
    }

    const correctPosition = () => {
      state.swiping = true

      if (state.active <= -1) {
        move({ pace: count.value })
      } else if (state.active >= count.value) {
        move({ pace: -count.value })
      }
    }
    const prevAvaliable = computed(() => props.loop || activeIndicator.value > 0)
    const nextAvaliable = computed(() => props.loop || activeIndicator.value < maxCount.value)
    const prev = () => {
      correctPosition()

      doubleRaf(() => {
        state.swiping = false
        move({
          pace: -moveStep.value || -step.value,
          emitChange: true
        })
      })
    }

    const next = () => {
      correctPosition()
      doubleRaf(() => {
        state.swiping = false
        move({
          pace: moveStep.value || step.value,
          emitChange: true
        })
      })
      return activeIndicator.value < maxCount.value
    }

    let autoplayTimer

    const stopAutoplay = () => clearTimeout(autoplayTimer)

    const autoplay = () => {
      stopAutoplay()
      if (props.autoplay > 0 && count.value > 1) {
        autoplayTimer = setTimeout(() => {
          next()
          autoplay()
        }, +props.autoplay)
      }
    }

    const caclSwipeItemWidth = () => {
      const $swipeDom = root.value
      if ($swipeDom) {
        swipeItemWidth.value =
            ($swipeDom.clientWidth - props.spacing * (props.step - 1)) /
            props.step
      }
    }

    const initialize = (active = +props.initialSwipe) => {
      if (!root.value) return

      if (!isHidden(root.value)) {
        const rect = {
          width: root.value.offsetWidth,
          height: root.value.offsetHeight
        }
        state.rect = rect
        nextTick(() => {
          !props.width && caclSwipeItemWidth()
          state.width = +(swipeItemWidth.value ?? rect.width)
          state.height = +(props.height ?? rect.height)
        })
      }

      if (count.value) {
        active = Math.min(count.value - 1, active)
      }

      state.active = active
      state.swiping = true
      state.offset = getTargetOffset(active)
      children.forEach((swipe, index) => {
        swipe.setOffset(index * spacing.value)
      })

      autoplay()
    }

    const resize = () => initialize(state.active)

    const swipeTo = (index, options = {}) => {
      correctPosition()

      doubleRaf(() => {
        let targetIndex
        if (props.loop && index === count.value) {
          targetIndex = state.active === 0 ? 0 : index
        } else {
          targetIndex = index % count.value
        }

        if (options.immediate) {
          doubleRaf(() => {
            state.swiping = false
          })
        } else {
          state.swiping = false
        }

        move({
          pace: targetIndex - state.active,
          emitChange: true
        })
      })
    }

    const onClickPrev = () => {
      if (!prevAvaliable.value) return
      emit('click-prev')
      prev()
    }

    const onClickNext = () => {
      if (!nextAvaliable.value) return
      emit('click-next')
      next()
    }
    const renderPrev = () => {
      return (
        <span
          class={[
            'swipe-btn swipe-prev-btn',
            {
              disable: !prevAvaliable.value
            },
            {
              square: props.button === 'square'
            }
          ]}
          onClick={onClickPrev}
        >
          <i class="iconfont-g icon-right-btn" />
        </span>
      )
    }

    const renderNext = () => {
      return (
        <span
          class={[
            'swipe-btn swipe-next-btn',
            {
              disable: !nextAvaliable.value
            },
            {
              square: props.button === 'square'
            }
          ]}
          onClick={onClickNext}
        >
          <i class="iconfont-g icon-right-btn" />
        </span>
      )
    }

    useExpose({
      prev,
      next,
      state,
      resize,
      swipeTo
    })

    linkChildren({
      size,
      props,
      count,
      activeIndicator,
      isVertical
    })

    watch(() => props.initialSwipe, value => initialize(+value))

    watch(count, () => {
      initialize(state.active)
    })

    watch(() => props.width, (val) => {
      initialize(state.active)
      swipeItemWidth.value = props.width
    })

    watch(() => props.autoplay, autoplay)
    watch([windowSize.width, windowSize.height], resize)

    watch(usePageVisibility(), visible => {
      if (visible === 'visible') {
        autoplay()
      } else {
        stopAutoplay()
      }
    })

    onMounted(() => {
      if (props.width) {
        swipeItemWidth.value = props.width
      }
      initialize()
    })
    onActivated(() => initialize(state.active))
    onPopupReopen(() => initialize(state.active))
    onDeactivated(stopAutoplay)
    onBeforeUnmount(stopAutoplay)
    // 切换路由时，初始化轮播位置
    onBeforeRouteUpdate((to, from, next) => {
      swipeTo(0)
      next()
    })

    return () => (
      <div ref={root} class={`g-swipe ${isVertical && 'g-swipe-vertical'}`}>
        { props.hideArrow ? null : renderPrev() }
        <div
          class='g-swipe-track'
          style={trackStyle.value}
        >
          { slots.default?.() }
        </div>
        { props.hideArrow ? null : renderNext() }
      </div>
    )
  }
})
