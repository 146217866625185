import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import router from '@/router';
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
export default defineComponent({
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const more = () => {
      router.push({
        name: 'why'
      });
      localStorage.setItem('key', 'why');
    };
    return {
      Client,
      more
    };
  }
});