import { withInstall } from '../utils'
import _Checkbox from './Checkbox'
import _GroupCheckbox from './Group-Checkbox'
import './index.scss'
export const Checkbox = withInstall(_Checkbox)
export const CroupCheckbox = withInstall(_GroupCheckbox)
export default {
  CroupCheckbox,
  Checkbox
}
