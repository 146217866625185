
import {
  defineComponent,
  ref
} from 'vue'
export default defineComponent({
  name: 'g-input-number',
  components: {
  },
  props: {
    className: {
      type: String, //  设置为 g-input-number-no-border 外框没有边框
      default: ''
    },
    isAuto: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: Infinity
    },
    width: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value', 'addAndsubtract'],
  setup (props, { emit }) {
    const inputNumber = ref('inputNumber')
    const max = ref(props.max)
    const min = ref(props.min)
    const decrementFn = (e) => {
      if (!props.disabled) {
        if (props.isAuto) {
          if (props.value > props.min) {
            emit('update:value', parseInt(props.value) - 1)
            return false
          }
        } else {
          emit('change', 'decrement')
        }
      }
      emit('change', e)
    }
    const incrementFn = (e) => {
      if (!props.disabled) {
        if (props.isAuto) {
          if (props.value < props.max) {
            emit('update:value', parseInt(props.value) + 1)
          }
        } else {
          emit('change', 'increment')
        }
      }
      emit('change', e)
    }
    const changeValue = (e) => {
      if (props.isAuto) {
        if (parseInt(e.target.value) > props.max) {
          e.target.value = max.value
          emit('update:value', max.value)
        } else if (parseInt(e.target.value) < props.min) {
          e.target.value = min.value
          emit('update:value', min.value)
        } else if (parseInt(e.target.value) > props.max && parseInt(e.target.value) > props.min) {
          emit('update:value', e.target.value)
        } else {
          emit('change', max.value)
        }
      } else {
        if (props.max && e.target.value > props.max) {
          emit('update:value', props.max)
          e.target.value = max.value
        } else {
          emit('update:value', e.target.value)
        }
        emit('change', 'input')
      }
    }
    const renderInput = () => {
      return (
        <div class={`g-input-number ${props.className}`}>
          <div class="g-input-number-content" style={`width:${props.width}`}>
            <div onClick={decrementFn} className={`decrement-btn ${props.value === props.min || props.disabled ? 'input-disabled' : ''}`}>
              <i class="iconfont-g icon-remove"></i>
            </div>
            <div onClick={incrementFn} className={`increment-btn ${props.value === props.max || props.disabled ? 'input-disabled' : ''}`}>
              <i class="iconfont-g icon-add"></i>
            </div>
            <div class="g-input-box">
              <input type="number" ref={inputNumber.value + 'inputNumber'} max={props.max} disabled={props.disabled} min={props.min} class="g-input" value={props.value} onBlur={changeValue.bind(this)}/>
            </div>
          </div>
        </div>
      )
    }
    return () => {
      return (
        <>
          {renderInput()}
        </>
      )
    }
  }
})
