import { defineComponent, ref, nextTick, onMounted, watch } from 'vue'
import { getChildren } from '@/utils'

export default defineComponent({
  name: 'g-textarea-auto',
  props: {
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [Number, String],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showLength: {
      type: Boolean,
      default: true
    },
    noRadius: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    autoPlaceholder: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: 128
    }
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const textareaRef = ref(null)
    const textVal = ref(props.value)
    watch(() => textVal.value, (val) => {
      emit('update:value', val)
    })
    watch(() => props.value, (val) => {
      textVal.value = val
    })
    const resizeBox = event => {
      event.target.style.height = 'auto'
      event.target.style.minHeight = props.minHeight + 'px'
      event.target.style.height = event.target.scrollHeight + 'px'
    }

    const renderTextarea = () => {
      return (
        <div
          ref={textareaRef} // 注意: jsx不用.value
          class={`textarea-auto-ui ${props.className}`}
        >
          <textarea
            v-model_trim={textVal.value}
            maxlength={props.maxlength}
            domPropsType="textarea"
            placeholder={!props.autoPlaceholder ? props.placeholder : undefined}
            class={`${props.noRadius && 'noRadius'} ${textVal.value && 'not-empty'}`}
            style={`min-height: ${props.minHeight}px`}
          />
          { props.autoPlaceholder && <span class="textarea-placeholder anime-placeholder">{props.placeholder}</span> }
          { props.showLength &&
            <span class="textarea-length">{ textVal.value.length } / { props.maxlength }</span>
          }
        </div>
      )
    }

    onMounted(() => {
      // 为组件里的textarea绑定事件
      const ele = textareaRef.value
      try {
        if (!ele) return
        const child = getChildren(ele)
        const areaEle = child && child[0]
        if (ele && areaEle && areaEle.type === 'textarea') {
          nextTick(() => {
            areaEle.setAttribute(
              'style',
              `height: ${areaEle.scrollHeight}px;min-height: ${props.minHeight}px;overflow-y:hidden;`
            )
          })
          areaEle.addEventListener('input', resizeBox)
        }
      } catch (err) {
        console.log(err)
      }
    })

    return () => {
      return (
        <>
          {renderTextarea()}
        </>
      )
    }
  }
})
