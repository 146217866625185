"use strict";
var __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
        t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reactiveAssign = exports.date2Time2String = exports.time2String = exports.outsideJumpReferrer = exports.jumpUrl = exports.pathJudge = exports.injectStyle = exports.getCartEventData = exports.loadAGSdk = exports.loadFbSdk = exports.execCopy = exports.parseURLToObj = exports.getChildren = exports.noScroll = exports.uniqueArr = exports.setMetaInfo = exports.validateEmail = exports.isPromise = exports.isEmptyObj = exports.isObject = exports.isFunction = exports.isString = exports.isDef = exports.lazyProdImg = exports.inBrowser = void 0;
var vue_1 = require("vue");
var js_cookie_1 = require("js-cookie");
var third_1 = require("@/utils/third");
exports.inBrowser = typeof window !== 'undefined';
exports.lazyProdImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAYAAABLLYUHAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAAVSURBVAiZY/z67ft/BihgYkACuDkAwPYD6djPGyUAAAAASUVORK5CYII=';
/**
 * @description 判断是否已定义变量
 * @param {any} val
 * @returns Boolean
 */
var isDef = function (val) {
  return val !== undefined && val !== null;
};
exports.isDef = isDef;
function isString(val) {
  return typeof val === 'string';
}
exports.isString = isString;
function isFunction(val) {
  return typeof val === 'function';
}
exports.isFunction = isFunction;
function isObject(val) {
  return (val !== null && Object.prototype.toString.call(val) === '[object Object]');
}
exports.isObject = isObject;
function isEmptyObj(val) {
  if (!val)
    return true;
  return JSON.stringify(val) === '{}';
}
exports.isEmptyObj = isEmptyObj;
function isPromise(val) {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch);
}
exports.isPromise = isPromise;
function validateEmail(val) {
  return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/.test(val);
}
exports.validateEmail = validateEmail;
var setMetaInfo = function (metaData) {
  if (!exports.inBrowser)
    return;
  var title = metaData.title, meta = metaData.meta;
  if (title) {
    document.title = title;
  }
  meta.map(function (_) {
    var node = _.name
      ? document.querySelector("meta[name='".concat(_.name, "']"))
      : document.head.querySelector("[property~='".concat(_.property, "']"));
    if (node) {
      node.content = _.content;
    }
    else {
      var fragment_1 = document.createDocumentFragment();
      var $meta_1 = document.createElement('meta');
      Object.keys(_).map(function (metaKey) {
        $meta_1.setAttribute(metaKey, _[metaKey]);
        fragment_1.appendChild($meta_1);
        return metaKey;
      });
      document.querySelector('head').appendChild(fragment_1);
    }
    return _;
  });
};
exports.setMetaInfo = setMetaInfo;
function uniqueArr(list, key) {
  if (!list)
    return list;
  if (key === undefined)
    return __spreadArray([], new Set(list), true);
  var obj = {};
  var map = {
    string: function (e) { return e[key]; },
    function: function (e) { return key(e); }
  };
  var fn = map[typeof key];
  obj = list.reduce(function (init, current, index) {
    var _a, _b;
    var key = fn(current);
    var firstKey = '';
    if (index === 1) {
      firstKey = fn(init);
    }
    var newObj = __assign(__assign({}, (index > 1 ? init : (_a = {}, _a[firstKey] = init, _a))), (_b = {}, _b[key] = current, _b));
    return newObj;
  });
  return Object.values(obj);
}
exports.uniqueArr = uniqueArr;
/**
 * noScroll.set() 禁止全局(body)滚动
 * noScroll.del() 解除禁止全局(body)滚动
 */
function noScroll() {
  var scroll = {
    classKey: 'g-no-scroll',
    set: function () {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
      var $body = document.querySelector('body');
      setTimeout(function () {
        $body.classList.add(scroll.classKey);
        $body.style.top = "-".concat(scrollTop, "px");
        $body.setAttribute(scroll.classKey, scrollTop);
      }, 100);
    },
    del: function () {
      var $body = document.querySelector('body');
      var scrollTop = $body.getAttribute(scroll.classKey);
      setTimeout(function () {
        $body.classList.remove(scroll.classKey);
        $body.style.top = 'auto';
        $body.setAttribute(scroll.classKey, 'auto');
        if (scrollTop !== 'auto') {
          scrollTo(0, Number(scrollTop));
        }
      }, 100);
    }
  };
  return scroll;
}
exports.noScroll = noScroll;
// 获取元素子节点
var getChildren = function (element) {
  var children = element.childNodes;
  var arr = [];
  for (var i = 0; i < children.length; i++) {
    if (children[i].nodeType === 1) {
      arr.push(children[i]);
    }
  }
  return arr;
};
exports.getChildren = getChildren;
/**
 * 把URL查询参数解析为json
 * @param {String} url
 * @returns
 */
function parseURLToObj(url) {
  var json = {};
  var paramArr = url.slice(url.indexOf('?') + 1).split('&');
  paramArr.forEach(function (param) {
    var arr = param.split('=');
    var key = arr[0];
    json[key] = arr[1];
  });
  return json;
}
exports.parseURLToObj = parseURLToObj;
/**
 * @description copy text
 * @param {String} text
 * @param {Dom} dom
 * @returns Promise
 */
function execCopy(text, dom) {
  return new Promise(function (resolve, reject) {
    if (!document.queryCommandSupported('copy')) {
      reject && reject(new Error());
      return;
    }
    // 解决聚焦触发输入法的问题
    var container = dom || document.body;
    var input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', text);
    container.appendChild(input);
    input.select();
    input.setSelectionRange(0, 9999);
    try {
      var successful = document.execCommand('copy');
      if (successful) {
        resolve && resolve('');
      }
      else {
        reject && reject(new Error());
      }
    }
    catch (err) {
      reject && reject(new Error());
    }
    container.removeChild(input);
  });
}
exports.execCopy = execCopy;
// 加载 facebook sdk
function loadFbSdk() {
  return new Promise(function (resolve, reject) {
    if (window.FB) {
      resolve(true);
    }
    else {
      var el = document.getElementById('srcipt-fbsdk');
      el && el.parentNode.removeChild(el);
      el = document.createElement('script');
      el.setAttribute('src', '//connect.facebook.net/en_US/sdk.js');
      el.setAttribute('id', 'srcipt-fbsdk');
      el.setAttribute('async', 'true');
      el.onload = function () {
        window.FB.init({
          appId: '3469280620062657',
          xfbml: true,
          cookie: true,
          version: 'v12.0'
        });
        resolve(true);
      };
      el.onerror = function () {
        reject(new Error('Can not fetch facebook SDK'));
      };
      document.body.appendChild(el);
    }
  });
}
exports.loadFbSdk = loadFbSdk;
// 加载 google 登录 sdk
function loadAGSdk() {
  return new Promise(function (resolve, reject) {
    if (window.google) {
      resolve(true);
    }
    else {
      var el = document.getElementById('srcipt-gsdk');
      el && el.parentNode.removeChild(el);
      el = document.createElement('script');
      el.setAttribute('src', '//accounts.google.com/gsi/client');
      el.setAttribute('id', 'srcipt-gsdk');
      el.setAttribute('async', 'true');
      el.onload = function () {
        resolve(true);
      };
      el.onerror = function () {
        reject(new Error('Can not fetch Google SDK'));
      };
      document.body.appendChild(el);
    }
  });
}
exports.loadAGSdk = loadAGSdk;
// 处理购物车结算页等产品列表数据
function getCartEventData(list) {
  var fbItems = [];
  var gaItems = [];
  var faItems = [];
  var contentIds = [];
  list.length && list.forEach(function (_) {
    contentIds.push(String(_.productsId));
    fbItems.push({
      id: String(_.productsId),
      quantity: _.qty,
      content_name: _.productInfo.productsName
    });
    // fa字段
    faItems.push({
      pid: _.productsId,
      sku: _.sku,
      name: _.productInfo.productsName,
      price: _.productInfo.finalPrice,
      quantity: _.qty,
      variant: (_.attributes &&
        _.attributes[0] &&
        _.attributes[0].valuesName) ||
        ''
    });
    // ga 字段
    var gaObj = {
      id: _.productsId,
      name: _.productInfo.productsName,
      price: _.productInfo.finalPrice,
      quantity: _.qty,
      variant: (_.attributes &&
        _.attributes[0] &&
        _.attributes[0].valuesName) ||
        ''
    };
    gaItems.push(gaObj);
  });
  return { fbItems: fbItems, gaItems: gaItems, faItems: faItems, contentIds: contentIds };
}
exports.getCartEventData = getCartEventData;
/**
 * 异步加载 css 代码
 * @param {任何} src [必选] 资源 url
 * @param {任何} callback [可选] 回调触发
 * @param {任何} id [可选] 资源 id
 */
function injectStyle(src, callback, id) {
  var head = document.getElementsByTagName('link')[0];
  var style = document.createElement('link');
  style.rel = 'stylesheet';
  style.href = src;
  style.onload = function () {
    callback && callback(style);
  };
  id && style.setAttribute('id', id);
  head.insertAdjacentElement('beforebegin', style);
}
exports.injectStyle = injectStyle;
// 判断站内外链接
var pathJudge = function (url) {
  var siteName = 'goelia'.toLocaleLowerCase() || location.hostname.replace(/.*\.([^.]+)\..*/, '$1');
  var reg = new RegExp("(?:[^\\.]+\\.".concat(siteName, "\\.[^/]+|^)(/.*)"), 'i');
  var p = url.match(reg);
  if (p) {
    return p[1];
  }
  return false;
};
exports.pathJudge = pathJudge;
var jumpUrl = function (url, router, replaced) {
  var type = (0, exports.pathJudge)(url);
  if (type) {
    if (replaced) {
      router.replace(type);
    }
    else {
      router.push(type);
    }
  }
  else {
    window.location.href = url;
  }
};
exports.jumpUrl = jumpUrl;
function outsideJumpReferrer(val) {
  if (val) {
    js_cookie_1.default.set('referrer', val);
  }
  else {
    js_cookie_1.default.set('referrer', window.location.pathname + window.location.search);
  }
}
exports.outsideJumpReferrer = outsideJumpReferrer;
/**
 * 时间戳转当地字符串
 * @param time [必选] 时间戳
 * @param area 地区
 * @param options toLocaleString 配置 https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
function time2String(time, area, options) {
  if (area === void 0) { area = 'en-US'; }
  if (options === void 0) { options = {}; }
  var date = new Date(time);
  var defaultOption = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZoneName: 'short'
  };
  var data = JSON.stringify(options) === '{}' ? defaultOption : options;
  var zone = (0, third_1.getCurrentTimeZone)();
  return date.toLocaleString(zone, data);
}
exports.time2String = time2String;
/**
 * 时间转为时间戳 再转为当地字符串 利用time2String方法
 * @param time
 * @param area
 */
function date2Time2String(date, area, options) {
  if (area === void 0) { area = 'en-US'; }
  if (options === void 0) { options = {}; }
  var time = new Date(date).getTime();
  return time2String(time, area, options);
}
exports.date2Time2String = date2Time2String;
// 响应式合并，替代Object.assign
function reactiveAssign(target) {
  var sources = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    sources[_i - 1] = arguments[_i];
  }
  sources.forEach(function (source) {
    for (var key in source) {
      if (!Object.prototype.hasOwnProperty.call(target, key)) {
        var value = source[key];
        if (typeof value === 'object' && value !== null) {
          target[key] = (0, vue_1.isReactive)(value) ? value : (0, vue_1.reactive)(value);
          if (!(0, vue_1.isReactive)(value) && (0, vue_1.isReactive)(target[key])) {
            (0, vue_1.markRaw)(target[key]);
          }
        }
        else {
          target[key] = value;
        }
      }
      else {
        var oldValue = target[key];
        var value = source[key];
        if (typeof value === 'object' && value !== null) {
          target[key] = (0, vue_1.isReactive)(value) ? value : reactiveAssign(oldValue, value);
          if (!(0, vue_1.isReactive)(value) && (0, vue_1.isReactive)(target[key])) {
            (0, vue_1.markRaw)(target[key]);
          }
        }
        else {
          target[key] = value;
        }
      }
    }
  });
}
exports.reactiveAssign = reactiveAssign;
