import { defineComponent, reactive, toRefs, onBeforeMount, onMounted } from 'vue';
export default defineComponent({
  name: 'richText',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      item: data
    });
    onBeforeMount(() => {});
    onMounted(() => {});
    return {
      ...toRefs(state)
    };
  }
});