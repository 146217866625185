import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
export default {
  name: 'infoList',
  setup() {
    const route = useRoute();
    console.log(route.query);
    const list = ref(JSON.parse(route.query.list));
    console.log(list);
    // 查看图文详情
    const details = (e, index) => {
      if (index <= 2) {
        router.push({
          name: 'details',
          params: {
            id: e
          }
        });
      } else {
        // 判断有没有登录
        if (!localStorage.getItem('userData')) {
          show();
        } else {
          const user_info = ref(JSON.parse(localStorage.getItem('userData')));
          if (user_info.value.spent != '1') {
            show();
          } else {
            router.push({
              name: 'details',
              params: {
                id: e
              }
            });
          }
        }
      }
    };
    return {
      list,
      details
    };
  }
};