import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
// import QrcodeVue from 'qrcode.vue'
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
export default {
  name: 'member_preferential_xq_m',
  components: {
    VueQr
  },
  setup() {
    // 弹窗内容
    const dialog_data = ref({});
    const route = useRoute();
    // 使用优惠卷
    const show_dialog = () => {
      axios({
        url: api.baseUrl + api.cards_xq + route.params.id,
        method: 'get'
      }).then(res => {
        dialog_data.value = res.data.data;
      });
    };
    const fh = () => {
      router.go(-1);
    };
    show_dialog();
    return {
      show_dialog,
      fh,
      dialog_data
    };
  }
};