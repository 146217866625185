import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '@/router';
import { ElMessage } from 'element-plus';
import { showLoadingToast } from 'vant';
import 'vant/lib/index.css';
export default {
  name: 'login_m',
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const popupState = ref(route.params.id); //0注册 1短信登录 2密码登录 3重置密码 4已登录
    const showClear = ref(false);
    // 秒针
    const clock = ref(60);
    // 按钮文字
    const get_code_text = ref('获取验证码');
    //同意隐私协议
    const isAccept = ref(false);
    // 提交表单数据
    const formData = ref({
      captcha: '',
      password: '',
      phone: ''
    });
    //手机号清除
    const clearPhone = () => {
      formData.value.phone = '';
    };
    // 获取验证码
    const get_code = () => {
      if (!formData.value.phone) {
        // ElMessage({
        //   message: '请正确填写手机号码',
        //   type: 'error',
        //   customClass: "message-m",
        // })
        showLoadingToast({
          message: '请正确填写手机号码',
          type: 'fail',
          forbidClick: true,
          overlay: true,
          iconSize: '10rem',
          className: 'tc'
        });
        return;
      }
      if (clock.value != 60) {
        // ElMessage({
        //   message: '请' + clock.value + '秒后再试',
        //   type: 'error',
        //   customClass: "message-m",
        // })
        showLoadingToast({
          message: '请' + clock.value + '秒后再试',
          type: 'fail',
          forbidClick: true,
          overlay: true,
          iconSize: '10rem',
          className: 'tc'
        });
        return;
      }
      showLoadingToast({
        message: '验证码发送中...',
        forbidClick: true,
        overlay: true,
        iconSize: '10rem',
        duration: 0,
        className: 'tc'
      });
      let url = api.baseUrl + api.sms;
      axios({
        url,
        data: {
          mobile: formData.value.phone,
          type: popupState.value
        },
        method: 'post'
      }).then(res => {
        if (res.data.code == 200) {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'success',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'success',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
        } else {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'error',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'fail',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
          return;
        }
        clock.value -= 1;
        get_code_text.value = clock.value + 's';
        runClock();
      });
    };
    //获取验证码倒计时
    const runClock = () => {
      setTimeout(() => {
        if (clock.value > 0) {
          clock.value -= 1;
          get_code_text.value = clock.value + 's';
          runClock();
        } else {
          get_code_text.value = '获取验证码';
          clock.value = 60;
        }
      }, 1000);
    };
    //注册
    const register = () => {
      if (!isAccept.value) {
        // ElMessage({
        //   message: "请先同意米檬口腔服务条款和米檬口腔隐私政策",
        //   type: 'warning',
        //   customClass: "message-m",
        // });
        showLoadingToast({
          message: "请先同意米檬口腔服务条款和米檬口腔隐私政策",
          type: 'fail',
          forbidClick: true,
          overlay: true,
          iconSize: '10rem',
          className: 'tc'
        });
        return;
      }
      let {
        captcha,
        password,
        phone
      } = formData.value;
      let data = {
        captcha,
        password,
        phone
      };
      if (localStorage.getItem('source')) {
        data.source = localStorage.getItem('source');
      }
      axios({
        url: api.baseUrl + api.register,
        method: "post",
        data
      }).then(res => {
        // 提示效果
        if (res.data.code == 200) {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'success',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'success',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
          formData.value = {
            captcha: '',
            password: '',
            phone: ''
          };
          popupState.value = 2;
        } else {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'error',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'fail',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
        }
      });
    };
    //登录
    const login = () => {
      if (!isAccept.value) {
        // ElMessage({
        //   message: "请先同意米檬口腔服务条款和米檬口腔隐私政策",
        //   type: 'warning',
        //   customClass: "message-m",
        // });
        showLoadingToast({
          message: "请先同意米檬口腔服务条款和米檬口腔隐私政策",
          type: 'fail',
          forbidClick: true,
          overlay: true,
          iconSize: '10rem',
          className: 'tc'
        });
        return;
      }
      let {
        captcha,
        password,
        phone
      } = formData.value;
      let data;
      if (popupState.value == 1) {
        data = {
          captcha,
          phone
        };
      } else {
        data = {
          password,
          phone
        };
      }
      axios({
        url: api.baseUrl + api.login,
        method: "post",
        data
      }).then(res => {
        // 提示效果
        if (res.data.code == 200) {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'success',
          //   customClass: "message-m"
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'success',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
          // 登录成功
          // 存临时缓存
          localStorage.setItem('userData', JSON.stringify(res.data.data));
          router.push({
            name: 'member_index'
          });
        } else {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'error',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'fail',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
        }
      });
    };
    //重置密码
    const reset = () => {
      let {
        captcha,
        password,
        phone
      } = formData.value;
      let data = {
        captcha,
        password,
        phone
      };
      axios({
        url: api.baseUrl + api.resetPassword,
        method: "post",
        data
      }).then(res => {
        // 提示效果
        if (res.data.code == 200) {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'success',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'success',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
          popupState.value = 1;
        } else {
          // ElMessage({
          //   message: res.data.msg,
          //   type: 'error',
          //   customClass: "message-m",
          // })
          showLoadingToast({
            message: res.data.msg,
            type: 'fail',
            forbidClick: true,
            overlay: true,
            iconSize: '10rem',
            className: 'tc'
          });
        }
      });
    };
    //跳转
    const go = name => {
      router.push({
        name
      });
    };
    const changeAction = e => {
      popupState.value = e;
      formData.value = {
        captcha: '',
        password: '',
        phone: ''
      };
    };
    const back = () => {
      router.back();
    };
    // 服务条款
    const open = () => {
      let routeData = router.resolve({
        name: 'details',
        params: {
          id: 78
        }
      });
      window.open(routeData.href, '_blank');
    };
    return {
      Client,
      popupState,
      formData,
      get_code,
      runClock,
      clock,
      get_code_text,
      changeAction,
      register,
      login,
      reset,
      go,
      isAccept,
      back,
      open,
      clearPhone,
      showClear
    };
  }
};