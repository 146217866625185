import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: 'details_m',
  setup() {
    const route = useRoute();
    const get_data = ref({});
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    // console.log(route.params);
    let url = api.baseUrl + api.details + '?id=' + route.params.id;
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      console.log(data);
      get_data.value = data.data;
    });
    return {
      get_data,
      Client
    };
  }
};