import { unref } from 'vue'
import { inBrowser } from '@/utils'
import { useEventListener } from './useEventListener'

export function useClickAway (target, listener, options = {}) {
  if (!inBrowser) {
    return
  }

  const { eventName = 'click' } = options

  const onClick = event => {
    const element = unref(target)
    if (element && !element.contains(event.target)) {
      listener(event)
    }
  }

  useEventListener(eventName, onClick, { target: document })
}
