import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "lowCode"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("iframe", {
    frameborder: "0",
    id: "iframe",
    scrolling: "no",
    onLoad: _cache[0] || (_cache[0] = (...args) => _ctx.iframeload && _ctx.iframeload(...args)),
    style: _normalizeStyle([{
      "width": "100%",
      "border": "none"
    }, {
      height: _ctx.iframeHeight + 'px'
    }])
  }, null, 36)]);
}