import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderPc = _resolveComponent("HeaderPc");
  const _component_HeaderPcMember = _resolveComponent("HeaderPcMember");
  const _component_HeaderMobile = _resolveComponent("HeaderMobile");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.getShowTask ? (_openBlock(), _createBlock(_component_HeaderPc, {
    key: 0
  })) : _createCommentVNode("", true), _ctx.Client == 0 && _ctx.getShowTask ? (_openBlock(), _createBlock(_component_HeaderPcMember, {
    key: 1
  })) : _createCommentVNode("", true), _ctx.Client == 1 && _ctx.getShowTask ? (_openBlock(), _createBlock(_component_HeaderMobile, {
    key: 2
  })) : _createCommentVNode("", true)]);
}