import "core-js/modules/es.array.push.js";
import { defineComponent, computed, watch, ref, reactive, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { request, storage } from '@/utils';
import { Button } from '@/components/ui';
import { openLoading, closeLoading } from '@/utils/global';
// import wish from '@/components/product/ProdWish'
import { useRoute } from 'vue-router';
import { selectContent } from '@/utils/third';
import eventBus from '@/utils/eventBus';
import Cookies from 'js-cookie';
export default defineComponent({
  name: 'ProductItem',
  components: {
    [Button.name]: Button
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    currentPage: {
      type: Number,
      default: 1
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    currentTitle: {
      type: String,
      default: 'Recommend'
    },
    showQuickBuy: {
      type: Boolean,
      default: true
    },
    showQuickView: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showAttr: {
      type: Boolean,
      default: true
    },
    isCartHover: {
      type: Boolean,
      default: false
    },
    addWish: {
      type: Boolean,
      default: false
    },
    isGift: {
      type: Boolean,
      default: false
    },
    isPreferentialGoods: {
      type: Boolean,
      default: false
    },
    giftInfo: {
      type: Object,
      default: () => ({})
    },
    oneLine: {
      type: Boolean,
      default: false
    },
    selectedColorName: {
      type: String,
      default: ''
    }
  },
  emits: ['update-success'],
  setup(props, {
    emit
  }) {
    const store = useStore();
    const route = useRoute();
    const fast = ref(null);
    const fastLoading = ref(false);
    const variantsPopup = ref(false);
    const variantsData = reactive({});
    const relaProdSize = reactive({});
    const relaProdSizeSku = ref('');
    const apiData = reactive({});
    const relaProd = computed(() => {
      if (props.data.items) {
        return props.data.items;
      }
      if (props.data?.relatedProduct) {
        const rp = [...props.data.relatedProduct];
        const self = {
          ...props.data
        };
        delete self.relatedProduct;
        rp.unshift(self);
        return rp;
      }
      return [props.data];
    });
    const relaActive = ref(0);
    function computeIndex() {
      if (!props.selectedColorName) return 0;
      const index = relaProd.value.findIndex(item => props.selectedColorName.toUpperCase().includes(item.attributesColor.toUpperCase()));
      if (index >= 0) {
        return index;
      } else {
        return 0;
      }
    }
    watchEffect(() => {
      relaActive.value = computeIndex();
    });
    const activeProd = computed(() => {
      return relaProd.value[relaActive.value];
    });
    watch(activeProd, () => {
      if (fast.value?.productsId) {
        fast.value.productsId = activeProd.value.productsId;
      }
    });

    // 保持数据同步更新
    const updateWish = val => {
      activeProd.value.wishList = val;
    };
    const lazyData = (ev, data) => {
      try {
        // TODO: 暂时注释掉，后期可考虑开放预加载数据的功能
        // store.commit('serverData/injectData', {
        //   prodDetail: {
        //     ...data,
        //     isLazy: 1
        //   }
        // })
        faSearchEvent(data);
        selectContent(Object.assign({}, data, {
          listCode: `${route.name}${props.currentTitle ? '+' + props.currentTitle : ''}`,
          listName: props.currentTitle || route.name,
          index: props.currentIndex
        }));
      } catch (err) {
        console.log(err);
      }
    };
    const updateCartItem = async (sku, data) => {
      data.fast ? fastLoading.value = true : openLoading();
      const __data = {
        productId: data.productsId,
        sku,
        qty: 1,
        ...(props.isGift && data.marketing ? {
          ruleId: data.marketing.ruleId
        } : {})
      };
      if (props.isGift && data.marketing) {
        __data.giftType = data.marketing.type;
      }
      if (Cookies.get('gift_deleted')) {
        data.gift_deleted = 1;
      }
      const apiURL = props.isGift ? '/cart/addGift' : '/cart/add';
      return request.post(apiURL, __data).then(res => {
        variantsPopup.value = false;
        if (res.code === 200 && !props.isPreferentialGoods && !data.fast) {
          emit('update-success', res);
        } else {
          emit('update-success');
        }
        // fb 埋点
        window.fbq && fbq('track', 'AddToCart', {
          content_ids: [sku],
          content_type: 'product',
          value: activeProd.value.finalPrice,
          content_name: activeProd.value.productsName,
          currency: storage.load('currentCurrency') || 'AUD'
        });
        fast.value && fast.value.handleHidePop && fast.value.handleHidePop();
        res.code === 200 && route.name !== 'Cart' && store.commit('updateCartSideBar', true);
      }).finally(() => {
        data.fast ? fastLoading.value = false : closeLoading();
      });
    };
    const faSearchEvent = item => {
      if (route.name !== 'SearchList') return;
      const args = {
        pid: item.productsId,
        page: props.currentPage,
        pos: props.currentIndex,
        searchType: item.searchType || '',
        act_word: item.actWord || ''
      };
      try {
        // fa && fa.search('event', args)
      } catch (err) {
        console.log(err);
      }
    };
    const switchColor = pid => {
      relaProd.value?.some((r, k) => {
        if (r.productsId === pid) {
          relaActive.value = k;
          return true;
        }
        return false;
      });
    };
    const showFastPop = () => {
      eventBus.$emit('loadFastProductDetail', activeProd.value.spuCode, activeProd.value.skuCode, activeProd.value.productsId);
    };
    const showVariantsPop = async () => {
      fastLoading.value = true;
      // 一行模式下打开移动端的快速加购模式
      if (props.oneLine && window.innerWidth <= 767) {
        eventBus.$emit('loadFastProductDetail', activeProd.value.spuCode, activeProd.value.skuCode, activeProd.value.productsId, () => {
          fastLoading.value = false;
        });
        return;
      }
      console.log(activeProd.value);
      const {
        code,
        data
      } = await request.get('/product/infoPanel', {
        productsId: activeProd.value.productsId
      });
      if (code === 200) {
        fastLoading.value = false;
        // 存储数据
        Object.assign(apiData, data);
        // 组装属性选项数据
        const attributesArr = Object.values(data.attributes).filter(x => x.canBuy);
        const attrObj = {};
        attributesArr.forEach((o, index) => {
          o.options.forEach(v => {
            for (const key in v) {
              if (index === 0) {
                if (key === 'optionsName') {
                  attrObj[v.optionsName] = {};
                  attrObj[v.optionsName].list = [];
                  attrObj[v.optionsName].canBuy = [];
                  attrObj[v.optionsName].product = o;
                }
              }
              if (key === 'valuesName' && !attrObj[v.optionsName].list.includes(v.valuesName)) {
                attrObj[v.optionsName].list.push(v.valuesName);
                attrObj[v.optionsName].canBuy.push(true);
              }
            }
          });
        });
        variantsData.value = attrObj;
        variantsPopup.value = true;
        return Promise.resolve();
      }
    };
    const toggleLoading = val => {
      fastLoading.value = val;
    };
    const changeSize = (item, variantsDataItem, key, canBuy) => {
      console.log(variantsDataItem.product.skuCode);
      if (!canBuy) return;
      relaProdSize[key] = item;
      relaProdSizeSku.value = variantsDataItem.product.skuCode;
      activeProd.value.formatFinalPrice = variantsDataItem.product.formatFinalPrice;
      activeProd.value.formatProductsPrice = variantsDataItem.product.formatProductsPrice;
      // 设置其他规格禁选
      let disabledArr = [];
      for (const key in apiData.attributes) {
        apiData.attributes[key].options.forEach(o => {
          if (o.valuesName === item) {
            disabledArr = apiData.attributes[key].options.map(item => {
              return item.valuesName;
            });
          }
        });
      }
      for (const key in variantsData.value) {
        variantsData.value[key].list.forEach((item, index) => {
          if (!disabledArr.includes(item)) {
            variantsData.value[key].canBuy[index] = false;
          }
        });
      }
      console.log(variantsData.value, disabledArr);
    };
    const variantsAddToCart = async () => {
      await updateCartItem(relaProdSizeSku.value, {
        fast: 1,
        productsId: activeProd.value.productsId
      });
    };
    const productUrlWithSku = computed(() => {
      return activeProd.value.skuCode ? `${activeProd.value.url}` : activeProd.value.url;
    });
    return {
      fast,
      updateWish,
      fastLoading,
      relaActive,
      relaProd,
      activeProd,
      lazyData,
      updateCartItem,
      switchColor,
      showFastPop,
      toggleLoading,
      variantsPopup,
      variantsData,
      relaProdSize,
      relaProdSizeSku,
      showVariantsPop,
      changeSize,
      variantsAddToCart,
      productUrlWithSku
    };
  }
});