import { withInstall } from '../utils'
import _Radio from './Radio'
import _GroupRadio from './Group-Radio'
import './index.scss'
export const Radio = withInstall(_Radio)
export const GroupRadio = withInstall(_GroupRadio)
export default {
  GroupRadio,
  Radio
}
