import { defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'videos',
  props: ['data'],
  setup(props) {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      item: data
    });
    return {
      Client,
      ...toRefs(state)
    };
  }
});