import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.item.base.isFullScreen ? '' : 'noFullScreen'),
    style: _normalizeStyle([{
      display: 'flex'
    }, {
      'justify-content': _ctx.item.config.valuealign
    }, {
      width: '100%'
    }])
  }, [_createElementVNode("p", {
    style: _normalizeStyle({
      width: _ctx.item.config.width + '%',
      color: _ctx.item.config.color,
      'font-size': _ctx.item.config.value + 'px',
      'line-height': '160%',
      'text-align': _ctx.item.config.valuealign
    }),
    innerHTML: _ctx.item.base.subtitle
  }, null, 12, _hoisted_1)], 6);
}