import { createRouter, createWebHistory } from 'vue-router'
// 引入字典
import routers from "../utils/dictionary.js";
// 引入截取url参数组件
import 'polyfill-object.fromentries'
// 引入api地址
import axios from "axios";
import { api } from "@/api/api";
// 引入vueX
import { useStore } from 'vuex';
import Home from '../views/Home.vue'
import about from '../views/about.vue'
import Zone from '../views/Zone.vue'
import science from '../views/science.vue'
import news from '../views/news.vue'
import details from '../views/details.vue'
import why from '../views/why.vue'
import seek from '../views/seek.vue'
import welfare from '../views/welfare.vue'
import welfare_details from '../views/welfare_details.vue'
import welfare_details_brief from '../views/welfare_details_brief.vue'
import member_index from '../views/member/member_index.vue'
import member_science from '../views/member/member_science.vue'
import member_science_more from '../views/member/member_science_more.vue'
import member_details from '../views/member/member_details.vue'
import member_association from '../views/member/member_association.vue'
import member_expert from '../views/member/member_expert.vue'
import member_clock from '../views/member/member_clock.vue'
import member_upload from '../views/member/member_upload.vue'
import member_raffle from '../views/member/member_raffle.vue'
import member_preferential from '../views/member/member_preferential.vue'
import member_upload_push from '../views/member/member_upload_push.vue'
import member_welfare from '../views/member/member_welfare.vue'
import member_receive from '../views/member/member_receive.vue'
import member_fxdetails from '../views/member/member_fxdetails.vue'
import member_shou from '../views/member/member_shou.vue'
import member_shou_m from '../views/member_m/member_shou_m.vue'
import about_m from '../views/mobile/about_m.vue'
import login_m from '../views/mobile/login_m.vue'
import Zone_m from '../views/mobile/Zone_m.vue'
import infoList from '../views/mobile/infoList.vue'
import science_boot_m from '../views/mobile/science_boot_m.vue'
import science_answer_m from '../views/mobile/science_answer_m.vue'
import seek_details_m from '../views/mobile/seek_details_m.vue'
import djs_m from '../views/djs_m.vue'
import member_department from '../views/member_m/member_department.vue'
import member_expert_m from '../views/member_m/member_expert.vue'
import member_department_xq from '../views/member_m/member_department_xq.vue'
import member_science_m from '../views/member_m/member_science.vue'
import member_details_m from '../views/member_m/member_details.vue'
import member_my from '../views/member_m/member_my.vue'
import member_association_m from '../views/member_m/member_association_m.vue'
import member_fxdetails_m from '../views/member_m/member_fxdetails_m.vue'
import member_upload_m from '../views/member_m/member_upload_m.vue'
import member_welfare_m from '../views/member_m/member_welfare_m.vue'
import member_upload_push_m from '../views/member_m/member_upload_push_m.vue'
import member_preferential_m from '../views/member_m/member_preferential_m.vue'
import member_preferential_xq_m from '../views/member_m/member_preferential_xq_m.vue'
import member_receive_m from '../views/member_m/member_receive_m.vue'
import member_gydetails_m from '../views/member_m/member_gydetails_m.vue'
import member_collect from '../views/member_m/member_collect.vue'
import agreement from '../views/agreement'
import member_details_brief from '../views/member/member_details_brief.vue'
import member_new_pc from '../views/member/member_new_pc.vue'
import member_new from '../views/member_m/member_new.vue'
import member_history_m from '../views/member_m/member_history_m.vue'
import member_questionnaire_m from '../views/member_m/member_questionnaire_m.vue'
import member_history from '../views/member/member_history.vue'
import member_record from '../views/member/member_record.vue'
import member_record_m from '../views/member_m/member_record_m.vue'
import member_userCard from '../views/member/member_userCard.vue'
import member_userCard_xq from '../views/member/member_userCard_xq.vue'
import member_userCard_m from '../views/member_m/member_userCard_m.vue'
import member_userCard_xq_m from '../views/member_m/member_userCard_xq_m.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/Zone',
    name: 'Zone',
    component: Zone
  },
  {
    path: '/science',
    name: 'science',
    component: science
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/details/:id',
    name: 'details',
    component: details
  },
  {
    path: '/why',
    name: 'why',
    component: why
  },
  {
    path: '/seek',
    name: 'seek',
    component: seek
  },
  {
    path: '/welfare',
    name: 'welfare',
    component: welfare
  },
  {
    path: '/member_science_more',
    name: 'member_science_more',
    component: member_science_more
  },
  {
    path: '/welfare_details/:id',
    name: 'welfare_details',
    component: welfare_details
  },
  {
    path: '/member_shou/:text',
    name: 'member_shou',
    component: member_shou
  },
  {
    path: '/member_questionnaire_m',
    name: 'member_questionnaire_m',
    component: member_questionnaire_m
  },
  {
    path: '/member_shou_m/:text',
    name: 'member_shou_m',
    component: member_shou_m
  },
  {
    path: '/welfare_details_brief/:id',
    name: 'welfare_details_brief',
    component: welfare_details_brief
  },
  {
    path: '/member_preferential_xq_m/:id',
    name: 'member_preferential_xq_m',
    component: member_preferential_xq_m
  },
  {
    path: '/member_history_m',
    name: 'member_history_m',
    component: member_history_m
  },
  {
    path: '/member_history',
    name: 'member_history',
    component: member_history
  },
  {
    path: '/about_m',
    name: 'about_m',
    component: about_m
  },
  {
    path: '/infoList',
    name: 'infoList',
    component: infoList
  },
  {
    path: '/member_index',
    name: 'member_index',
    component: member_index
  },
  {
    path: '/member_new_pc',
    name: 'member_new_pc',
    component: member_new_pc
  },
  {
    path: '/member_science',
    name: 'member_science',
    component: member_science
  },
  {
    path: '/member_details/:id',
    name: 'member_details',
    component: member_details
  },
  {
    path: '/member_fxdetails/:id',
    name: 'member_fxdetails',
    component: member_fxdetails
  },
  {
    path: '/member_association',
    name: 'member_association',
    component: member_association
  },
  {
    path: '/login_m/:id',
    name: 'login_m',
    component: login_m
  }, {
    path: '/member_upload',
    name: 'member_upload',
    component: member_upload
  },
  {
    path: '/Zone_m/:id',
    name: 'Zone_m',
    component: Zone_m
  },
  {
    path: '/science_boot_m',
    name: 'science_boot_m',
    component: science_boot_m
  },
  {
    path: '/science_answer_m',
    name: 'science_answer_m',
    component: science_answer_m
  },
  {
    path: '/seek_details_m/:id',
    name: 'seek_details_m',
    component: seek_details_m
  },
  {
    path: '/member_details_m/:id',
    name: 'member_details_m',
    component: member_details_m
  },
  {
    path: '/member_gydetails_m/:id',
    name: 'member_gydetails_m',
    component: member_gydetails_m
  },
  {
    path: '/member_fxdetails_m/:id',
    name: 'member_fxdetails_m',
    component: member_fxdetails_m
  },
  {
    path: '/djs_m',
    name: 'djs_m',
    component: djs_m
  },
  {
    path: '/member_clock',
    name: 'member_clock',
    component: member_clock
  },
  {
    path: '/member_raffle',
    name: 'member_raffle',
    component: member_raffle
  },
  {
    path: '/member_preferential',
    name: 'member_preferential',
    component: member_preferential
  },
  {
    path: '/member_upload_push',
    name: 'member_upload_push',
    component: member_upload_push
  },
  {
    path: '/member_welfare',
    name: 'member_welfare',
    component: member_welfare
  },
  {
    path: '/member_receive',
    name: 'member_receive',
    component: member_receive
  },
  {
    path: '/member_expert',
    name: 'member_expert',
    component: member_expert
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement
  },
  {
    path: '/member_expert_m',
    name: 'member_expert_m',
    component: member_expert_m
  },
  {
    path: '/member_department',
    name: 'member_department',
    component: member_department
  },
  {
    path: '/member_new',
    name: 'member_new',
    component: member_new
  },
  {
    path: '/member_department_xq',
    name: 'member_department_xq',
    component: member_department_xq
  },
  {
    path: '/member_science_m',
    name: 'member_science_m',
    component: member_science_m
  },
  {
    path: '/member_my',
    name: 'member_my',
    component: member_my
  },
  {
    path: '/member_association_m',
    name: 'member_association_m',
    component: member_association_m
  },
  {
    path: '/member_upload_m',
    name: 'member_upload_m',
    component: member_upload_m
  },
  {
    path: '/member_welfare_m',
    name: 'member_welfare_m',
    component: member_welfare_m
  },
  {
    path: '/member_upload_push_m',
    name: 'member_upload_push_m',
    component: member_upload_push_m
  },
  {
    path: '/member_preferential_m',
    name: 'member_preferential_m',
    component: member_preferential_m
  },
  {
    path: '/member_details_brief/:id',
    name: 'member_details_brief',
    component: member_details_brief
  }
  ,
  {
    path: '/member_receive_m',
    name: 'member_receive_m',
    component: member_receive_m
  },
  {
    path: '/member_collect',
    name: 'member_collect',
    component: member_collect
  },
  {
    path: '/member_record',
    name: 'member_record',
    component: member_record
  },
  {
    path: '/member_record_m',
    name: 'member_record_m',
    component: member_record_m
  },
  {
    path: '/member_userCard',
    name: 'member_userCard',
    component: member_userCard
  },
  {
    path: '/member_userCard_xq',
    name: 'member_userCard_xq',
    component: member_userCard_xq
  },
  {
    path: '/member_userCard_m',
    name: 'member_userCard_m',
    component: member_userCard_m
  },
  {
    path: '/member_userCard_xq_m',
    name: 'member_userCard_xq_m',
    component: member_userCard_xq_m
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function resetRouter() {
  const newRouter = new Router({
    mode: 'history',
    routes: []
  })
  router.matcher = newRouter.matcher // the relevant part
}

router.beforeEach((to, from, next) => {
  const store = useStore()
  // 检查来源
  let url = window.location.href
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  console.log(result);
  // // 判断登录并且是去会员页面的
  if (localStorage.getItem('userData') && routers.includes(to.name)) {
    let data = JSON.parse(localStorage.getItem('userData'))
    if (/MicroMessenger/.test(navigator.userAgent)) {
      if (!data.unionid && !result.code) {
        // url处理
        let baseUrl = 'https://www.mimodental.com/member_index'
        let encode = encodeURI(baseUrl)
        let base64 = btoa(encode)
        let redirect_uri = store.state.Client == 0 ? encode : encode
        let scope = store.state.Client == 0 ? 'snsapi_login' : 'snsapi_userinfo'
        let appid = store.state.Client == 0 ? 'wx64b955839bc59c8e' : 'wxe031caa9d96f4432'
        let beURL = store.state.Client == 0 ? 'https://open.weixin.qq.com/connect/qrconnect?' : 'https://open.weixin.qq.com/connect/oauth2/authorize?'
        window.location.href = beURL + 'appid=' + appid + '&redirect_uri=' +
          redirect_uri + '&response_type=code&scope=' + scope + '&state=STATE#wechat_redirect' + (store.state.Client == 1 ? '&forcePopup=true' : '')
      } else if (result.code) {
        // 回调带有code 绑定
        // code换取id
        axios({
          url: api.baseUrl + api.wxcode + '?code=' + result.code + '&client=' + store.state.Client,
          method: 'get'
        }).then((res) => {
          const date = res.data.data
          // console.log(res);
          // 拿到id去绑定
          axios({
            url: api.baseUrl + api.bindWxid,
            method: 'post',
            data: {
              userId: data.id,
              unionid: date.unionid
            }
          }).then((res) => {
            // 绑定成功后更新
            let url = api.baseUrl + api.get_user_info + data.id
            axios({
              url,
              method: 'get'
            }).then((res) => {
              console.log(res);
              // 存临时缓存
              localStorage.setItem('userData', JSON.stringify(res.data.data))
              sessionStorage.setItem('userData', JSON.stringify(res.data.data))
            })
          })
        })
      }
    } else {
      if (store.state.Client == 0) {
        if (!data.unionid && !result.code) {
          // url处理
          let baseUrl = 'https://www.mimodental.com/member_index'
          let encode = encodeURI(baseUrl)
          let base64 = btoa(encode)
          let redirect_uri = store.state.Client == 0 ? encode : encode
          let scope = store.state.Client == 0 ? 'snsapi_login' : 'snsapi_userinfo'
          let appid = store.state.Client == 0 ? 'wx64b955839bc59c8e' : 'wxe031caa9d96f4432'
          let beURL = store.state.Client == 0 ? 'https://open.weixin.qq.com/connect/qrconnect?' : 'https://open.weixin.qq.com/connect/oauth2/authorize?'
          window.location.href = beURL + 'appid=' + appid + '&redirect_uri=' +
            redirect_uri + '&response_type=code&scope=' + scope + '&state=STATE#wechat_redirect' + (store.state.Client == 1 ? '&forcePopup=true' : '')
        } else if (result.code) {
          // 回调带有code 绑定
          // code换取id
          axios({
            url: api.baseUrl + api.wxcode + '?code=' + result.code + '&client=' + store.state.Client,
            method: 'get'
          }).then((res) => {
            const date = res.data.data
            // console.log(res);
            // 拿到id去绑定
            axios({
              url: api.baseUrl + api.bindWxid,
              method: 'post',
              data: {
                userId: data.id,
                unionid: date.unionid
              }
            }).then((res) => {
              // 绑定成功后更新
              let url = api.baseUrl + api.get_user_info + data.id
              axios({
                url,
                method: 'get'
              }).then((res) => {
                console.log(res);
                // 存临时缓存
                localStorage.setItem('userData', JSON.stringify(res.data.data))
                sessionStorage.setItem('userData', JSON.stringify(res.data.data))
              })
            })
          })
        }
      }
    }
  }
  // 判断是否存在来源 存在则存缓存
  if (result.source) {
    localStorage.setItem('source', result.source)
  }
  localStorage.setItem('key', to.name)
  console.log(from);
  if (from.name != 'member_details_m') {
    document.documentElement.scrollTop = 0;
  }
  var d = document.getElementById("app");
  // 判断是否会员端 区分会员端与游客端
  // 输出判断结果
  console.log(routers.includes(to.name));
  store.commit('serviceMember', routers.includes(to.name))
  // 修改路由名字
  store.commit('servicerouterKey', to.name)
  // 游客端登录判断
  if (to.name == 'login_m') {
    store.commit('serviceLogin', true)
  } else {
    store.commit('serviceLogin', false)
  }
  // 动态修改背景颜色
  if (routers.includes(to.name) && to.name != 'member_expert_m') {
    if (store.state.Client == 0) {
      d.style.minWidth = "1560px";
    }
    d.style.background = '#f2f2f2'
    let bodyStyle = document.body.style
    bodyStyle.background = '#f2f2f2'
  } else {
    d.style.background = '#fff'
    let bodyStyle = document.body.style
    bodyStyle.background = '#fff'
  }
  // 口腔科普复位
  console.log(to, from);
  if (to.name == 'science') {
    if (from.name != 'details') {
      store.commit('serviceTabIndex', 0)
    }
  }
  next()
})

export default router
