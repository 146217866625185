export default {
  name: 'member_raffle',
  data() {
    return {
      blocks: [{
        padding: '10px',
        background: '#FFFFFF'
      }],
      prizes: [{
        x: 0,
        y: 0,
        fonts: [{
          text: '一等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 1,
        y: 0,
        fonts: [{
          text: '二等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 2,
        y: 0,
        fonts: [{
          text: '三等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 2,
        y: 1,
        fonts: [{
          text: '四等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 2,
        y: 2,
        fonts: [{
          text: '五等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 1,
        y: 2,
        fonts: [{
          text: '六等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 0,
        y: 2,
        fonts: [{
          text: '七等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }, {
        x: 0,
        y: 1,
        fonts: [{
          text: '八等奖\nxxxxx',
          wordWrap: false,
          top: '45%',
          fontColor: '#E5CC22'
        }],
        background: '#fff',
        shadow: '0px 0px 20px #E9CE21'
      }],
      buttons: [{
        x: 1,
        y: 1,
        background: '#E9CE21',
        fonts: [{
          text: '开始',
          top: '45%',
          fontColor: '#fff'
        }]
      }],
      defaultConfig: {
        gutter: '30px'
      },
      activeStyle: {
        fontColor: '#fff',
        background: '#E9CE21'
      }
    };
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play();
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const index = 0;
        // 调用stop停止旋转并传递中奖索引
        this.$refs.myLucky.stop(index);
      }, 3000);
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize);
    }
  }
};