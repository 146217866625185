import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
export default {
  name: 'member_preferential_m',
  setup() {
    // 优惠券tab
    const preferential_tab = ref([{
      name: '未使用',
      key: '1'
    }, {
      name: '已使用',
      key: '2'
    }, {
      name: '已过期',
      key: '5'
    }]);
    // 优惠券tab选中
    const preferential_tab_key = ref(1);
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 优惠券列表
    const cardRecordsList = ref([]);
    // 优惠券弹窗
    const show = ref(false);
    // 弹窗内容
    const dialog_data = ref({});
    // 优惠卷样式
    const designsObj = ref({});
    // 获取优惠券
    const get_cardRecords = () => {
      let url = api.baseUrl + api.cardRecords + '?userId=' + user_info.value.id + '&status=' + preferential_tab_key.value;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        cardRecordsList.value = res.data.rows;
        designs();
      });
    };
    // tab点击事件
    const preferential_tab_click = e => {
      preferential_tab_key.value = e;
      get_cardRecords();
    };
    // 使用优惠券
    const show_dialog = id => {
      axios({
        url: api.baseUrl + api.cards_xq + id,
        method: 'get'
      }).then(res => {
        console.log(res);
        dialog_data.value = res.data.data;
        show.value = true;
      });
    };
    const pay_yhj = id => {
      router.push({
        name: 'member_preferential_xq_m',
        params: {
          id
        }
      });
    };
    // 关闭弹窗
    const handleClose = () => {
      show.value = false;
    };
    const fh = () => {
      router.go(-1);
    };
    const designs = () => {
      let data = cardRecordsList.value.map(item => {
        return {
          kaquanId: item.cardId,
          client: 1,
          type: preferential_tab_key.value != '5' ? preferential_tab_key.value - 1 : 2
        };
      });
      axios({
        url: api.baseUrl + api.designs,
        method: 'post',
        data
      }).then(res => {
        // console.log(res);
        designsObj.value = res.data.data;
      });
    };
    get_cardRecords();
    return {
      preferential_tab,
      preferential_tab_key,
      preferential_tab_click,
      show,
      dialog_data,
      handleClose,
      show_dialog,
      cardRecordsList,
      fh,
      pay_yhj,
      designsObj
    };
  }
};