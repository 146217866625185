import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
export default {
  name: 'member_index',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // tab
    const tabList = ref([{
      name: '公益活动',
      key: 'upload',
      go: 'member_upload'
    }, {
      name: '领取优惠券',
      key: 'preferential',
      go: 'member_receive'
    }, {
      name: '檬友分享',
      go: 'member_association',
      key: 'association'
    }, {
      name: '口腔知识',
      go: 'member_science',
      key: 'knowledge'
    }, {
      name: '媒体报道',
      go: 'member_new_pc',
      key: 'new'
    }]);
    const get_data = ref({});
    const userCard_list = ref(0);
    // 获取优惠券
    const get_cardRecords = () => {
      let url = api.baseUrl + api.cardRecords + '?userId=' + user_info.value.id + '&status=' + 1;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res.data.rows.length);
        user_info.value.params.countKaquan = res.data.rows.length;
      });
    };
    // 获取信息
    const get_text = () => {
      let url = api.baseUrl + api.text + '?category=science&pageSize=6&pageNum=1&isNotes=0&userId=' + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        get_data.value.top = res.data.rows;
      });
    };
    // 路由跳转
    const go = e => {
      console.log(e);
      router.push({
        name: e
      });
    };
    const lungo = url => {
      // console.log(e);
      router.push({
        path: url
      });
    };
    const go_details_brief = (name, id) => {
      router.push({
        name: name,
        params: {
          id: id
        }
      });
    };
    // 查看图文详情
    const details = e => {
      router.push({
        name: store.state.Client == 0 ? 'member_details' : 'member_details_m',
        params: {
          id: e
        }
      });
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    get_cardRecords();
    get_text();
    // 判断是否已消费
    if (user_info.value.spent == '1') {
      tabList.value.push({
        name: '诊疗记录',
        go: 'member_record',
        key: 'record'
      });
      tabList.value.unshift({
        name: '口腔专家',
        go: 'member_expert',
        key: 'pc_expert'
      });
    }
    const get_userCard = () => {
      let url = api.baseUrl + api.userCard + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res.data;
        let num = 0;
        data.map(item => {
          if (item.status == '已激活') {
            num += 1;
          }
        });
        userCard_list.value = num;
      });
    };
    const lun = ref([]);
    // 获取轮播图
    const osMemberHome = () => {
      let url = api.baseUrl + api.osMemberHome;
      axios({
        url,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res.data;
        console.log(data);
        lun.value = data;
      });
    };
    onMounted(() => {
      get_userCard();
      osMemberHome();
    });
    return {
      user_info,
      tabList,
      get_data,
      Client,
      go,
      go_details_brief,
      details,
      call,
      get_cardRecords,
      userCard_list,
      lun,
      lungo
    };
  }
};