import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_recList = _resolveComponent("recList");
  const _component_prodList = _resolveComponent("prodList");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["goods-list-item", _ctx.item.base.isFullScreen ? '' : 'noFullScreen'])
  }, [_ctx.item.data.length ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_ctx.item.base.isCarousel ? (_openBlock(), _createBlock(_component_recList, {
    key: 0,
    class: "prod-detail-recommand",
    addWish: true,
    title: _ctx.item.base.title,
    isShowBorder: false,
    "need-point": false,
    list: _ctx.item.data,
    button: "square",
    titleCenter: ""
  }, null, 8, ["title", "list"])) : (_openBlock(), _createBlock(_component_prodList, {
    key: 1,
    class: "category-product overflow-hidden -mx-2",
    list: _ctx.item.data,
    "current-page": 1,
    "current-title": _ctx.item.base.title,
    "show-quick-buy": false,
    col: _ctx.col
  }, null, 8, ["list", "current-title", "col"]))], 64)) : _createCommentVNode("", true)], 2);
}