import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'service',
  setup() {
    const store = useStore();
    const getShowTask = computed(() => {
      //返回的是ref对象
      return store.state.show_service;
    });
    watch(getShowTask, (newVal, oldVal) => {
      console.log('newVal, oldVal', newVal, oldVal);
    }, {
      immediate: true,
      deep: true
    });
    const handleClose = () => {
      store.commit('serviceShow', false);
    };
    const Client = store.state.Client;
    return {
      handleClose,
      getShowTask,
      Client
    };
  }
});