export const htmlEncode = function (handleString) {
  /* return handleString
  .replace(/&/g,"&amp;")
  .replace(/</g,"&lt;")
  .replace(/>/g,"&gt;")
  .replace(/ /g,"&nbsp;")
  .replace(/\'/g,"&#39;")
  .replace(/\"/g,"&quot;"); */
  return handleString.replace(/&|<|>|'|"/g, '')
}
