import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e702bd8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "zoom": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "text-align": "center",
    "margin": "30px 0",
    "font-size": "1rem"
  }
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "width": "100%",
    "font-size": "1rem"
  }
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", null, _toDisplayString($setup.get_data.title), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", {
    class: "html-m",
    innerHTML: $setup.get_data.text,
    style: {
      "margin-bottom": "30px",
      "width": "94%",
      "font-size": "1rem"
    }
  }, null, 8, _hoisted_4)])]);
}