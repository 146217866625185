import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import science_m from '@/views/mobile/science_m';
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
export default {
  name: 'science',
  components: {
    science_m
  },
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const loginShow = ref(false);
    const showState = ref(1); //0为注册，1为登录
    const show = () => {
      store.commit('serviceShow', true);
    };
    // tab栏目
    const tabList = ref([{
      name: '正畸',
      key: 'zhengji'
    }, {
      name: '种植',
      key: 'zhongzhi'
    }, {
      name: '修复',
      key: 'xiufu'
    }, {
      name: '综合',
      key: 'zonghe'
    }, {
      name: '肌功能',
      key: 'jigongneng'
    }, {
      name: '儿牙',
      key: 'erya'
    }]);
    // 当前选中的tab
    const tabIndex = ref(store.state.tabIndex);
    // 展示信息
    const get_data = ref({});
    // 当前选中的展示信息
    const select_index = ref(0);
    // 专家解答
    const answer = ref([]);
    // 获取信息
    const get_text = () => {
      let url = api.baseUrl + api.text + '?category=science&category2=' + tabList.value[tabIndex.value].key + '&isNotes=0';
      axios({
        url,
        method: 'get'
      }).then(res => {
        // console.log(res);
        get_data.value.top = res.data.rows;
      });
    };
    // 获取底部注意事项
    const get_attention = () => {
      let url = api.baseUrl + api.text + '?category=science&category2=' + tabList.value[tabIndex.value].key + '&isNotes=1';
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        get_data.value.boot = res.data.rows;
      });
    };
    // tab点击事件
    const tabClick = e => {
      if (tabIndex.value != e) {
        store.commit('serviceTabIndex', e);
        tabIndex.value = e;
        get_text();
        get_attention();
        get_answer();
      }
    };
    // 顶部新闻切换事件
    const info_option = e => {
      select_index.value = e;
    };
    // 查看图文详情
    const details = (e, index) => {
      console.log(index);
      if (index <= 2) {
        router.push({
          name: 'details',
          params: {
            id: e
          }
        });
      } else {
        // 判断有没有登录
        if (!localStorage.getItem('userData')) {
          show();
        } else {
          const user_info = ref(JSON.parse(localStorage.getItem('userData')));
          if (user_info.value.spent != '1') {
            show();
          } else {
            router.push({
              name: 'details',
              params: {
                id: e
              }
            });
          }
        }
      }
    };
    //了解更多
    const learnMore = () => {
      if (localStorage.getItem('userData')) {
        router.push({
          name: 'member_science_more'
        });
      } else {
        loginShow.value = true;
      }
    };
    const modifyShowState = e => {
      loginShow.value = false;
    };
    const getUserData = () => {
      console.log();
      if (localStorage.getItem('userData')) {
        userData.value = JSON.parse(localStorage.getItem('userData'));
      }
    };
    const more = () => {
      router.push({
        name: 'about'
      });
      localStorage.setItem('key', 'about');
    };
    const findCon = () => {
      router.push({
        name: 'seek'
      });
      localStorage.setItem('key', 'seek');
    };
    // 获取专家解读
    const get_answer = () => {
      let url = api.baseUrl + api.text + '?category=problem&category2=science&category3=' + tabList.value[tabIndex.value].key;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        answer.value = res.data.rows;
      });
    };
    get_text();
    get_attention();
    get_answer();
    return {
      Client,
      tabList,
      tabIndex,
      get_text,
      tabClick,
      get_attention,
      get_data,
      select_index,
      info_option,
      details,
      loginShow,
      learnMore,
      showState,
      modifyShowState,
      getUserData,
      more,
      findCon,
      show,
      answer
    };
  }
};