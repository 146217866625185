import {
  ref,
  defineComponent
} from 'vue'
import Loading from '@/components/common/loading.vue'

const name = 'g-imguploader'

const props = {
  imgList: {
    type: Array,
    default: () => []
  },
  deletable: {
    type: Boolean,
    default: true
  },
  Index: { // 当前循环的索引
    type: [String, Number]
  },
  maxSize: {
    type: Number,
    default: 0
  },
  maxCount: {
    type: Number,
    default: 1
  },
  uploadIcon: {
    type: String,
    default: ''
  }
}

export default defineComponent({
  name,
  props,
  emits: ['after-read', 'before-delete', 'update:imgList', 'oversize'],
  setup (props, { emit, slots }) {
    const list = ref([
      ...props.imgList
    ])
    const change = event => {
      const flie = Array.prototype.slice.call(event.target.files)
      if (flie.length === 0) return false
      flie.forEach((f, fKey) => {
        if (props.maxCount <= fKey) {
          return false
        }
        if (props.maxSize && f.size > props.maxSize) {
          emit('oversize', f)
          return false
        }
        const reader = new FileReader()
        const tempKey = list.value.length
        reader.readAsDataURL(f)
        reader.addEventListener('load', () => {
          list.value[tempKey].base64 = reader.result
          emit('after-read', list.value[tempKey], props.Index ? props.Index : 0)
          emit('update:imgList', list)
        }, false)
        list.value[tempKey] = {
          file: f,
          index: tempKey,
          loading: true
        }
      })
      emit('update:imgList', list.value)
      // 清空图片信息
      event.target.value = null
    }

    const deleteImg = async (key) => {
      await emit('before-delete', key, props.Index)
      list.value.splice(key, 1)
      emit('update:imgList', list.value)
    }

    const renderIcon = () => {
      return (
        <>
          <i class={['iconfont-g icon-add', props.uploadIcon ? props.uploadIcon : 'i-g-a-tupian-24-10']} />
          { props.maxCount > 1 && <span class='count'>{list.value.length} / {props.maxCount}</span> }
        </>
      )
    }

    const renderItem = (item, key) => {
      return (
        <div class='item'>
          { item.status !== 'done' &&
            (item.loading && (slots.loading
              ? slots.loading()
              : <div class="img-mask">
                <Loading
                  fill="#fff"
                />
              </div>))
          }
          { item.status === 'done' && props.deletable &&
            <span class="icon-close-box">
              <i class='iconfont-g icon-icon-tupianshanchu- i-g-a-guanbitianchong16-10' onClick={ () => deleteImg(key) } />
            </span>
          }
          <div class='g-img-box'><img src={item.base64} /></div>
        </div>
      )
    }

    return () => (
      <div class='g-img-uploader'>
        {
          list.value.map((item, key) => {
            return renderItem(item, key)
          })
        }
        { list.value.length < props.maxCount &&
          <div class='item uploader-input-wrap'>
            { slots.default ? slots.default() : renderIcon() }
            <input class='g-img-input' type='file' accept='image/*' multiple={ props.maxCount > 1 } onChange={ $event => change($event) } />
          </div>
        }
      </div>
    )
  }
})
