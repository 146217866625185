import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17611345"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "home_m"
};
const _hoisted_2 = {
  class: "vido_list"
};
const _hoisted_3 = {
  class: "vido_list_l"
};
const _hoisted_4 = {
  class: "list"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "dome_img"
};
const _hoisted_7 = ["src", "onClick"];
const _hoisted_8 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.get_data.boot, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "vido_dom",
      key: index
    }, [_createElementVNode("h1", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
      class: "info-img",
      src: item.imgUrl,
      onClick: $event => $setup.details(item.id, index)
    }, null, 8, _hoisted_7), _createElementVNode("div", null, [_createElementVNode("span", {
      class: "concat-us",
      onClick: _cache[0] || (_cache[0] = (...args) => $setup.show && $setup.show(...args))
    }, "联系我们"), _createElementVNode("span", {
      class: "guide",
      onClick: $event => $setup.details(item.id, index)
    }, "术后指引", 8, _hoisted_8)])])]);
  }), 128))])])])]);
}