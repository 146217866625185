import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from 'vue-router';
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue';
import SwiperCore, { EffectFade, Controller, Autoplay } from 'swiper';
import { request } from '@/utils';
import { Toast, Dialog } from '@/components/ui';
import { Swiper, SwiperSlide } from 'swiper/vue';
SwiperCore.use([Autoplay]);
export default defineComponent({
  name: '',
  props: ['data'],
  components: {
    Swiper,
    SwiperSlide
  },
  setup(props) {
    const router = useRouter();
    const {
      data
    } = {
      ...props
    };
    const imgSwiper = ref(null);
    const state = reactive({
      item: data,
      swiperIndex: null
    });
    const fetchSet = {
      getCoupon: params => request.get('/coupon/getCouponById', params)
    };
    onMounted(() => {
      const swiperObj = document.getElementsByClassName('_swiper');
      // console.log(swiperObj[0].swiper.realIndex, '===================')
      state.swiperIndex = swiperObj[0].swiper.realIndex;
    });
    const slideChange = data => {
      state.swiperIndex = data.realIndex;
    };
    const changePagination = index => {
      const swiperObj = document.getElementsByClassName('_swiper');
      state.swiperIndex = index;
      swiperObj[0].swiper.slideTo(index + 1);
    };
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };
    // 跳转链接
    const toLinkUrl = (i, name) => {
      // console.log(i)
      if (!i.linkPage) {
        return false;
      }
      if (i.linkPage === 'activityPage') {
        router.push({
          path: '/activePage',
          query: {
            id: i.id.id
          }
        });
      } else if (i.linkPage === 'coupon') {
        fetchSet.getCoupon({
          couponId: i.id
        }).then(res => {
          const {
            code,
            message
          } = res;
          if (code === 200) {
            GetCouponsPop('Successfully received');
          } else {
            GetCouponsPop(message);
          }
        }).catch(err => {
          Toast(err);
        });
      } else {
        document.location.href = i.url;
      }
    };
    return {
      ...toRefs(state),
      toLinkUrl,
      slideChange,
      changePagination,
      imgSwiper
    };
  }
});