import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FooterPc = _resolveComponent("FooterPc");
  const _component_FooterPcMember = _resolveComponent("FooterPcMember");
  const _component_FooterMobile = _resolveComponent("FooterMobile");
  return _openBlock(), _createElementBlock("div", null, [!_ctx.getShowTask ? (_openBlock(), _createBlock(_component_FooterPc, {
    key: 0
  })) : _createCommentVNode("", true), _ctx.Client == 0 && _ctx.getShowTask ? (_openBlock(), _createBlock(_component_FooterPcMember, {
    key: 1
  })) : _createCommentVNode("", true), _ctx.Client == 1 && _ctx.getShowTask ? (_openBlock(), _createBlock(_component_FooterMobile, {
    key: 2
  })) : _createCommentVNode("", true)]);
}