import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: '',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      item: data
    });
    return {
      ...toRefs(state)
    };
  }
});