import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cc148b7e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "member_details_body"
};
const _hoisted_2 = {
  class: "navbat"
};
const _hoisted_3 = {
  style: {
    "position": "relative",
    "overflow": "hidden"
  },
  class: "nr"
};
const _hoisted_4 = {
  style: {
    "display": "flex",
    "width": "100%",
    "flex-direction": "column",
    "align-items": "center"
  },
  class: "nr_data"
};
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: "https://www.mimodental.com/assets/imgData/imgs/fh.png",
    alt: "",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.fh && $setup.fh(...args))
  }), _createElementVNode("span", null, _toDisplayString($setup.hd_data.cardName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("p", {
    innerHTML: $setup.hd_data.status == '未激活' ? $setup.hd_data.unDetailsM : $setup.hd_data.detailsM,
    style: {
      "margin-bottom": "100px",
      "width": "80%"
    }
  }, null, 8, _hoisted_5), $setup.hd_data.status == '未激活' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: "https://www.mimodental.com/assets/imgData/img/new/wjh_btn.png",
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.call && $setup.call(...args)),
    alt: ""
  })) : _createCommentVNode("", true)])])]);
}