import {
  ref,
  getCurrentInstance,
  watch
} from 'vue'

import { extend, usePopupState, mountComponent, withInstall } from '../utils'
import { isObject, inBrowser } from '@/utils'
import FMSToast from './ToastRender'

const defaultOptions = {
  type: 'text',
  message: '',
  className: '',
  overlay: false,
  onClose: undefined,
  onOpened: undefined,
  duration: 2000,
  teleport: 'body',
  position: 'middle',
  transition: 'g-fade',
  closeOnClick: false,
  closeOnClickOverlay: false
}

let queue = []
let allowMutiple = false
let currentOptions = extend({}, defaultOptions)

const defaultOptionsMap = new Map()

const parseOptions = (message) => {
  if (isObject(message)) {
    return message
  } else {
    return { message }
  }
}

const createInstance = () => {
  const { instance, unmount } = mountComponent({
    setup () {
      const message = ref('')
      const { open, state, close, toggle } = usePopupState()

      const onClosed = () => {
        if (allowMutiple) {
          queue = queue.filter(item => item !== instance)
          unmount()
        }
      }

      const render = () => {
        const attrs = {
          onClosed,
          'onUpdate:show': toggle
        }

        return <FMSToast {...state } {...attrs} />
      }

      watch(message, (val) => {
        state.message = val
      })

      getCurrentInstance().render = render

      return {
        open,
        clear: close,
        message,
        state
      }
    }
  })

  return instance
}

const getInstance = () => {
  if (!queue.length || allowMutiple) {
    const instance = createInstance()
    queue.push(instance)
  }

  return queue[queue.length - 1]
}

const Toast = (options) => {
  // 容错处理, 防止ssr工程渲染报错
  if (!inBrowser) {
    return {}
  }
  const toast = getInstance()
  const parsedOptions = parseOptions(options)

  toast.open(
    extend(
      {},
      currentOptions,
      defaultOptionsMap.get(parsedOptions.type || currentOptions.type),
      parsedOptions
    )
  )

  return toast
}

const createMethod = (type) => (options) =>
  Toast(extend({ type }, parseOptions(options)))

// Toast.loading = createMethod('loading')
Toast.success = createMethod('success')
Toast.fail = createMethod('fail')

Toast.clear = (isAll) => {
  if (queue.length) {
    if (isAll) {
      queue.forEach(_ => {
        _.clear()
      })
      queue = []
    } else if (!allowMutiple) {
      queue[0].clear()
    } else {
      queue.shift()?.clear()
    }
  }
}

Toast.setDefaultOptions = (type, options) => {
  if (type === 'string') {
    defaultOptionsMap.set(type, options)
  } else {
    extend(currentOptions, type)
  }
}

Toast.resetDefaultOptions = type => {
  if (typeof type === 'string') {
    defaultOptionsMap.delete(type)
  } else {
    currentOptions = extend({}, defaultOptions)
    defaultOptionsMap.clear()
  }
}

Toast.allowMutiple = value => {
  allowMutiple = value
}

Toast.install = app => {
  app.use(withInstall(FMSToast))
  app.config.globalProperties.$toast = Toast
}

export default Toast
export {
  Toast
}
