import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
export default {
  name: 'member_expert_m',
  setup() {
    const upList = ref([]);
    const tjList = ref([]);
    const tjList_index = ref(0);
    const tjShow = ref(false);
    // 获取口腔专家
    const get_list = () => {
      let url = api.baseUrl + api.specialist + '?section=' + tjList.value[tjList_index.value].dictValue;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
      });
    };
    // 获取条件列表
    const get_section = () => {
      let url = api.baseUrl + api.section;
      axios({
        url,
        method: 'get'
      }).then(res => {
        tjList.value = res.data.data;
        get_list();
      });
    };
    const fh = () => {
      router.go(-1);
    };
    const tjListClick = index => {
      tjList_index.value = index;
      tjShow.value = false;
      get_list();
    };
    get_section();
    return {
      upList,
      fh,
      tjList,
      tjList_index,
      tjShow,
      tjListClick
    };
  }
};