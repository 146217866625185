import "core-js/modules/es.array.push.js";
import subscription from './subscription';
import { useRouter, useRoute } from 'vue-router';
//   import { request } from '@/utils'
import { Swiper, SwiperSlide } from 'swiper/vue';
// import { openLoading, closeLoading } from '@/utils/global'
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { Toast, Dialog } from '@/components/ui';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'imageText',
  props: ['data', 'imageTextIndex'],
  components: {
    subscription,
    Swiper,
    SwiperSlide
  },
  setup(props) {
    const {
      data,
      imageTextIndex
    } = {
      ...props
    };
    const item = data;
    const itIndex = imageTextIndex;
    const router = useRouter();
    const route = useRoute();
    const ishHomePage = route.path === '/' ? 'homeBtn' : 'otherBtn';
    const state = reactive({
      screenWidthFlag: '',
      mobileSwiperIndex: null,
      ishHomePage
    });
    const fastLoadingText = ref('ADD');
    const store = useStore();
    onMounted(() => {
      setTimeout(() => {
        const swiperObj = document.getElementsByClassName(`img_swiper${itIndex}`);
        // console.log(swiperObj, '==============')
        if (swiperObj && swiperObj[0]) {
          state.mobileSwiperIndex = swiperObj[0].swiper.realIndex;
        }
      }, 0);
    });
    const getAStyle = item => {
      return {
        // height: `${item.inputHeight}px`,
        // lineHeight: `${item.inputHeight}px`,
        color: `${item.fontColor} !important`,
        fontSize: `${item.fontSizeVal}px`,
        textAlign: item.alignoptionsVal,
        marginBottom: `${item.marginBottom}px`,
        marginTop: `${item.marginTop}px`,
        paddingTop: `${item.paddingTop}px`,
        paddingLeft: `${item.paddingLeft}px`,
        paddingRight: `${item.paddingRight}px`,
        paddingBottom: `${item.paddingBottom}px`,
        fontFamily: item.fontFamilyVal
      };
    };
    const getBtnStyle = item => {
      return {
        color: item.btnColor,
        backgroundColor: item.btnbgColor,
        fontSize: `${item.btnFontSizeVal}px`,
        marginBottom: `${item.btnMarginBottom}px`,
        marginTop: `${item.btnMarginTop}px`,
        borderRadius: `${item.borderRadius}px`,
        textAlign: `${item.alignoptionsVal}`,
        paddingTop: `${item.btnPaddingTop}px`,
        paddingLeft: `${item.btnPaddingLeft}px`,
        paddingRight: `${item.btnPaddingRight}px`,
        paddingBottom: `${item.btnPaddingBottom}px`
      };
    };
    const getImgBoxStyle = (item, it, isMobile) => {
      return {
        width: !isMobile ? item.data.length > 1 ? it.gridRatio ? it.gridRatio + '%' : `${(100 - item.data.length) / item.data.length}%` : '100%' : item.base.mobileConfigId && item.base.mobileConfigId > 0 ? `${(100 - item.base.mobileConfigId) / item.base.mobileConfigId}%` : '100%',
        height: 'auto',
        marginRight: item.data.length > 1 ? '1%' : '',
        backgroundImage: isMobile ? `url(${it.itemBgUrl !== 0 ? it.itemBgUrl : ''})` : `url(${it.data.length !== 0 ? it.itemBgUrl : ''})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
        // minHeight: '400px'
      };
    };

    const fetchSet = {
      // getCoupon: (params) => request.get('/coupon/getCouponById', params)
    };
    // 跳转链接
    const toLinkUrl = (i, name) => {
      // console.log(i)
      if (!i.linkPage) {
        return false;
      }
      if (i.linkPage === 'activityPage') {
        router.push({
          path: '/activePage',
          query: {
            id: i.id.id
          }
        });
      } else if (i.linkPage === 'coupon') {
        fetchSet.getCoupon({
          couponId: i.id
        }).then(res => {
          const {
            code,
            message
          } = res;
          if (code === 200) {
            GetCouponsPop('Successfully received');
          } else {
            GetCouponsPop(message);
          }
        }).catch(err => {
          Toast(err);
        });
      } else {
        document.location.href = i.url;
      }
    };
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };

    //   const AddToCart = async (data) => {
    //     if (fastLoadingText.value === 'ADD') {
    //       fastLoadingText.value = 'Adding...'
    //       const __data = {
    //         productId: data.productId,
    //         sku: data.sku
    //       }
    //       request
    //         .post('/cart/add', __data)
    //         .then(res => {
    //           if (res.code === 200) {
    //             fastLoadingText.value = 'Thank you!'
    //             store.commit('updateCartSideBar', true)
    //             setTimeout(() => {
    //               fastLoadingText.value = 'ADD'
    //             }, 1000)
    //           }
    //         })
    //         .finally(() => {
    //           fastLoadingText.value = 'ADD'
    //         })
    //     }
    //   }
    const changePagination = index => {
      const swiperObj = document.getElementsByClassName(`img_swiper${itIndex}`);
      state.mobileSwiperIndex = index;
      swiperObj[0].swiper.slideTo(index);
    };
    const slideChange = data => {
      state.mobileSwiperIndex = data.realIndex;
    };
    return {
      item,
      itIndex,
      getAStyle,
      getBtnStyle,
      toLinkUrl,
      getImgBoxStyle,
      state,
      fastLoadingText,
      changePagination,
      slideChange
    };
  }
});