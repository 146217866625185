import "core-js/modules/es.array.push.js";
import { useStore } from 'vuex';
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import router from '@/router';
export default {
  name: 'HeaderMobile',
  setup() {
    const tab_mobile = ref([]);
    const classification = ref([]);
    const text = ref('');
    const store = useStore(); // 该方法用于返回store 实例
    // 获取当前页面
    const key = computed(() => {
      return store.state.routerKey;
    });
    tab_mobile.value = [
    // {
    //   name: '口腔专家',
    //   notes: '了解米檬的团队',
    //   img: 'expert'
    // },
    {
      name: '诊疗科室',
      notes: '高品质的诊疗体验',
      img: 'Department',
      go: 'member_department'
    }, {
      name: '米檬分享',
      notes: '分享米檬的诊疗',
      img: 'share',
      go: 'member_association_m'
    }];
    classification.value = [{
      name: '公益活动',
      key: 'upload',
      go: 'member_upload_m'
    }, {
      name: '优惠券',
      key: 'preferential',
      go: 'member_receive_m'
    }, {
      name: '媒体报道',
      key: 'new',
      go: 'member_new'
    }
    // {
    //   name: '打卡',
    //   key: 'clock'
    // },
    // {
    //   name: '专属社群',
    //   key: 'association'
    // }
    ];
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 判断是否已消费
    if (user_info.value.spent == '1') {
      classification.value.push({
        name: '诊疗记录',
        go: 'member_record_m',
        key: 'record'
      });
      tab_mobile.value.unshift({
        name: '口腔专家',
        notes: '了解米檬的团队',
        img: 'expert',
        go: 'member_expert_m'
      });
    }
    const searchBtn = event => {
      if (event.keyCode == 13) {
        router.push({
          name: 'member_shou_m',
          params: {
            text: text.value
          }
        });
        text.value = '';
      }
    };
    const go = name => {
      router.push({
        name
      });
    };
    return {
      // eslint-disable-next-line camelcase
      tab_mobile,
      classification,
      key,
      go,
      searchBtn,
      text,
      user_info
    };
  }
};