import { createVNode, render, nextTick } from 'vue'
import FMSMessageBox from '../messagebox/MessageBox'
import { inBrowser } from '@/utils'
const Dialog = opt => {
  return new Promise((resolve, reject) => {
    const options = opt.value ? opt.value : opt
    // 容错处理, 防止ssr工程渲染报错
    if (!inBrowser) {
      return {}
    }
    const vm = createVNode(FMSMessageBox, { Options: options })
    nextTick(() => {
      // 渲染组件，并插入 body 之中
      const container = document.createDocumentFragment()
      render(vm, container)
      document.body.appendChild(container)
      vm.component.props.show = true
      // 确认回调
      vm.component.props.Options.confirmCallback = () => {
        vm.component.ctx.remove()
        resolve('confirm')
      }
      // 销毁组件
      vm.component.ctx.remove = function () {
        render(null, container)
      }
      // 取消回调
      vm.component.props.Options.cancelCallback = () => {
        vm.component.ctx.remove()
      }
    })
  })
}
export { Dialog }
