import { createApp, reactive, nextTick, getCurrentInstance, unref } from 'vue'
import { inBrowser, isPromise } from '@/utils'
/* const camelizeRE = /-(\w)/g;

const camelize = (str) => {
  return str.replace(camelizeRE, (_, c) => c.toUpperCase())
} */

/**
 * @description 判断是否已定义变量
 * @param {any} val
 * @returns Boolean
 */
export const isDef = val => val !== undefined && val !== null

export const truthProp = {
  type: Boolean,
  default: true
}

// 空函数
export function noop () {}

export const extend = Object.assign

export const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max)
}

export function raf (fn) {
  return inBrowser ? requestAnimationFrame(fn) : -1
}

export function cancelRaf (id) {
  if (inBrowser) {
    cancelAnimationFrame(id)
  }
}

export function doubleRaf (fn) {
  raf(() => raf(fn))
}

export const stopPropagation = event => {
  event.stopPropagation()
}

export function preventDefault (event, isStopPropagation) {
  /* istanbul ignore else */
  if (typeof event.cancelable !== 'boolean' || event.cancelable) {
    event.preventDefault()
  }

  if (isStopPropagation) {
    stopPropagation(event)
  }
}

export function isHidden (elementRef) {
  const el = unref(elementRef)
  if (!el) {
    return false
  }

  const style = window.getComputedStyle(el)
  const hidden = style.display === 'none'

  // offsetParent 会在以下两种情况返回null
  // 1. 节点或者父节点设置display属性为none
  // 2. 节点设置position属性为fixed
  const parentHidden = el.offsetParent === null && style.position !== 'fixed'

  return hidden || parentHidden
}

// 支持vue.use语法调用的外壳
export const withInstall = options => {
  options.install = app => {
    const { name } = options
    app.component(name, options)
    // app.component(camelize(`-${name}`), options)
  }
  return options
}

// expose public api
export const useExpose = apis => {
  const instance = getCurrentInstance()
  if (instance) {
    extend(instance.proxy, apis)
  }
}

// 弹窗类通用方法设置
export const usePopupState = () => {
  const state = reactive({
    show: false
  })

  const toggle = show => {
    state.show = show
  }

  const open = props => {
    extend(state, props)
    nextTick(() => toggle(true))
  }

  const close = () => toggle(false)

  useExpose({ open, close, toggle })

  return {
    open,
    close,
    state,
    toggle
  }
}

// 挂载组件
export const mountComponent = RootComponent => {
  const app = createApp(RootComponent)
  const root = document.createElement('div')
  document.body.appendChild(root)
  return {
    instance: app.mount(root),
    unmount () {
      app.unmount()
      document.body.removeChild(root)
    }
  }
}

/**
 * 回调函数挂载
 */
export const callInterceptor = options => {
  const { interceptor, args, done, canceled } = options
  if (interceptor) {
    const returnVal = interceptor.apply(null, args || [])

    if (isPromise(returnVal)) {
      returnVal
        .then(val => {
          if (val) {
            done()
          } else if (canceled) {
            canceled()
          }
        })
        .catch(noop)
    } else if (returnVal) {
      done()
    } else if (canceled) {
      canceled()
    }
  } else {
    done()
  }
}

// toast 禁止点击
let lockCount = 0
export const lockClick = lock => {
  if (lock) {
    if (!lockCount) {
      document.body.classList.add('g-toast-unclickable')
    }

    lockCount++
  } else if (lockCount) {
    lockCount--

    if (!lockCount) {
      document.body.classList.remove('g-toast-unclickable')
    }
  }
}

export const padZero = (num, targetLength = 2) => {
  let str = '' + num

  while (str.length < targetLength) {
    str = '0' + str
  }

  return str
}

export const parseFormat = (format, currentTime) => {
  const { days } = currentTime
  let { hours, minutes, seconds, milliseconds } = currentTime

  if (format.includes('DD')) {
    format = format.replace('DD', padZero(days))
  } else {
    hours += days * 24
  }

  if (format.includes('HH')) {
    format = format.replace('HH', padZero(hours))
  } else {
    minutes += hours * 60
  }

  if (format.includes('mm')) {
    format = format.replace('mm', padZero(minutes))
  } else {
    seconds += minutes * 60
  }

  if (format.includes('ss')) {
    format = format.replace('ss', padZero(seconds))
  } else {
    milliseconds += seconds * 1000
  }

  if (format.includes('S')) {
    const ms = padZero(milliseconds, 3)

    if (format.includes('SSS')) {
      format = format.replace('SSS', ms)
    } else if (format.includes('SS')) {
      format = format.replace('SS', ms.slice(0, 2))
    } else {
      format = format.replace('SS', ms.charAt(0))
    }
  }

  return format
}

export function throttle (action, delay) {
  let timeout = null
  let lastRun = 0
  return function (...args) {
    if (timeout) {
      return
    }
    const elapsed = Date.now() - lastRun
    const runCallback = () => {
      lastRun = Date.now()
      timeout = false
      action.apply(this, args)
    }
    if (elapsed >= delay) {
      runCallback()
    } else {
      timeout = setTimeout(runCallback, delay)
    }
  }
}

/**
* @desc 函数防抖
* @param func (function) 函数
* @param wait (number) 延迟执行毫秒数
* @param immediate (boolean) true 表立即执行，false 表非立即执行
*/
export function debounce (func, wait, immediate) {
  let timeout = null
  return function () {
    const context = this
    const args = arguments
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(function () {
        timeout = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args)
      }, wait)
    }
  }
}

export function scrollLeftTo (scroller, to, duration) {
  let count = 0
  const from = scroller.scrollLeft
  const frames = duration === 0 ? 1 : Math.round((duration * 1000) / 16)

  function animate () {
    scroller.scrollLeft += (to - from) / frames

    if (++count < frames) {
      raf(animate)
    }
  }

  animate()
}

/**
 * 获取字段类型
 * @param {任何} val [必选] 传入需要判断类型的字段
 */
export function getType (val) {
  const typeArr = {
    isString: false,
    isNumber: false,
    isArray: false,
    isObject: false,
    isUndefined: false,
    isNull: false,
    isFunction: false,
    isBoolean: false
  }
  const type = Object.prototype.toString
    .call(val)
    .replace(/\[|\]|object| /g, '')

  switch (type) {
    case 'String':
      typeArr.isString = true
      break
    case 'Number':
      typeArr.isNumber = true
      break
    case 'Array':
      typeArr.isArray = true
      break
    case 'Object':
      typeArr.isObject = true
      break
    case 'Undefined':
      typeArr.isUndefined = true
      break
    case 'Null':
      typeArr.isNull = true
      break
    case 'Function':
      typeArr.isFunction = true
      break
    case 'Boolean':
      typeArr.isBoolean = true
      break
    default:
    // do nothing
  }

  return typeArr
}
