import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
export default {
  name: 'member_upload',
  setup() {
    const upList = ref([]);
    // 获取上传资料列表
    const get_list = () => {
      let url = api.baseUrl + api.benefit;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        let resultList = [];
        for (let index in res.data.rows) {
          if (res.data.rows[index].status == 0) {
            resultList.push(res.data.rows[index]);
          }
        }
        upList.value = resultList;
      });
    };
    const go = e => {
      // router.push({
      //     name: 'member_upload_push'
      // })
      if (e == 2) {
        router.push({
          name: 'member_welfare'
        });
        return;
      }
      // if (e == 4) {
      //     router.push({
      //         name: 'member_details_brief',
      //         params: {
      //             id: e
      //         }
      //     })
      //     return
      // }
      router.push({
        name: 'member_details_brief',
        params: {
          id: e
        }
      });
    };
    get_list();
    return {
      go,
      upList
    };
  }
};