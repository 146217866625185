import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import prodItem from '@/components/product/ProductItem';
import { viewItemList, getCurrency } from '@/utils/third';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useEventListener } from '../ui/composables';
SwiperCore.use([Navigation, Pagination]);
export default defineComponent({
  name: 'RecommandList',
  components: {
    prodItem,
    Swiper,
    SwiperSlide
  },
  props: {
    swiperName: {
      type: String,
      default: () => {
        return `swiper${Date.now()}`;
      }
    },
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'You may also like'
    },
    titleCenter: {
      type: Boolean,
      default: false
    },
    addWish: {
      type: Boolean,
      default: false
    },
    isGift: {
      type: Boolean,
      default: false
    },
    isPreferentialGoods: {
      type: Boolean,
      default: false
    },
    giftInfo: {
      type: Object,
      default: () => ({})
    },
    isShowBorder: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update-success'],
  setup(props, {
    emit
  }) {
    const route = useRoute();
    const updateSuccess = res => {
      emit('update-success', res);
    };
    const triggerView = () => {
      const sendList = props.list.map((p, index) => {
        const item = p.items?.[0] || {};
        return {
          pid: item.productsId,
          name: item.productsName,
          currency: getCurrency(),
          finalPrice: item.finalPrice,
          discount: item.discount,
          index: index
        };
      });
      const faParams = {
        itemListCode: `${route.name}${props.title}`,
        itemListName: `${route.name}${props.title}`,
        items: sendList
      };
      viewItemList(null, faParams);
    };
    const onSwiper = swiper => {
      swiper.init();
      // console.log(swiper)
    };

    const slidesPerGroup = ref(4);
    onMounted(() => {
      if (window.innerWidth < 768) {
        slidesPerGroup.value = 2;
      } else if (window.innerWidth < 1024) {
        slidesPerGroup.value = 3;
      }
    });
    useEventListener('resize', () => {
      if (window.innerWidth < 768) {
        slidesPerGroup.value = 2;
      } else if (window.innerWidth < 1024) {
        slidesPerGroup.value = 3;
      } else {
        slidesPerGroup.value = 4;
      }
    });
    return {
      onSwiper,
      triggerView,
      updateSuccess,
      slidesPerGroup
    };
  }
});