import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_prodItem = _resolveComponent("prodItem");
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  const _directive_observer = _resolveDirective("observer");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["recommand-box", {
      'recommand-bn': !_ctx.isShowBorder
    }])
  }, [_ctx.title ? (_openBlock(), _createElementBlock("h3", {
    key: 0,
    class: _normalizeClass(["recommand-title", {
      'text-center': _ctx.titleCenter
    }])
  }, _toDisplayString(_ctx.title), 3)) : _createCommentVNode("", true), _createVNode(_component_swiper, {
    class: _normalizeClass(_ctx.swiperName),
    "slides-per-view": "auto",
    "slides-per-group": _ctx.slidesPerGroup,
    navigation: {
      clickable: true
    },
    pagination: {
      clickable: true
    },
    onSwiper: _ctx.onSwiper,
    loop: ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: index,
        class: "recommand-product-box"
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_component_prodItem, {
          key: item.items?.[0]?.spuCode || index,
          data: item,
          "current-index": index,
          "current-title": _ctx.title,
          "add-wish": _ctx.addWish,
          "is-gift": _ctx.isGift,
          "is-preferential-goods": _ctx.isPreferentialGoods,
          "gift-info": _ctx.giftInfo[index],
          onUpdateSuccess: _ctx.updateSuccess,
          "show-quick-buy": _ctx.isGift || _ctx.isPreferentialGoods
        }, {
          option: _withCtx(({
            option
          }) => [_renderSlot(_ctx.$slots, "option", {
            option: option
          })]),
          _: 2
        }, 1032, ["data", "current-index", "current-title", "add-wish", "is-gift", "is-preferential-goods", "gift-info", "onUpdateSuccess", "show-quick-buy"]))]),
        _: 2
      }, 1024);
    }), 128)), _createTextVNode(" ... ")]),
    _: 3
  }, 8, ["class", "slides-per-group", "onSwiper"])], 2)), [[_directive_observer, _ctx.triggerView]]);
}