import {
  computed,
  nextTick,
  reactive,
  onMounted,
  defineComponent
} from 'vue'

import { useParent } from '../composables'
import { useExpose } from '../utils'

import { SWIPE_KEY } from '../swipe/Swipe'

export default defineComponent({
  name: 'g-swipe-item',

  setup (props, { slots }) {
    let rendered
    const state = reactive({
      offset: 0,
      inited: false,
      mounted: false
    })

    const { parent, index } = useParent(SWIPE_KEY)

    if (!parent) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('<SwipeItem> must be a child component of <Swipe>')
      }
      return
    }

    const style = computed(() => {
      const style = {}

      if (parent.size.value) {
        style.width = `${parent.size.value}px`
      }

      if (state.offset) {
        if (parent.isVertical) {
          style.transform = `translateY(${state.offset}px)`
        } else {
          style.transform = `translateX(${state.offset}px)`
        }
      }

      return style
    })

    const shouldRender = computed(() => {
      const { loop, lazyRender } = parent.props

      if (!lazyRender || rendered) {
        return true
      }

      if (!state.mounted) {
        return false
      }

      const active = parent.activeIndicator.value
      const maxActive = parent.count.value - 1
      const prevActive = active === 0 && loop ? maxActive : active - 1
      const nextActive = active === maxActive && loop ? 0 : active + 1
      rendered = index.value === active ||
        index.value === prevActive ||
        index.value === nextActive

      return rendered
    })

    const setOffset = offset => {
      state.offset = offset
    }

    onMounted(() => {
      nextTick(() => {
        state.mounted = true
      })
    })

    useExpose({ setOffset })

    return () => (
      <div class={`g-swipe-item ${index.value === parent.activeIndicator.value ? 'active' : ''}`} style={style.value}>
        { shouldRender.value ? slots.default?.() : null }
      </div>
    )
  }
})
