import "core-js/modules/es.array.push.js";
import { ref, onMounted } from 'vue';
import router from '@/router';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { headerPcM } from './HeaderPcM.vue';
import popup from '../../common/popup.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: 'HeaderPc',
  components: {
    popup
  },
  setup() {
    // 数据内容
    const tabList = ref([{
      name: '首页',
      key: 'home'
    }, {
      name: '关于米檬',
      key: 'about'
    }, {
      name: '诊疗专区',
      key: 'Zone'
    }, {
      name: '口腔科普',
      key: 'science'
    }, {
      name: '新闻资讯',
      key: 'news'
    }, {
      name: '公益行动',
      key: 'welfare'
    }]);
    const route = useRoute();
    const routeName = route.name;
    const key = ref('home');
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const loginShow = ref(false);
    const showState = ref(1); //0为注册，1为登录
    const userData = ref(false);
    const nav_to = e => {
      // console.log(e);
      router.push({
        name: e
      });
      key.value = e;
      localStorage.setItem('key', e);
    };
    const login = () => {
      loginShow.value = true;
    };
    const modifyShowState = e => {
      loginShow.value = false;
    };
    const getUserData = () => {
      console.log(localStorage.getItem('userData'));
      if (localStorage.getItem('userData')) {
        userData.value = JSON.parse(localStorage.getItem('userData'));
      }
    };
    // 注销登录
    const logOff = () => {
      ElMessageBox.confirm('确定要退出登录吗?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        localStorage.clear();
        sessionStorage.clear();
        userData.value = false;
        ElMessage({
          type: 'success',
          message: '退出登录'
        });
      });
    };
    // 获取用户数据
    onMounted(() => {
      getUserData();
      key.value = localStorage.getItem('key');
      window.addEventListener('setItemEvent', function (e) {
        // console.log(e);
        if (e.key == 'key') {
          key.value = e.newValue;
        }
      });
    });
    // 会员服务点击事件
    const userInfo = () => {
      console.log('1');
      if (!userData.value) {
        login();
      } else {
        router.push({
          name: 'member_index'
        });
      }
    };
    return {
      key,
      tabList,
      routeName,
      Client,
      nav_to,
      login,
      loginShow,
      showState,
      modifyShowState,
      getUserData,
      userData,
      logOff,
      userInfo
    };
  }
};