import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useRoute } from 'vue-router';
import router from '@/router';
import { ElMessage } from 'element-plus';
export default {
  name: 'member_details_m',
  setup() {
    document.documentElement.scrollTop = 0;
    const route = useRoute();
    const get_data = ref({});
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // console.log(route.params);
    const details = () => {
      let url = api.baseUrl + api.details + '?id=' + route.params.id + '&userId=' + user_info.value.id;
      axios({
        url,
        method: "get"
      }).then(res => {
        let {
          data
        } = res;
        console.log(data);
        get_data.value = data.data;
      });
    };
    const fh = () => {
      // router.go(-1)
      router.back();
    };
    const collect = () => {
      let url = api.baseUrl + api.collect;
      axios({
        url,
        method: 'post',
        data: {
          userId: user_info.value.id,
          textId: route.params.id,
          isDelete: get_data.value.userCollect == 0 ? 0 : 1
        }
      }).then(res => {
        console.log(res);
        ElMessage({
          message: get_data.value.userCollect == 0 ? '收藏成功' : '取消收藏',
          type: 'success',
          customClass: "message-m"
        });
        details();
      });
    };
    details();
    return {
      get_data,
      fh,
      collect
    };
  }
};