import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import HeaderPc from './components/HeaderPc.vue';
import HeaderMobile from './components/HeaderMobile.vue';
import HeaderPcMember from './components/HeaderPcMember.vue';
export default defineComponent({
  name: 'Header',
  components: {
    HeaderPc,
    HeaderMobile,
    HeaderPcMember
  },
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    // 是否为会员端
    const getShowTask = computed(() => {
      return store.state.is_member;
    });
    // 监听更新
    // watch(getShowTask, (newVal, oldVal) => {
    //   // console.log('newVal, oldVal', newVal, oldVal)
    //   if (!newVal) {
    //     console.log('游客端');
    //   } else {
    //     console.log('会员端');
    //   }
    // }, { immediate: true, deep: true });
    return {
      Client,
      getShowTask
    };
  }
});