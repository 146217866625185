import { defineComponent, ref, provide, nextTick, reactive, computed, onMounted, watch } from 'vue'
import { useChildren, useWindowSize } from '../composables'

const name = 'g-tabs'
export const TABS_KEY = Symbol(name)
export default defineComponent({
  name,
  props: {
    defaultKey: {
      type: [String, Number],
      default: '1'
    },
    scrollAble: {
      type: Boolean,
      default: true
    }
  },
  emits: ['tabCallback'],
  setup (props, context) {
    const windowSize = useWindowSize()
    const $slot = context.slots?.default()
    const currentIndex = ref(0)
    const state = reactive({
      lineWidth: 0,
      lineOffset: 0
    })

    const activeKey = ref(props.defaultKey) // 当前key
    provide('activeKey', activeKey)
    const { linkChildren } = useChildren(TABS_KEY)
    linkChildren()

    const lineStyle = computed(() => {
      return {
        width: `${state.lineWidth}px`,
        transform: `translateX(${state.lineOffset}px)`
      }
    })

    const onTabClick = (e, tab, index) => {
      activeKey.value = tab.props.name
      currentIndex.value = index
      context.emit('tabCallback', activeKey.value)
      LineSlide(e)
    }
    // 下划线滑动
    const LineSlide = (e) => {
      nextTick(() => {
        const $dom = e.target
        if ($dom) {
          state.lineWidth = ($dom.childNodes && $dom.childNodes[0]?.clientWidth) || $dom.clientWidth
          state.lineOffset = ($dom.childNodes && $dom.childNodes[0]?.offsetLeft) || $dom.offsetLeft
        } else {
          state.lineWidth = 0
          state.lineOffset = 0
        }
      })
    }
    // 初始化计算下划线
    const initCalcLine = () => {
      nextTick(() => {
        try {
          const activeEle = document.querySelectorAll('.tab-header-ui .tab-nav.active') || []
          activeEle.length && activeEle.forEach(e => {
            const $rect = e.childNodes && e.childNodes[0]
            const $curBar = e.parentNode.parentNode.childNodes[1]
            if ($curBar) {
              $curBar.style.width = `${$rect.clientWidth}px`
              $curBar.style.transform = `translateX(${$rect.offsetLeft}px)`
            }
          })
        } catch (err) {
          console.log(err)
        }
      })
    }

    const resize = () => {
      initCalcLine()
    }

    watch([windowSize.width, windowSize.height], resize)

    onMounted(() => {
      initCalcLine()
    })

    const renderNav = () => {
      return $slot.map((item, index) => {
        return (
          <div class={[{ active: activeKey.value === item.props.name }, 'tab-nav']}
            onClick={(e) => onTabClick(e, item, index)}
          >
            <span class="tab-nav_text">{ item.props.label }</span>
          </div>
        )
      })
    }

    const renderTabs = () => {
      return (
        <div class="tab-group-ui">
          <div class="tab-bar-nav">
            <div ref="titsRef" class="tab-header-ui" layout="row">
              {renderNav()}
            </div>
            <div ref="barRef" class="tab-bar" style={lineStyle.value} />
          </div>
          <div class="tab-panel">
            { $slot }
          </div>
        </div>
      )
    }

    return () => {
      return (
        <>
          {renderTabs()}
        </>
      )
    }
  }
})
