import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63fcddc7"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "first-title"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 0,
  class: "collapse-item"
};
const _hoisted_6 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["accordion", _ctx.item.base.isFullScreen ? 'a-fullScreen' : 'noFullScreen'])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (k, j) => {
    return _openBlock(), _createElementBlock("div", {
      key: j,
      onClick: $event => _ctx.openItem(k, j),
      class: "collapse-box"
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(k.title), 1), _createElementVNode("img", {
      src: k.active ? 'https://www.mimodental.com/assets/imgData/imgs/remove.png' : 'https://www.mimodental.com/assets/imgData/imgs/add.png',
      alt: ""
    }, null, 8, _hoisted_4)]), k.active ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
      innerHTML: k.content
    }, null, 8, _hoisted_6)])) : _createCommentVNode("", true)], 8, _hoisted_1);
  }), 128))], 2);
}