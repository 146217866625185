import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center",
    "margin": "30px 0"
  }
};
const _hoisted_2 = {
  style: {
    "font-size": "43px"
  }
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "width": "100%"
  }
};
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_details_m = _resolveComponent("details_m");
  return $setup.Client == 0 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle(['zoom:' + ($setup.Client ? '20%' : '') + ';' + 'border-top:1px solid #E3E3E3;', {
      "min-width": "1560px",
      "padding-bottom": "100px"
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString($setup.get_data.title), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", {
    innerHTML: $setup.get_data.text,
    style: {
      "margin-bottom": "100px",
      "width": "80%",
      "margin": "0 auto",
      "min-width": "960px",
      "max-width": "1020px"
    }
  }, null, 8, _hoisted_4)])], 4)) : (_openBlock(), _createBlock(_component_details_m, {
    key: 1
  }));
}