import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import router from '@/router';
export default {
  name: 'member_userCard_xq_m',
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const route = useRoute();
    const hd_data = ref({
      ...route.query
    });
    // 弹窗内容
    const dialog_data = ref({
      welfares: [{
        "searchValue": null,
        "createBy": null,
        "createTime": null,
        "updateBy": null,
        "updateTime": null,
        "remark": null,
        "params": {},
        "id": 3,
        "name": "全口涂氟",
        "details": "不限次数"
      }, {
        "searchValue": null,
        "createBy": null,
        "createTime": null,
        "updateBy": null,
        "updateTime": null,
        "remark": null,
        "params": {},
        "id": 8,
        "name": "全口洁治",
        "details": "1次"
      }]
    });
    // 获取卡片
    const get_cardRecords = () => {
      axios({
        url: api.baseUrl + api.getCard + hd_data.value.id,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res.data;
        console.log(data);
        dialog_data.value = data;
        // cardRecordsList.value = data
      });
    };

    const fh = () => {
      router.go(-1);
    };
    const call = () => {
      store.commit('serviceShow', true);
    };
    get_cardRecords();
    return {
      dialog_data,
      fh,
      hd_data,
      call
    };
  }
};