import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import FooterPc from './components/FooterPc.vue';
import FooterMobile from './components/FooterMobile.vue';
import FooterPcMember from './components/FooterPcMember.vue';
export default defineComponent({
  name: 'footer',
  components: {
    FooterPc,
    FooterMobile
  },
  setup() {
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    // 是否为会员端
    const getShowTask = computed(() => {
      return store.state.is_member;
    });
    return {
      Client,
      getShowTask
    };
  }
});