import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
export default {
  name: 'member_new_pc',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    const total = ref(0);
    // 获取上传资料列表
    const get_list = e => {
      let url = api.baseUrl + api.text + '?category=information&pageNum=' + e + '&pageSize=10';
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        upList.value = res.data.rows;
        total.value = res.data.total;
        document.documentElement.scrollTop = 0;
      });
    };
    const go = e => {
      router.push({
        name: 'member_details',
        params: {
          id: e
        }
      });
    };
    get_list(1);
    return {
      go,
      upList,
      total,
      get_list
    };
  }
};