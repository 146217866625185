const eventBus = {
  events: {},
  $on (event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  },
  $emit (event, ...args) {
    if (!this.events[event]) {
      return
    }
    this.events[event].forEach(fn => fn(...args))
  },
  $off (event, callback) {
    if (!this.events[event]) {
      return
    }
    if (!callback) {
      delete this.events[event]
    } else {
      this.events[event] = this.events[event].filter(cb => cb !== callback)
    }
  }
}

export default eventBus
