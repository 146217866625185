import {
  defineComponent
} from 'vue'

const name = 'FMSRateStar'

const props = {
  total: {
    type: Number,
    default: 5
  },
  rate: {
    type: Number,
    default: 5
  },
  clickAble: {
    type: Boolean,
    default: false
  }
}

export default defineComponent({
  name,
  props,
  emits: ['update:rate', 'change'],
  setup (props, { emit }) {
    const pickRate = (r) => {
      if (props.clickAble) {
        emit('update:rate', r)
        emit('change', r)
      }
    }
    const renderStar = (iconType) => {
      const tempArr = []
      for (let i = 0; i < props.total; i++) {
        tempArr.push(<i class={ 'star iconfont-g ' + iconType} onClick={() => pickRate(i + 1)} />)
      }
      return tempArr
    }

    return () => (
      <div class='g-rate-star'>
        { renderStar('icon-rate base') }
        <div class="star-box" style={{ width: `${props.rate * 100 / props.total}%` }}>
          { renderStar('icon-rate') }
        </div>
      </div>
    )
  }
})
