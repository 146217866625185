import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4108f7f6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "info-box"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "bottom_jt"
};
const _hoisted_6 = {
  key: 0,
  src: "https://www.mimodental.com/assets/imgData/img/science/gren_link.png",
  alt: ""
};
const _hoisted_7 = {
  key: 1,
  src: "https://www.mimodental.com/assets/imgData/img/science/fff_link.png",
  alt: ""
};
const _hoisted_8 = {
  key: 0,
  class: "bottom_link"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.list?.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($setup.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "top_info",
      key: index,
      style: _normalizeStyle('background:' + ((index + 1) % 2 == 0 ? '#4fbeb7;' : '#fff;'))
    }, [_createElementVNode("div", {
      class: "img_info",
      onClick: $event => $setup.details(item.id, 0)
    }, [_createElementVNode("img", {
      class: "info-img",
      src: item?.imgUrl,
      alt: ""
    }, null, 8, _hoisted_3)], 8, _hoisted_2), _createElementVNode("div", {
      class: "info_option",
      onClick: $event => $setup.details(item.id, 0)
    }, [_createElementVNode("h2", {
      class: "title",
      style: _normalizeStyle('color:' + ((index + 1) % 2 != 0 ? '#4fbeb7;' : '#fff;'))
    }, _toDisplayString(item?.title || ''), 5), _createElementVNode("div", {
      style: _normalizeStyle([{
        "height": "4px",
        "width": "5.5rem",
        "margin-bottom": "2rem",
        "border-radius": "10rem"
      }, 'background:' + ((index + 1) % 2 != 0 ? '#4fbeb7;' : '#fff;')])
    }, null, 4), _createElementVNode("span", {
      class: "content",
      style: _normalizeStyle(['color:' + ((index + 1) % 2 != 0 ? '#000;' : '#fff;'), {
        "font-family": "siyuan_Regular"
      }])
    }, _toDisplayString(item.brief), 5)], 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [(index + 1) % 2 != 0 ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("img", _hoisted_7))]), (index + 1) % 2 != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("", true)], 4);
  }), 128)) : _createCommentVNode("", true)]);
}