import Loading from '../loading'
import { RouterLink } from 'vue-router'
import { debounce } from '@/components/ui/utils'
import {
  defineComponent
} from 'vue'
export default defineComponent({
  name: 'g-button',
  components: {
    RouterLink
  },
  props: {
    className: {
      type: String,
      default: ''
    },
    size: {
      type: String, // large small mini
      default: ''
    },
    type: {
      type: String, // primary info
      default: 'primary'
    },
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: [String, Object],
    round: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: ''
    },
    height: {
      type: [String, Number],
      default: ''
    },
    // 彩虹色按钮
    isRainbow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup (props, { emit, attrs, slots }) {
    const clickAction = debounce(() => {
      if (!props.disabled) {
        emit('click')
      }
    }, 800, true)
    const disabled = (type) => {
      // g-button-disabled 为禁用按钮的公共样式类名
      return props.disabled ? `g-button-${type}-disabled g-button-disabled` : ''
    }
    const type = () => {
      return props.type ? `g-button-${props.type}` : 'g-button-text'
    }
    const round = () => {
      return props.round ? 'g-button-round' : ''
    }
    const size = () => {
      return props.size ? `g-button-${props.size}` : 'g-button-default'
    }
    const rainbow = () => {
      return props.isRainbow ? 'g-button-rainbow' : ''
    }
    const className = () => {
      return props.className ? props.className : attrs.class ? attrs.class : ''
    }
    const renderButton = () => {
      return (
        <button style={`width:${props.width}px;height:${props.height}px`} onClick={clickAction} className={
          `g-button ${props.loading ? 'is-loading' : ''} ${type()} ${size()} ${disabled(props.type)} ${round()} ${rainbow()} ${className()}`}>
          <span class="text">{props.text ? props.text : slots.default?.()}</span>
        </button>
      )
    }
    const routerLink = () => {
      if (!props.disabled && props.to) {
        return (
          <router-link to={props.to}>
            {renderButton()}
          </router-link>
        )
      } else {
        return (
          renderButton()
        )
      }
    }
    return () => {
      return (
        <>
          {routerLink()}
        </>
      )
    }
  }
})
