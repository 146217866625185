import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from 'vue-router';
import { defineComponent, reactive, toRefs, ref, onBeforeMount } from 'vue';
import prodList from '@/components/product/ProductList';
import recList from '@/components/product/RecList';
import { Toast, Dialog } from '@/components/ui';
import { request } from '@/utils';
export default defineComponent({
  name: '',
  props: ['data'],
  components: {
    prodList,
    recList
  },
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const router = useRouter();
    const col = ref(2);
    const state = reactive({
      item: data,
      screenWidthFlag: ''
    });
    onBeforeMount(() => {
      state.screenWidthFlag = window.screen.width >= 1024;
      if (window.innerWidth >= 1024) {
        col.value = 4;
      }
      window.addEventListener('resize', () => {
        state.screenWidthFlag = window.screen.width >= 1024;
        if (window.innerWidth >= 1024 && col.value === 2) {
          col.value = 3;
        } else if (window.innerWidth < 1024 && col.value > 2) {
          col.value = 2;
        } else if (window.innerWidth < 1300 && col.value > 3) {
          col.value = 3;
        } else if (window.innerWidth < 1600 && col.value > 4) {
          col.value = 4;
        }
      });
    });
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };
    const fetchSet = {
      getCoupon: params => request.get('/coupon/getCouponById', params)
    };
    // 跳转链接
    const toLinkUrl = (i, name) => {
      console.log(i);
      if (i.linkPage === 'activityPage') {
        router.push({
          path: '/activePage',
          query: {
            id: i.id.id
          }
        });
      } else if (i.linkPage === 'coupon') {
        fetchSet.getCoupon({
          couponId: i.id
        }).then(res => {
          const {
            code,
            message
          } = res;
          if (code === 200) {
            GetCouponsPop('Successfully received');
          } else {
            GetCouponsPop(message);
          }
        }).catch(err => {
          Toast(err);
        });
      } else {
        document.location.href = i.url;
      }
    };
    return {
      ...toRefs(state),
      col,
      toLinkUrl
    };
  }
});