import {
  defineComponent,
  provide,
  computed
} from 'vue'

export const modelValue = Symbol('model')
export default defineComponent({
  name: 'g-group-checkbox',
  props: {
    value: Array,
    label: {
      type: String,
      default: 'radio'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup (props, { emit, attrs, slots }) {
    const value = computed(() => props.value)
    const update = (val) => {
      emit('change', val)
    }
    provide('modelCheckbox', {
      value: value,
      update: update
    })
    return () => {
      return (
        <>
          {slots.default()}
        </>
      )
    }
  }
})
