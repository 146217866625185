import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import seek_m from '@/views/mobile/seek_m';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
const __default__ = {
  name: 'seek',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace,
    seek_m
  },
  setup() {
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const tabList = ref([{
      name: '牙齿不适',
      img: 'discomfort',
      key: '64'
    }, {
      name: '牙齿缺损',
      img: 'defect',
      key: '65'
    }, {
      name: '牙齿不美观',
      img: 'Unattractive',
      key: '66'
    }, {
      name: '牙齿变色',
      img: 'discoloration',
      key: '67'
    }, {
      name: '儿牙专科',
      img: 'children',
      key: '68'
    }]);
    const TabData = ref([]);
    const SelectedTab = ref(0);
    const tabDatastate = ref(false);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=63';
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      getTabData();
    });
    const tabClick = (index, id) => {
      SelectedTab.value = index;
      getTabData(id);
    };
    const getTabData = id => {
      tabDatastate.value = false;
      let Taburl = api.baseUrl + api.webDecor + '?id=' + (id || tabList.value[SelectedTab.value].key);
      axios({
        url: Taburl,
        method: "get"
      }).then(res => {
        let {
          data
        } = res;
        TabData.value = JSON.parse(data.data.editConfig);
        tabDatastate.value = true;
      });
    };
    return {
      get_data,
      Client,
      tabList,
      SelectedTab,
      TabData,
      tabDatastate,
      tabClick,
      getTabData
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "19ad173c": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;