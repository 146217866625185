import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a055feae"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src", "onClick"];
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["imgSwiper", _ctx.item.base.isFullScreen ? '' : 'noFullScreen'])
  }, [_createVNode(_component_swiper, {
    navigation: {
      clickable: true
    },
    pagination: {
      clickable: true
    },
    "slides-per-group": _ctx.item.config.slidesGroup ? parseInt(_ctx.item.config.slidesGroup) : 1,
    "slides-per-view": _ctx.item.config.slidesView ? parseInt(_ctx.item.config.slidesView) : 1,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    onSlideChange: _ctx.slideChange,
    class: _normalizeClass(["_swiper", ['pagination-' + _ctx.item.config.dotLocation, 'pagination-' + _ctx.item.config.dotCover]]),
    ref: "imgSwiper"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (items, indexs) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        class: "policy-swiper-item",
        key: indexs
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          class: "swipe-img",
          src: items.imgUrl,
          onClick: $event => _ctx.toLinkUrl(items),
          alt: "",
          style: {
            "width": "100%",
            "height": "100%"
          }
        }, null, 8, _hoisted_1)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["slides-per-group", "slides-per-view", "onSlideChange", "class"]), !_ctx.item.config.dotCover ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["pagination", ['c-pagination-' + _ctx.item.config.dotLocation]])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data.length, (its, ids) => {
    return _openBlock(), _createElementBlock("span", {
      key: ids,
      class: _normalizeClass([ids === _ctx.swiperIndex ? 'swiper-pagination-bullet-active' : '', "swiper-pagination-bullet"]),
      onClick: $event => _ctx.changePagination(ids)
    }, null, 10, _hoisted_2);
  }), 128))], 2)) : _createCommentVNode("", true)], 2);
}