import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-409f1bce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "imgTextContainer"
};
const _hoisted_2 = {
  class: "hidden lg:block"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src", "onClick"];
const _hoisted_5 = {
  key: 1,
  class: "fit-up-item imgTextMb"
};
const _hoisted_6 = {
  class: "imgTitle"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  key: 2,
  class: "imgContentBottom"
};
const _hoisted_9 = ["href"];
const _hoisted_10 = ["id", "href"];
const _hoisted_11 = {
  key: 3,
  class: "subscription"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  const _component_subscription = _resolveComponent("subscription");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["marginTB", _ctx.item.base.isFullScreen ? '' : 'noFullScreen']),
    style: _normalizeStyle({
      marginTop: _ctx.item.base.containerMT + 'px',
      marginBottom: _ctx.item.base.containerMB + 'px'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createElementVNode("div", {
    class: "imgTextBg",
    style: _normalizeStyle({
      backgroundColor: _ctx.item.base.bgColor,
      backgroundImage: `url(${_ctx.item.base.bgImg ? _ctx.item.base.bgImg : ''})`,
      backgroundRepeat: 'no-repeat'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (it, k) => {
    return _openBlock(), _createElementBlock("div", {
      class: "imgBox",
      key: k,
      style: _normalizeStyle(_ctx.getImgBoxStyle(_ctx.item, it, false))
    }, [it.data.length === 0 ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: it.itemBgUrl
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(it.data, (m, j) => {
      return _openBlock(), _createElementBlock("div", {
        key: j
      }, [m.name === 'slider' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fit-up-item imgTextMb",
        style: _normalizeStyle(m.base.isFullScreen ? 'width: 100%;' : `width: 100%;${_ctx.state.screenWidthFlag ? '' : 'padding: 0;'}`)
      }, [_createVNode(_component_swiper, {
        navigation: {
          clickable: true
        },
        pagination: {
          clickable: true
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m.data, (items, indexs) => {
          return _openBlock(), _createBlock(_component_swiper_slide, {
            class: "policy-swiper-item",
            key: indexs
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              class: "swipe-img",
              src: items.imgUrl,
              onClick: $event => _ctx.toLinkUrl(items),
              alt: "",
              style: {
                "width": "100%",
                "height": "100%"
              }
            }, null, 8, _hoisted_4)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 2
      }, 1024)], 4)) : _createCommentVNode("", true), m.name === 'images' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(m.base.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m.data, img => {
        return _openBlock(), _createElementBlock("div", {
          key: img,
          style: {
            "overflow": "hidden"
          }
        }, [_createElementVNode("img", {
          src: img.imgUrl,
          alt: "",
          style: {
            "margin": "0 auto"
          }
        }, null, 8, _hoisted_7)]);
      }), 128))])) : _createCommentVNode("", true), m.type === 'input' || m.type === 'btn' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", null, [m.type === 'input' ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: m.textHref,
        target: "_blank",
        class: "hoverA",
        style: _normalizeStyle(_ctx.getAStyle(m))
      }, _toDisplayString(m.val), 13, _hoisted_9)) : (_openBlock(), _createElementBlock("a", {
        key: 1,
        class: "btn",
        id: _ctx.state.ishHomePage,
        target: "_blank",
        href: m.btnHref,
        style: _normalizeStyle(_ctx.getBtnStyle(m))
      }, _toDisplayString(m.btnContent), 13, _hoisted_10))])])) : _createCommentVNode("", true), m.name === 'subscription' ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_subscription, {
        data: m
      }, null, 8, ["data"])])) : _createCommentVNode("", true)]);
    }), 128))], 4);
  }), 128))], 4)])])], 6);
}