import "core-js/modules/es.array.push.js";
import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import imageText from '@/components/imageText.vue';
import googleMaps from '@/components/googleMaps';
import accordion from '@/components/accordion';
import lowCode from '@/components/lowCode';
import subscription from '@/components/subscription';
import richText from '@/components/richText';
import imgSwipers from '@/components/imgSwipers';
import images from '@/components/images';
import videos from '@/components/videos';
import navigation from '@/components/navigation';
import goodsGrid from '@/components/goodsGrid';
import texts from '@/components/texts';
import hotMap from '@/components/hotMap';
import goodsScroll from '@/components/goodsScroll';
import mimo_replace from '@/components/home/mimo-replace';
import axios from "axios";
import { api } from "@/api/api";
import { useStore } from 'vuex';
import router from '@/router';
import { useRoute } from 'vue-router';
const __default__ = {
  name: 'seek_details_m',
  components: {
    imageText,
    googleMaps,
    accordion,
    lowCode,
    subscription,
    richText,
    imgSwipers,
    images,
    videos,
    navigation,
    goodsGrid,
    texts,
    hotMap,
    goodsScroll,
    mimo_replace
  },
  setup() {
    const route = useRoute();
    const get_data = ref([]);
    const store = useStore(); // 该方法用于返回store 实例
    const Client = store.state.Client;
    const showPicturesList = ref([]);
    const event = ref([]);
    const loginShow = ref(false);
    const showState = ref(0); //0为注册，1为登录
    const userData = ref(false);
    // 当前选择的时间-年份
    const select_year = ref(0);
    // 当前选择的事件
    const select_event = ref(0);
    // 滚动辅助 判断是向上还是向下
    const auxiliary = ref(0);
    // 装修列表
    let url = api.baseUrl + api.webDecor + '?id=' + route.params.id;
    // 荣誉资质
    let showPictures = api.baseUrl + api.showPictures + '?type=honor';
    // 装修数据
    axios({
      url,
      method: "get"
    }).then(res => {
      let {
        data
      } = res;
      // console.log(data);
      if (data.data.background) {
        var d = document.getElementById("app");
        d.style.background = 'url(' + data.data.background + ')';
        if (data.data.backgroundFit == 'fill') {}
        d.style.backgroundSize = data.data.backgroundFit == 'fill' ? '100% 100%' : 'auto 100%';
        d.style.backgroundPosition = 'center', d.style.backgroundRepeat = 'no-repeat';
      }
      get_data.value = JSON.parse(data.data.editConfig);
      console.log(get_data.value);
    });
    // 事件内部滚动事件
    // const scrollHandler = (e) => {
    //   const scrollTop = e.target.scrollTop
    //   // 判断是向上还是向下
    //   let direction = auxiliary.value > scrollTop ? 0 : 1 //0为向上 1为向下
    //   // 下滑距离
    //   let distance = auxiliary.value > scrollTop ? auxiliary.value - scrollTop : scrollTop - auxiliary.value
    //   // 判断
    //   console.log(distance);
    //   if (distance > 10) {
    //     if (direction) {
    //       // 向下
    //       // 判断是否最后一条
    //       if (select_year.value + 1 == event.value.length) {
    //         if (select_event.value + 1 == event.value[select_year.value].length) {
    //           return
    //         }
    //       }
    //       // 操作
    //       if (select_event.value + 1 < event.value[select_year.value].length) {
    //         select_event.value += 1
    //       } else {
    //         select_year.value += 1
    //         select_event.value = 0
    //       }
    //     } else {
    //       // 向上
    //       // 判断是否第一条
    //       // 判断是否最后一条
    //       if (select_year.value == 0) {
    //         if (select_event.value == 0) {
    //           return
    //         }
    //       }
    //       // 操作
    //       if (select_event.value > 0) {
    //         select_event.value -= 1
    //       } else {
    //         select_year.value -= 1
    //         select_event.value = 0
    //       }
    //     }
    //   }
    //   auxiliary.value = scrollTop
    // }

    // 事件点击切换
    const event_click = (index, ind) => {
      console.log(index);
      console.log(ind);
      select_year.value = index;
      select_event.value = ind;
    };
    const show = () => {
      store.commit('serviceShow', true);
    };
    //了解更多
    const learnMore = () => {
      loginShow.value = true;
    };
    const modifyShowState = e => {
      loginShow.value = false;
    };
    const getUserData = () => {
      console.log(localStorage.getItem('userData'));
      if (localStorage.getItem('userData')) {
        userData.value = JSON.parse(localStorage.getItem('userData'));
      }
    };
    const gotoWelfareD = () => {
      router.push({
        name: 'welfare_details',
        params: {
          id: 2
        }
      });
    };
    const gotoWelfareDB = () => {
      router.push({
        name: 'welfare_details_brief',
        params: {
          id: 3
        }
      });
    };
    const openFile = type => {
      if (type == "duty") {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=https://mimo-scrm-test.oss-cn-guangzhou.aliyuncs.com/file/%E6%82%A3%E8%80%85%E6%9D%83%E5%88%A9%E3%80%81%E8%B4%A3%E4%BB%BB%E5%92%8C%E9%9A%90%E7%A7%81.docx');
      } else if (type == "security") {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=https://mimo-scrm-test.oss-cn-guangzhou.aliyuncs.com/file/%E7%B1%B3%E6%AA%AC%E5%8F%A3%E8%85%94%E7%9A%84%E4%BF%9D%E5%AF%86%E6%80%A7.docx');
      }
    };
    return {
      get_data,
      Client,
      showPicturesList,
      event,
      select_year,
      select_event,
      // scrollHandler
      event_click,
      show,
      modifyShowState,
      getUserData,
      userData,
      learnMore,
      loginShow,
      showState,
      gotoWelfareD,
      gotoWelfareDB,
      openFile
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "30b9b160": _ctx.pointerChange
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;