import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from 'vue-router';
import { defineComponent, reactive, toRefs } from 'vue';
import { Toast, Dialog } from '@/components/ui';
import { request } from '@/utils';
export default defineComponent({
  name: '',
  props: ['data'],
  setup(props) {
    const {
      data
    } = {
      ...props
    };
    const state = reactive({
      item: data
    });
    const fetchSet = {
      getCoupon: params => request.get('/coupon/getCouponById', params)
    };
    const GetCouponsPop = message => {
      Dialog({
        // showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        // cancelButtonText: "CANCEL",
        message: message
      });
    };
    // 跳转链接
    const router = useRouter();
    const toLinkUrl = (i, name) => {
      if (!i.linkPage) {
        return false;
      }
      if (i.linkPage === 'activityPage') {
        router.push({
          path: '/activePage',
          query: {
            id: i.id.id
          }
        });
      } else if (i.linkPage === 'coupon') {
        fetchSet.getCoupon({
          couponId: i.id
        }).then(res => {
          const {
            code,
            message
          } = res;
          if (code === 200) {
            GetCouponsPop('Successfully received');
          } else {
            GetCouponsPop(message);
          }
        }).catch(err => {
          Toast(err);
        });
      } else {
        document.location.href = i.url;
      }
    };
    return {
      ...toRefs(state),
      toLinkUrl
    };
  }
});