import { defineComponent } from 'vue'

const name = 'g-loading'

const props = {
  fill: {
    type: String,
    default: '#000'
  },
  showBg: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: ''
  }
}

export default defineComponent({
  name,
  props,
  setup (props) {
    return () => (
      <div class={ props.showBg ? 'bg g-loading' : 'g-loading' }>
        <svg class={ props.size } viewBox="0,0,16,16">
          <circle cx="8" cy="8" r="8" fill={ props.fill } />
        </svg>
        <svg class={ props.size } viewBox="0,0,16,16">
          <circle cx="8" cy="8" r="8" fill={ props.fill } />
        </svg>
        <svg class={ props.size } viewBox="0,0,16,16">
          <circle cx="8" cy="8" r="8" fill={ props.fill } />
        </svg>
      </div>
    )
  }
})
