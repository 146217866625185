import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
export default {
  name: 'member_clock',
  setup() {
    // 活动信息
    const contactData = ref({
      day: 1,
      is_clock: false
    });
    const push_data = ref({
      textarea: ''
    });
    // 获取打卡信息
    const get_contactData = () => {
      axios({
        url: api.baseUrl + api.contactData,
        method: 'get'
      }).then(res => {
        console.log(res);
      });
    };
    // 上传视频
    const afterReadImg = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      const formData = new FormData();
      formData.append('file', file.file);
      const url = api.baseUrl + api.uploadIcon;
      axios.post(url, formData).then(res => {
        console.log(res);
        push_data.value.img = res.data.data.url;
      });
    };
    // 上传视频
    const afterReadVido = file => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      const formData = new FormData();
      formData.append('file', file.file);
      const url = api.baseUrl + api.uploadVideo;
      axios.post(url, formData).then(res => {
        console.log(res);
        push_data.value.vido = res.data.data.url;
      });
    };
    get_contactData();
    return {
      contactData,
      push_data,
      afterReadVido,
      afterReadImg
    };
  }
};