import { defineComponent, computed, Transition } from 'vue'

export default defineComponent({
  name: 'g-messsage',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    // 主题: success|warning|error
    type: {
      type: String,
      default: ''
    },
    // 位置
    position: {
      type: String,
      default: 'middle'
    },
    // 容器具体位置 [0, 8]
    offset: {
      type: Array,
      default: () => []
    },
    // 自定义类名
    customClass: {
      type: String,
      default: ''
    },
    // 文字居中
    center: {
      type: Boolean,
      default: false
    },
    // 使用 HTML 片段
    dangerouslyUseHTMLString: {
      type: Boolean,
      default: false
    },
    // 是否显示关闭按钮
    showclose: {
      type: Boolean,
      default: false
    },
    // 关闭按钮函数
    close: {
      type: Function,
      default: () => {}
    }
  },
  setup (props, { emit, attrs, slots }) {
    const customClass = computed(() => {
      const classes = []
      switch (props.position) {
        case 'top':
          classes.push('placetop')
          break
        case 'bottom':
          classes.push('placebottom')
          break
        default:
          classes.push('placemiddle')
      }
      classes.push(props.customClass)
      return classes.join(' ')
    })
    const setStyle = computed(() => {
      const obj = {}
      if (props.offset) {
        // 计算时要考虑transform的值
        obj.left = props.offset[0] + 'px'
        obj.top = props.offset[1] + 'px'
      }
      return obj
    })
    const renderIcon = () => {
      let iconName = ''
      switch (props.type) {
        case 'success':
          iconName = 'i-g-wancheng24-tianchong msg-icon-success'
          break
        case 'warning':
          iconName = 'i-g-jingshi-24-tianchong msg-icon-warning'
          break
        case 'error':
          iconName = 'i-g-a-guanbitianchong16-10 msg-icon-error'
          break
      }
      return (
        <i class={['iconfont g-message-icon-info', iconName]}></i>
      )
    }

    const renderMessage = () => {
      return (
        <Transition>
          <div class={[
            'g-message g-message-info',
            `g-message--${props.type}`,
            props.show && 'g-message-fade-enter',
            props.center && 'g-message-is-center',
            customClass.value]}
          v-show={props.show}
          style={setStyle.value}
          >
            { renderIcon() }
            { props.dangerouslyUseHTMLString
              ? <p class="g-message-text" innerHTML={String(props.message)} />
              : <p class="g-message-text">{props.message}</p>
            }
            { props.showclose && <i class="iconfont-g icon-close g-message-close" onClick={props.close}/>}
          </div>
        </Transition>

      )
    }

    return () => {
      return (
        <>
          { renderMessage() }
        </>
      )
    }
  }
})
