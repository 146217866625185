import { defineComponent, defineAsyncComponent, ref, computed, watch, provide, onMounted } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useRoute } from 'vue-router';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: 'member_history',
  setup() {
    const upList = ref([]);
    const route = useRoute();
    const hd_data = ref({
      ...route.query
    });
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    // 获取记录
    const getUserRecord = () => {
      axios({
        url: api.baseUrl + api.getUserRecord,
        method: 'post',
        data: {
          userId: user_info.value.id,
          benefitId: hd_data.value.benefitId,
          materialId: hd_data.value.id
        }
      }).then(res => {
        upList.value = res.data.data;
      });
    };
    // 删除记录
    const deleteBenefitActivityRecord = id => {
      ElMessageBox.confirm('确认删除吗?', '删除', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios({
          url: api.baseUrl + api.deleteBenefitActivityRecord + id,
          method: 'delete'
        }).then(res => {
          console.log(res);
          ElMessage({
            message: res.data.code == 200 ? '删除成功' : '删除失败',
            type: res.data.code == 200 ? 'success' : 'error'
          });
          getUserRecord();
        });
      }).catch(() => {});
    };
    const look = url => {
      console.log('1');
      window.open(url);
    };
    getUserRecord();
    return {
      upList,
      deleteBenefitActivityRecord,
      look
    };
  }
};