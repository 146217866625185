import "core-js/modules/es.array.push.js";
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
import { useCascaderAreaData } from '@vant/area-data';
export default {
  name: 'member_questionnaire_m',
  setup() {
    const newarr = ref(['高血压', '糖尿病', '心脏病史', '肝炎', '血液病', '传染病', '无', '其他']);
    const jisuan = name => {
      let key = 0;
      newarr.value.map((item, index) => {
        if (name == item) {
          key = index;
        }
      });
      return key;
    };
    // 来源字典
    const channel = ref([]);
    // 其他渠道名字
    const other_channel = ref('');
    // 用户信息
    const user_info = ref(JSON.parse(localStorage.getItem('userData')) || {});
    const push_data = ref({
      name: user_info.value.name || '',
      sex: user_info.value.sex || 0,
      phone: user_info.value.phone || '',
      birthday: user_info.value.birthday || '',
      email: user_info.value.email || '',
      contact: user_info.value.contact || '',
      contactPhone: user_info.value.contactPhone || '',
      workUnit: user_info.value.workUnit || '',
      identityCard: user_info.value.identityCard || '',
      location: user_info.value.location || '',
      refereedBy: user_info.value.refereedBy || '',
      refereedPhone: user_info.value.refereedPhone || '',
      medicineNow: user_info.value.medicineNow || '',
      medicineReason: user_info.value.medicineReason || '',
      adverseAnesthetic: user_info.value.adverseAnesthetic || '',
      allergicMedicine: user_info.value.allergicMedicine || '',
      allergicFood: user_info.value.allergicFood || '',
      allergicOthers: user_info.value.allergicOthers || '',
      isPregnant: user_info.value.isPregnant || 0,
      anyImplants: user_info.value.anyImplants || 0,
      otherDiseases: user_info.value.otherDiseases ? jisuan(user_info.value.otherDiseases) : 6
    });
    console.log(push_data.value.otherDiseases);
    console.log(user_info.value.refereedBy);
    const form_state = ref({
      nr1: true,
      nr2: false
    });
    const auxiliary = ref({
      byFrienda: user_info.value.refereedBy ? 1 : 0,
      Medicine: user_info.value.medicineNow ? 1 : 0,
      Anesthetic: user_info.value.adverseAnesthetic ? 1 : 0,
      IsAllergy: user_info.value.allergicMedicine ? 1 : 0
    });

    // 日历弹窗
    const rl_show = ref(false);
    // 地区弹窗
    const qr_show = ref(false);
    // 地区数据
    const options = useCascaderAreaData();
    // 年月日单位
    const columnsType = ['year', 'month', 'day'];
    // 隐私条款
    const isAccept = ref(false);
    // 获取验证码文字
    const getText = ref('获取验证码');
    // 获取验证码时间
    const getCodeTime = ref(60);
    // 定时器
    const alarm = ref(false);
    const optionClick = (key, val) => {
      push_data.value[key] = val;
    };
    const auxiliaryClick = (key, val) => {
      // 数据重置
      if (key == 'byFrienda' && val == 0) {
        push_data.value.refereedBy = '';
        push_data.value.refereedPhone = '';
      } else if (key == 'IsAllergy' && val == 0) {
        push_data.value.allergicMedicine = '';
        push_data.value.allergicFood = '';
        push_data.value.allergicOthers = '';
      } else if (key == 'Medicine' && val == 0) {
        push_data.value.medicineNow = '';
        push_data.value.medicineReason = '';
      } else if (key == 'Anesthetic' && val == 0) {
        push_data.value.adverseAnesthetic = '';
      }
      // 更改状态
      auxiliary.value[key] = val;
    };
    // 上传数据
    const push_data_click = () => {
      console.log(!user_info.value);
      if (user_info.value == {} && !push_data.value.captcha) {
        ElMessage({
          message: '请输入验证码',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (user_info.value == {} && !push_data.value.know_source) {
        ElMessage({
          message: '请选择了解渠道',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (push_data.know_source == 'other') {
        ElMessage({
          message: '请填写其他了解渠道',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!isAccept.value) {
        ElMessage({
          message: '请同意声明条款',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!push_data.value.phone) {
        ElMessage({
          message: '请输入手机号',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(push_data.value.phone)) {
        ElMessage({
          message: '手机号码格式有误',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!push_data.value.location) {
        console.log(push_data.value.birthday);
        ElMessage({
          message: '请选择所在区域',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!push_data.value.name) {
        ElMessage({
          message: '请输入姓名',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (!push_data.value.name) {
        ElMessage({
          message: '请输入姓名',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (auxiliary.value.byFrienda == 1) {
        if (!push_data.value.refereedBy) {
          ElMessage({
            message: '请输入介绍人姓名',
            type: 'error',
            customClass: "message-m"
          });
          return;
        } else if (!push_data.value.refereedPhone) {
          ElMessage({
            message: '请输入联系电话',
            type: 'error',
            customClass: "message-m"
          });
          return;
        }
      }
      if (auxiliary.value.IsAllergy == 1) {
        if (!push_data.value.allergicMedicine) {
          ElMessage({
            message: '请输入药物',
            type: 'error',
            customClass: "message-m"
          });
          return;
        } else if (!push_data.value.allergicFood) {
          ElMessage({
            message: '请输入食物',
            type: 'error',
            customClass: "message-m"
          });
          return;
        } else if (!push_data.value.allergicOthers) {
          ElMessage({
            message: '请输入其他',
            type: 'error',
            customClass: "message-m"
          });
          return;
        }
      }
      if (auxiliary.value.Medicine == 1) {
        if (!push_data.value.medicineNow) {
          ElMessage({
            message: '请输入药物名称',
            type: 'error',
            customClass: "message-m"
          });
          return;
        } else if (!push_data.value.medicineReason) {
          ElMessage({
            message: '请输入原因',
            type: 'error',
            customClass: "message-m"
          });
          return;
        }
      }
      if (auxiliary.value.Anesthetic == 1) {
        if (!push_data.value.adverseAnesthetic) {
          ElMessage({
            message: '请输入麻药名称',
            type: 'error',
            customClass: "message-m"
          });
          return;
        }
      }
      let date = {
        ...push_data.value
      };
      if (localStorage.getItem('source')) {
        date.source = localStorage.getItem('source');
      }
      let otherDiseasesArr = ['高血压', '糖尿病', '心脏病史', '肝炎', '血液病', '传染病', '无', '其他'];
      date.otherDiseases = otherDiseasesArr[push_data.value.otherDiseases];
      if (push_data.value.know_source == 'other') {
        date.know_source = other_channel.value;
      }
      axios({
        url: api.baseUrl + api.questionnaire,
        method: 'post',
        data: date
      }).then(res => {
        ElMessage({
          message: res.data.msg,
          type: res.data.code == 200 ? 'success' : 'error',
          customClass: "message-m"
        });
        // 提交成功更新数据
        if (res.data.code == 200) {
          // 判断有没有登录
          if (user_info.value.phone) {
            get_user_info();
          } else {
            getUser(res.data.data);
          }
        }
      });
    };
    const getUser = id => {
      // 判断有没有微信授权
      let url = api.baseUrl + api.get_user_info + id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        // 存临时缓存
        if (res.data.data.unionid) {
          get_user_info();
        } else {
          localStorage.setItem('userData', JSON.stringify(res.data.data));
          router.push({
            name: 'member_index'
          });
        }
      });
    };
    const fh = () => {
      router.go(-1);
    };
    // 出生日期
    const onConfirm = e => {
      // console.log(formatDate(e));
      push_data.value.birthday = formatDate(e);
      rl_show.value = false;
    };
    // 日期转换
    const formatDate = date => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    // 地址选择
    const onFinish = ({
      selectedOptions
    }) => {
      console.log(selectedOptions);
      push_data.value.location = selectedOptions.map(option => option.text).join('-');
      qr_show.value = false;
    };
    // 获取用户信息
    const get_user_info = () => {
      if (!user_info.value.id) {
        // 数据回显
        push_data.value = {
          name: user_info.value.name || '',
          sex: user_info.value.sex || 0,
          phone: user_info.value.phone || '',
          birthday: user_info.value.birthday || '',
          email: user_info.value.email || '',
          contact: user_info.value.contact || '',
          contactPhone: user_info.value.contactPhone || '',
          workUnit: user_info.value.workUnit || '',
          identityCard: user_info.value.identityCard || '',
          location: user_info.value.location || '',
          refereedBy: user_info.value.refereedBy || '',
          refereedPhone: user_info.value.refereedPhone || '',
          medicineNow: user_info.value.medicineNow || '',
          medicineReason: user_info.value.medicineReason || '',
          adverseAnesthetic: user_info.value.adverseAnesthetic || '',
          allergicMedicine: user_info.value.allergicMedicine || '',
          allergicFood: user_info.value.allergicFood || '',
          allergicOthers: user_info.value.allergicOthers || '',
          isPregnant: user_info.value.isPregnant || 0,
          anyImplants: user_info.value.anyImplants || 0,
          otherDiseases: user_info.value.otherDiseases ? jisuan(user_info.value.otherDiseases) : 6
        };
        auxiliary.value = {
          byFrienda: user_info.value.refereedBy ? 1 : 0,
          Medicine: user_info.value.medicineNow ? 1 : 0,
          Anesthetic: user_info.value.adverseAnesthetic ? 1 : 0,
          IsAllergy: user_info.value.allergicMedicine ? 1 : 0
        };
        return;
      }
      let url = api.baseUrl + api.get_user_info + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        console.log(res);
        // 存临时缓存
        localStorage.setItem('userData', JSON.stringify(res.data.data));
        user_info.value = JSON.parse(localStorage.getItem('userData'));
        // 数据回显
        push_data.value = {
          name: user_info.value.name || '',
          sex: user_info.value.sex || 0,
          phone: user_info.value.phone || '',
          birthday: user_info.value.birthday || '',
          email: user_info.value.email || '',
          contact: user_info.value.contact || '',
          contactPhone: user_info.value.contactPhone || '',
          workUnit: user_info.value.workUnit || '',
          identityCard: user_info.value.identityCard || '',
          location: user_info.value.location || '',
          refereedBy: user_info.value.refereedBy || '',
          refereedPhone: user_info.value.refereedPhone || '',
          medicineNow: user_info.value.medicineNow || '',
          medicineReason: user_info.value.medicineReason || '',
          adverseAnesthetic: user_info.value.adverseAnesthetic || '',
          allergicMedicine: user_info.value.allergicMedicine || '',
          allergicFood: user_info.value.allergicFood || '',
          allergicOthers: user_info.value.allergicOthers || '',
          isPregnant: user_info.value.isPregnant || 1,
          anyImplants: user_info.value.anyImplants || 1,
          otherDiseases: user_info.value.otherDiseases ? jisuan(user_info.value.otherDiseases) : 6
        };
        auxiliary.value = {
          byFrienda: user_info.value.refereedBy ? 1 : 0,
          Medicine: user_info.value.medicineNow ? 1 : 0,
          Anesthetic: user_info.value.adverseAnesthetic ? 1 : 0,
          IsAllergy: user_info.value.allergicMedicine ? 1 : 0
        };
      });
    };
    // 出生日期数据
    const time_data = computed(() => {
      console.log('1');
      if (push_data.value.birthday) {
        return push_data.value.birthday.split('-');
      } else {
        return ['2000', '01', '01'];
      }
    });
    // 格式化年月日
    const formatter = (type, option) => {
      if (type === 'year') {
        option.text += '年';
      }
      if (type === 'month') {
        option.text += '月';
      }
      if (type === 'day') {
        option.text += '日';
      }
      return option;
    };
    // 最大日期
    const max_date = computed(() => {
      let myDate = new Date();
      let y = myDate.getFullYear();
      let m = myDate.getMonth() + 1;
      let d = myDate.getDate();
      let time = y + '/' + m + '/' + d;
      return new Date(time);
    });
    // 取消按钮
    const tiem_cancel = () => {
      rl_show.value = false;
    };
    // 确认按钮
    const time_confirm = e => {
      push_data.value.birthday = e.selectedValues.join('-');
      rl_show.value = false;
    };
    // 获取验证码
    const get_code = () => {
      if (!push_data.value.phone) {
        ElMessage({
          message: '请正确填写手机号',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      if (getCodeTime.value != 60) {
        ElMessage({
          message: '请倒计时结束后再试',
          type: 'error',
          customClass: "message-m"
        });
        return;
      }
      let url = api.baseUrl + api.sms;
      axios({
        url,
        data: {
          mobile: push_data.value.phone,
          type: 3
        },
        method: 'post'
      }).then(res => {
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.msg,
            type: 'success',
            customClass: "message-m"
          });
          runClock();
        }
      });
    };
    const runClock = () => {
      setTimeout(() => {
        if (getCodeTime.value > 0) {
          getCodeTime.value -= 1;
          getText.value = getCodeTime.value + 's';
          runClock();
        } else {
          getText.value = '获取验证码', getCodeTime.value = 60;
        }
      }, 1000);
    };
    // 获取来源
    const get_channel = () => {
      let url = api.baseUrl + api.getDict + '?dictName=know_source';
      axios({
        url,
        method: "get"
      }).then(res => {
        console.log(res);
        channel.value = res.data.data;
      });
    };
    get_channel();
    return {
      push_data,
      optionClick,
      auxiliary,
      auxiliaryClick,
      isAccept,
      push_data_click,
      fh,
      form_state,
      rl_show,
      qr_show,
      onConfirm,
      options,
      onFinish,
      user_info,
      time_data,
      formatter,
      columnsType,
      max_date,
      min_date: new Date('1920/01/01'),
      tiem_cancel,
      time_confirm,
      getText,
      get_code,
      channel,
      other_channel
    };
  }
};