import { defineComponent, onMounted, ref } from 'vue'
import Loading from '../loading'

const name = 'g-infinite-load'

const props = {
  distance: {
    type: [Number, String],
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  finish: {
    type: Boolean,
    default: false
  }
}

export default defineComponent({
  name,
  props,
  emits: ['load'],
  setup (props, { emit, slots }) {
    const triggerStyle = {
      top: `-${props.distance}px`
    }
    const trigger = ref(null)
    onMounted(() => {
      const IO = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          !props.finish && !props.loading && emit('load')
          props.finish && IO.unobserve(entry.target)
        }
      })
      IO.observe(trigger.value)
    })
    return () => (
      <div class='g-infinite-load'>
        <Loading v-show={ props.loading && !props.finish } />
        <div ref={ trigger } class='g-infinite-load-trigger' style={ triggerStyle } />
        <div class='g-infinite-load-content'>
          { slots.default?.() }
        </div>
      </div>
    )
  }
})
