import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import axios from "axios";
import { api } from "@/api/api";
import router from '@/router';
export default {
  name: 'member_collect',
  setup() {
    const user_info = ref(JSON.parse(localStorage.getItem('userData')));
    const fh = () => {
      router.go(-1);
    };
    const collectList = ref([{
      name: '口腔护理保健',
      imgUrl: "http://mimo.mimodental.com/assets/image/202382215133_图片1.png",
      brief: `<p><span style="font-family: siyuan_Regular;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;孩子在6岁之前使用的都是乳牙，等到6岁以后才会逐渐换成“恒牙”。可是，有不少家长发现自家的孩子突然多了一排牙齿，难道是孩子身体发育异常了？</span></p><p><br/></p>`
    }, {
      name: '口腔护理保健',
      imgUrl: "http://mimo.mimodental.com/assets/image/202382215133_图片1.png",
      brief: `<p><span style="font-family: siyuan_Regular;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;孩子在6岁之前使用的都是乳牙，等到6岁以后才会逐渐换成“恒牙”。可是，有不少家长发现自家的孩子突然多了一排牙齿，难道是孩子身体发育异常了？</span></p><p><br/></p>`
    }, {
      name: '口腔护理保健',
      imgUrl: "http://mimo.mimodental.com/assets/image/202382215133_图片1.png",
      brief: `<p><span style="font-family: siyuan_Regular;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;孩子在6岁之前使用的都是乳牙，等到6岁以后才会逐渐换成“恒牙”。可是，有不少家长发现自家的孩子突然多了一排牙齿，难道是孩子身体发育异常了？</span></p><p><br/></p>`
    }]);
    const userCollects = () => {
      let url = api.baseUrl + api.userCollects + '?userId=' + user_info.value.id;
      axios({
        url,
        method: 'get'
      }).then(res => {
        const {
          data
        } = res;
        // console.log(data);
        collectList.value = data.rows;
      });
    };
    // 查看图文详情
    const details = e => {
      router.push({
        name: 'member_details_m',
        params: {
          id: e
        }
      });
    };
    userCollects();
    return {
      fh,
      collectList,
      details
    };
  }
};