import { defineComponent, reactive, toRefs, onBeforeMount, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
const loader = new Loader({
  apiKey: 'AIzaSyDZMyjfuFkpDKJbcsqF-OiTJD7hBYjv8mI',
  // 之前的key
  version: 'weekly',
  // 版本
  libraries: ['places']
});
export default defineComponent({
  name: 'googleMaps',
  props: ['gMaps'],
  setup(props) {
    const {
      gMaps
    } = {
      ...props
    };
    const state = reactive({
      iframeHeight: '',
      google: '',
      map: '',
      service: '',
      marker: ''
    });
    onBeforeMount(() => {});
    onMounted(() => {
      initMap(gMaps.data);
    });
    const initMap = val => {
      window.navigator.geolocation.getCurrentPosition(position => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const mapOptions = {
          center: center,
          zoom: 6
        };
        loader.load().then(google => {
          state.google = google;
          state.map = new google.maps.Map(document.getElementById('mapBox'), mapOptions);
          // service 地点查询类
          state.service = new google.maps.places.PlacesService(state.map);
          geolocate(val);
          state.marker = new google.maps.Marker(); // 地图标记类
        }).catch(e => {
          console.log(e);
        });
      });
    };
    const geolocate = val => {
      const request = {
        query: val,
        // 搜索文本
        fields: ['name', 'geometry', 'place_id'] // 指定要返回的数据字段， 也可以写成 fields: ['all']
      };

      state.service.textSearch(request, (results, status) => {
        if (status === state.google.maps.places.PlacesServiceStatus.OK) {
          const res = results[0];
          state.marker = new state.google.maps.Marker({
            // 给搜索地点添加地图标记
            position: res.geometry.location,
            map: state.map
          });
          state.map.setCenter(state.marker.getPosition()); // 以搜索点为中心
          const myLatLngBounds = new state.google.maps.LatLngBounds(res.geometry.viewport); // 当前地点的几何视口
          state.map.fitBounds(myLatLngBounds); // 设置视口，使其包含指定边界。改变zoom缩放级别
        }
      });
    };

    return {
      ...toRefs(state)
    };
  }
});